import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import asyncComponent from 'util/asyncComponent';
import * as a from 'actions';
import ContainerHeader from 'components/ContainerHeader';
import CardWithTabs from 'components/kea/CardWithTabs';
import IntlMessages from 'util/IntlMessages';
import SaveActionBtnGrp from 'components/kea/settings/SaveActionBtnGrp';
import { objectDifference } from 'util/appUtils';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import Theme from './theme';
import General from './general';


export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={() => (<Redirect to={`${match.url}/general`} />)} />

    <Route path={`${match.url}/:tabId`} component={Settings} />
    <Route component={asyncComponent(() => import('app/routes/404'))} />
  </Switch>
);

const Settings = (props) => {
  const dispatch = useDispatch();
  const { match, match: { params }, history } = props;

  const {
    settings: { isFetched, isUpdatingRecs, recsVisible, forDiff, recs },
    app: { currentShop },
  } = useSelector((store) => store);


  const [tabId, setTabId] = useState(params.tabId || 'general');
  useEffect(() => setTabId(params.tabId), [params.tabId]);

  const onDiscardSetting = () => dispatch(a.resetRecsSettings());
  const onSaveSetting = () => dispatch(a.saveRecsSettings(currentShop));

  const changeTab = (event, id) => {
    history.push(id);
    setTabId(id);
  };

  const tabs = {
    general: { title: 'General', component: <General /> },
    theme: { title: 'Colors', component: <Theme /> },
  };

  const buttons = [
    { text: 'Save', action: onSaveSetting, active: true, color: 'primary' },
    { text: 'Discard', action: onDiscardSetting, active: false, color: 'secondary' },
  ];

  const settingsChanged = Object.keys(objectDifference(forDiff.recs, recs)).length !== 0;
  const visibleChanged = forDiff.recsVisible !== recsVisible.value;
  const savingIsDisable = !(settingsChanged || visibleChanged);

  return (
    <div className="app-wrapper dashboard animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        match={match}
        title={<IntlMessages id="recommendations.settings.title" />}
        action={(
          <SaveActionBtnGrp
            isFetching={isUpdatingRecs}
            buttons={buttons}
            disabled={savingIsDisable}
          />
)}
      />
      {
        isFetched
          ? (
            <CardWithTabs
              tabId={tabId}
              tabs={tabs}
              onTabClick={changeTab}
            />
          )
          : <PlaceholderSpinner />
      }
    </div>
  );
};
