/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Widget from 'components/Widget/index';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import WidgetRainbow from '../../common/WidgetRainbow';
import { chartColors } from 'util/chartUtils';

const AffectedUsers = ({ queryNoResult }) => {
  const { isFetching, users, error } = queryNoResult;

  if (isFetching) {
    return (
      <Widget styleName="jr-card-full noMargin">
        <PlaceholderSpinner height={129} />
      </Widget>
    );
  }

  const pieData = [
    { id: '1', name: 'Affected users', fill: chartColors.main.normal, value: users ? users.filteredTotal : '-' },
    { id: '2', name: 'Other users', fill: chartColors.different.normal, value: users ? users.otherTotal : '-' },
  ];
  const mainNumber = error || !(users && users.filteredTotal)
    ? '-'
    : users.filteredTotal;

  return (
    <WidgetRainbow
      pieData={pieData}
      title="Affected users"
      mainNumber={mainNumber}
      description="Number of users who didn't get any results"
    />
  );
};

export default AffectedUsers;
