/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import Divider from '@material-ui/core/Divider';
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ReactComponent as UpIcon } from 'assets/images/icons/arrow_upward-24px.svg';
import { ReactComponent as DownIcon } from 'assets/images/icons/arrow_downward-24px.svg';
import { useDispatch, useSelector } from 'react-redux';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import CardContainer from 'components/kea/CardContainer';
import { commaSeparate } from 'util/appUtils';
import { changeTimeframe, getABResults } from 'actions';
import DateRangePicker from 'components/kea/DateRangePicker';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import { Link } from 'react-router-dom';
import PlaceholderError from '../../../../components/kea/placeholders/PlaceholderError';

const Experiment = ({ match, location }) => {
  const dispatch = useDispatch();
  const {
    analytics: { experiment, timeframe },
    app: { currentShop },
    settings,
  } = useSelector((store) => store);
  const { error, isFetching, a, b } = experiment;

  useEffect(() => {
    dispatch(getABResults(currentShop, timeframe));
  }, [timeframe]);


  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));
  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  const calcDiffBetween = (a, b, fixed = 2, suffix = null, goodIs = '+') => {
    if (!a || !b) return null;
    const diff = b - a;
    const sign = diff > 0 ? '+' : '-';
    const value = Math.abs(diff).toFixed(fixed);
    const result = (sign === goodIs) ? 'good' : 'bad';

    return (
      <div className={`exp-row-elevation-item-${result}`}>
        {
          sign === '+'
            ? <UpIcon className="exp-row-elevation-icon" />
            : <DownIcon className="exp-row-elevation-icon" />
        }
        {' '}
        {value}
        {' '}
        {suffix && suffix}
      </div>
    );
  };

  const calcPercentageBetween = (a, b, fixed, suffix = '%', goodIs = '+') => {
    if (!a || !b) return null;
    const diff = ((b - a) / a) * 100;
    const sign = diff > 0 ? '+' : '-';
    const value = Math.abs(diff).toFixed(2);
    const result = (sign === goodIs) ? 'good' : 'bad';

    return (
      <div className={`exp-row-elevation-item-${result}`}>
        {
          sign === '+'
            ? <UpIcon className="exp-row-elevation-icon" />
            : <DownIcon className="exp-row-elevation-icon" />
        }
        {value}
        {suffix && suffix}
      </div>
    );
  };

  return (
    <div className="app-wrapper slideInUpTiny">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={<IntlMessages id="sidebar.store.experiment" />}
      />
      <CardContainer>
        {
          isFetching
            ? <PlaceholderSpinner height={750} />
            : error
              ? <PlaceholderError error={error} height={750} />
              : (
                <Grid>
                  <Grid container>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={3} />
                        <Grid item xs={2}>
                          <div className="jr-fs-2xl jr-font-weight-bold exp-main-accent">A</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div className="exp-main-accent-elevation">
                            <div>{`Recommendations ${settings.experiment.A.recsEnabled ? 'enabled' : 'disabled'}`}</div>
                            <div>{`Search ${settings.experiment.A.searchEnabled ? 'enabled' : 'disabled'}`}</div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={3} />
                        <Grid item xs={2}>
                          <div className="jr-fs-2xl jr-font-weight-bold exp-main-accent">B</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div className="exp-main-accent-elevation">
                            <div>{`Recommendations ${settings.experiment.B.recsEnabled ? 'enabled' : 'disabled'}`}</div>
                            <div>{`Search ${settings.experiment.B.searchEnabled ? 'enabled' : 'disabled'}`}</div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" />

                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-header">Revenue</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        <div className="jr-fs-2xl jr-font-weight-bold exp-main-accent exp-row-value">
                          {commaSeparate(a.revenue)}
                          {settings.currency}
                        </div>
                        <div className="exp-row-elevation-wrapper">
                          {calcDiffBetween(b.revenue, a.revenue, 0, settings.currency)}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <div className="exp-row-value">
                          <div className="jr-fs-2xl jr-font-weight-bold exp-main-accent">
                            {commaSeparate(b.revenue)}
                            {settings.currency}
                          </div>
                          <div className="exp-row-elevation-wrapper">
                            {calcDiffBetween(a.revenue, b.revenue, 0, settings.currency)}
                            {calcPercentageBetween(a.revenue, b.revenue)}
                          </div>
                        </div>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Divider variant="middle" />
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="exp-row-header">Audience</div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-subheader">Number of visitors</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.audience.visitors}
                        <div className="exp-row-elevation-wrapper" />
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.audience.visitors}
                        <div className="exp-row-elevation-wrapper">
                          {calcPercentageBetween(a.audience.visitors, b.audience.visitors)}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-subheader">Number of filled carts</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.audience.withProducts}
                        <div className="exp-row-elevation-wrapper">
                          {calcDiffBetween(b.audience.withProducts, a.audience.withProducts, 0)}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.audience.withProducts}
                        <div className="exp-row-elevation-wrapper">
                          {calcDiffBetween(a.audience.withProducts, b.audience.withProducts, 0)}
                          {calcPercentageBetween(a.audience.withProducts, b.audience.withProducts)}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-subheader">Filled carts conversion</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.audience.withProductsRate}
                        %
                        <div className="exp-row-elevation-wrapper">
                         &nbsp;
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.audience.withProductsRate}
                        %
                        <div className="exp-row-elevation-wrapper">
                          {calcPercentageBetween(a.audience.withProductsRate, b.audience.withProductsRate, 2, '%')}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-subheader">Number of purchases</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.purchases.count ? a.purchases.count : '-'}
                        <div className="exp-row-elevation-wrapper">
                          &nbsp;
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.purchases.count ? b.purchases.count : '-'}
                        <div className="exp-row-elevation-wrapper">
                          {calcPercentageBetween(a.purchases.count, b.purchases.count)}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Divider variant="middle" />
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-header">Abandoned carts rate</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.abandonedRate !== 100 ? a.abandonedRate : '-'}
                        <div className="exp-row-elevation-wrapper">
                          &nbsp;
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.abandonedRate !== 100 ? b.abandonedRate : '-'}
                        <div className="exp-row-elevation-wrapper">
                          {a.abandonedRate === b.abandonedRate ? ' ' : calcPercentageBetween(a.abandonedRate, b.abandonedRate, 1, '%', '-')}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" />
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-header">Average Order Value</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.aov}
                        {settings.currency}
                        <div className="exp-row-elevation-wrapper">
                          &nbsp;
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.aov}
                        {settings.currency}
                        <div className="exp-row-elevation-wrapper">
                          {calcDiffBetween(a.aov, b.aov, 0)}
                          {calcPercentageBetween(a.aov, b.aov, 2, '%', '+')}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  {/* <Divider variant="middle" /> */}
                  {/* <Grid container> */}
                  {/* <Grid item xs={12}> */}
                  {/* <div className="exp-row-header">Service Usage</div> */}
                  {/* </Grid> */}
                  {/* </Grid> */}
                  {/* <Grid container> */}
                  {/* <Grid item xs={4}> */}
                  {/* <div className="exp-row-subheader">Used Recommendations</div> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={4}> */}
                  {/* <div className="exp-row-value"> */}
                  {/* {a.usage.recsUsersCount ? `${a.usage.recsUsersCount}` : '-'} */}
                  {/* </div> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={4}> */}
                  {/* <div className="exp-row-value"> */}
                  {/* {b.usage.recsUsersCount ? `${b.usage.recsUsersCount}` : '-'} */}
                  {/* </div> */}
                  {/* </Grid> */}
                  {/* </Grid> */}
                  {/* <Grid container> */}
                  {/* <Grid item xs={4}> */}
                  {/* <div className="exp-row-subheader">Used Search</div> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={4}> */}
                  {/* <div className="exp-row-value"> */}
                  {/* {a.usage.searchUsersCount ? `${a.usage.searchUsersCount}` : '-'} */}
                  {/* </div> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={4}> */}
                  {/* <div className="exp-row-value"> */}
                  {/* {b.usage.searchUsersCount ? `${b.usage.searchUsersCount}` : '-'} */}
                  {/* </div> */}
                  {/* </Grid> */}
                  {/* </Grid> */}
                  <Divider variant="middle" />
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-header">Average Products Viewed</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.averageProductsViewed ? `${a.averageProductsViewed}` : '-'}
                        <div className="exp-row-elevation-wrapper">
                          &nbsp;
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.averageProductsViewed ? `${b.averageProductsViewed}` : '-'}
                        <div className="exp-row-elevation-wrapper">
                          {calcPercentageBetween(a.averageProductsViewed, b.averageProductsViewed)}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" />
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="exp-row-header">Average Number of products in cart</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {a.averageNumberOfProductsInCart ? a.averageNumberOfProductsInCart : '-'}
                        <div className="exp-row-elevation-wrapper">
                          &nbsp;
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        {b.averageNumberOfProductsInCart ? b.averageNumberOfProductsInCart : '-'}
                        <div className="exp-row-elevation-wrapper">
                          {calcPercentageBetween(a.averageNumberOfProductsInCart, b.averageNumberOfProductsInCart)}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        <Link to={`${location.pathname}/carts?group=A`}>
                          View details...
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="exp-row-value">
                        <Link to={`${location.pathname}/carts?group=B`}>
                          View details...
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )
}
      </CardContainer>
    </div>
  );
};
export default Experiment;
