import { takeLatest } from 'redux-saga/effects';
import {
  GET_AUTHORIZATION_INFO_FAILED,
  KEA_SIGN_IN_FAILED,
  KEA_SIGN_IN_WITH_SECRET_FAILED,
} from 'constants/ActionTypes';

import {
  GET_ALERTS_FAILED,
  GET_ALL_SETTINGS_FAILED,
  GET_DESCRIBES_FAILED,
  SAVE_RECS_SETTINGS_FAILED,
  SAVE_SEARCH_SETTINGS_FAILED,
} from '../constants/actionTypes/settings';
import {
  GET_PRODUCT_BY_ID_FAILED,
  GET_PRODUCTS_FAILED,
} from '../constants/actionTypes/catalog';
import { GET_PLANS_FAILED } from '../constants/actionTypes/billing';
import * as search from '../constants/actionTypes/search';
import * as analytics from '../constants/actionTypes/analytics';
import * as recs from '../constants/actionTypes/recs';

const isDev = true;
const isDebug = false;

function criticalErrorHandler(action) {
  const { payload: error } = action;
  if (isDev) console.error(error.message);
  else if (isDebug) console.error(error);
  // TODO: add sentry in feature;
}

function errorHandler(action) {
  const { payload: error } = action;
  if (isDev) console.error(error.message);
  else if (isDebug) console.error(error);
  // TODO: add sentry in feature;
}

export default function* errorHandlers() {
  yield takeLatest(GET_ALL_SETTINGS_FAILED, criticalErrorHandler);
  yield takeLatest(GET_AUTHORIZATION_INFO_FAILED, criticalErrorHandler);

  yield takeLatest(KEA_SIGN_IN_FAILED, errorHandler);
  yield takeLatest(KEA_SIGN_IN_WITH_SECRET_FAILED, errorHandler);

  yield takeLatest(SAVE_SEARCH_SETTINGS_FAILED, errorHandler);
  yield takeLatest(SAVE_RECS_SETTINGS_FAILED, errorHandler);
  yield takeLatest(search.SAVE_FIELDS_CHANGES_FAILED, errorHandler);
  yield takeLatest(recs.SAVE_FIELDS_CHANGES_FAILED, errorHandler);
  yield takeLatest(recs.RECS_UPDATE_ONE_WIDGET_FAILED, errorHandler);
  yield takeLatest(recs.RECS_DELETE_WIDGET_FAILED, errorHandler);
  yield takeLatest(recs.RECS_CREATE_WIDGET_FAILED, errorHandler);
  yield takeLatest(recs.RECS_UPDATE_PAGE_FAILED, errorHandler);
  yield takeLatest(GET_DESCRIBES_FAILED, errorHandler);
  yield takeLatest(GET_ALERTS_FAILED, errorHandler);
  yield takeLatest(search.GET_FIELDS_FAILED, errorHandler);
  yield takeLatest(recs.GET_FIELDS_FAILED, errorHandler);
  yield takeLatest(recs.RECS_LOAD_ALL_MODELS_FAILED, errorHandler);
  yield takeLatest(recs.RECS_LOAD_ALL_WIDGETS_FAILED, errorHandler);
  yield takeLatest(recs.RECS_LOAD_ALL_PAGES_FAILED, errorHandler);
  yield takeLatest(GET_PRODUCT_BY_ID_FAILED, errorHandler);
  yield takeLatest(GET_PRODUCTS_FAILED, errorHandler);
  yield takeLatest(GET_PLANS_FAILED, errorHandler);
  yield takeLatest(analytics.GET_A_B_RESULTS_FAILED, errorHandler);
  yield takeLatest(analytics.GET_RECS_DASHBOARD_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_DASHBOARD_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_STATS_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_FILTER_POPULAR_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_LOST_OPPORTUNITIES_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_CURVE_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_QUERY_POPULAR_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_QUERY_SORTED_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_QUERY_NO_RESULT_FAILED, errorHandler);
  yield takeLatest(analytics.GET_SEARCH_RELATED_QUERIES_FAILED, errorHandler);
  yield takeLatest(analytics.GET_PRODUCTS_CONVERSION_BEST_FAILED, errorHandler);
  yield takeLatest(analytics.GET_PRODUCTS_CONVERSION_WORST_FAILED, errorHandler);
  yield takeLatest(analytics.GET_PRODUCTS_POPULAR_BY_VIEW_FAILED, errorHandler);
  yield takeLatest(analytics.GET_PRODUCTS_POPULAR_BY_PURCHASE_FAILED, errorHandler);
  yield takeLatest(analytics.GET_PRODUCTS_RELATED_BY_VIEW_FAILED, errorHandler);
  yield takeLatest(analytics.GET_PRODUCTS_RELATED_BY_PURCHASE_FAILED, errorHandler);
  yield takeLatest(analytics.GET_RELATED_PRODUCTS_BY_QUERY_FAILED, errorHandler);
  yield takeLatest(analytics.GET_RELATED_QUERIES_BY_PRODUCT_FAILED, errorHandler);
}
