/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContainerHeader from 'components/ContainerHeader';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import DateRangePicker from 'components/kea/DateRangePicker';
import { changeTimeframe, getSearchDashboard } from 'actions';
import IntlMessages from 'util/IntlMessages';
import { matchDatesDashboardCase } from 'util/appUtils';
import SearchEfficiency from './SearchEfficiency';
import Summary from './Summary';
import SearchTrends from './SearchTrends';
import Audience from './Audience';
import Carts from './Carts';
import Profitability from './Profitability';

const Dashboard = ({ match }) => {
  const dispatch = useDispatch();
  const { app, analytics } = useSelector((store) => store);
  const { search, timeframe } = analytics;
  const { currentShop } = app;
  const { dashboard } = search;
  const { isFetching, projection, meta } = dashboard;

  useEffect(() => {
    const dataIsUnavailable = !isFetching && currentShop;
    const datesIsMatched = meta && matchDatesDashboardCase(meta, timeframe);
    if (dataIsUnavailable || !datesIsMatched) dispatch(getSearchDashboard(currentShop, timeframe));
  }, [timeframe, currentShop]);

  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));

  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  if (isFetching || !projection) return <PlaceholderSpinner />;

  return (
    <div className="app-wrapper dashboard animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={<IntlMessages id="search.dashboard.header" />}
      />

      <div className="row">
        <Summary summary={projection.summary} />
      </div>

      <div className="row">
        <div className="col-12">
          <SearchEfficiency search={projection.search} />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Audience audience={projection.audience} />
        </div>

        <div className="col-sm-12 col-md-6">
          <Carts withProducts={projection.withProducts} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Profitability
            chart={projection.chart}
          />
        </div>
      </div>


      <div className="row">
        <div className="col-12">
          <SearchTrends
            demand={projection.demand}
          />
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
