/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainer from '../../../../../components/kea/CardContainer';
import { linkToQueryDetail } from '../../../../../util/appUtils';

const MainSection = ({ queryNoResult, currentShop }) => {
  const { isFetching, data, error } = queryNoResult;

  const headCells = [
    { id: 'query', numeric: false, label: 'Query' },
    { id: 'users', numeric: true, label: 'Users' },
    { id: 'total', numeric: true, label: 'Total' },
  ];

  const prepareRows = (rows) => rows.map((el, i) => ({
    ...el,
    id: `${el.query}-${i}`,
    query: linkToQueryDetail(currentShop, el.query),
    ctr: (el.ctr * 100).toFixed(2),
  }));


  return (
    <CardContainer
      headerId="search.analytics.queryNoResult.header"
      subheaderId="search.analytics.queryNoResult.subheader"
      styleName="min-height-700px noMargin"
      isFetching={isFetching}
      error={error}
    >
      <AnalyticTable
        rows={prepareRows(data)}
        headCells={headCells}
        orderById="total"
      />
    </CardContainer>
  );
};

export default MainSection;
