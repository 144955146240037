import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';

const SearchEfficiency = ({ search }) => {
  const options = {
    textYIndex: 2,
    legend: {
      display: false,
      position: 'bottom',
    },
  };

  if (!search || search.error) {
    return (
      <CardBox header="Search efficiency">
        <DashboardStub />
      </CardBox>
    );
  }
  const { gray, main, different, accent, howToNameIt } = chartColors;
  const data = {
    labels: [
      'No actions taken',
      'Search refined with filters',
      'Navigated to full search from searchbox',
      'Navigated to product from full search',
      'Navigated to product from searchbox',
    ],
    datasets: [{
      data: [
        search.totalBad,
        search.usedFilters,
        search.navigateViewAll,
        search.navigateProductSearch,
        search.navigateProductSuggest,
      ],
      backgroundColor: [
        gray.normal,
        main.normal,
        accent.normal,
        different.normal,
        howToNameIt.normal,
      ],
      hoverBackgroundColor: [
        gray.dark,
        main.dark,
        accent.dark,
        different.dark,
        howToNameIt.dark,
      ],
    }],
    text: `${search.totalGoodRateWithBounds}%`,
  };

  return (
    <CardBox header="Search efficiency">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <p>{`There were ${search.total} - search sessions in total:`}</p>
          <p>{`Number of full search sessions - ${search.totalSearch}`}</p>
          <p>{`Number of searchbox sessions -  ${search.totalSuggest}`}</p>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <Doughnut
            height={230}
            data={data}
            options={options}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <p>{`${search.totalGoodRateWithBounds}  of search sessions lead visitors to actions`}</p>
          <p>
            {`After ${search.navigateProductSuggestRate}  % of the searchbox queries, visitors immediately found the product, and after`}
            {`${search.navigateViewAllTotalRate} % of the queries they moved to refine search with the full search.`}
          </p>
          <p>{`After ${search.navigateProductSearchRate}% of the full search sessions, visitors also navigated to product.`}</p>
        </div>
      </div>
    </CardBox>
  );
};

SearchEfficiency.propTypes = {
  search: PropTypes.shape({
    navigateProductSuggestRate: PropTypes.any,
    navigateViewAllTotalRate: PropTypes.any,
    navigateProductSearchRate: PropTypes.any,
    totalSuggest: PropTypes.any,
    totalSearch: PropTypes.any,
    total: PropTypes.any,
    totalGoodRateWithBounds: PropTypes.any,
    totalBad: PropTypes.any,
    usedFilters: PropTypes.any,
    navigateViewAll: PropTypes.any,
    navigateProductSearch: PropTypes.any,
    navigateProductSuggest: PropTypes.any,

    error: PropTypes.any,
  }).isRequired,
};


export default SearchEfficiency;
