/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticCurve } from 'actions';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import CardContainer from 'components/kea/CardContainer';
import { groupCurveByDays, theCurveIsMostlyFull } from 'util/appUtils';
import { MenuItem, Select } from '@material-ui/core';
import { chartColors } from 'util/chartUtils';

const SelectGrouping = ({ onChange, value }) => (
  <Select onChange={onChange} style={{ width: 200 }} value={value}>
    <MenuItem value={false}>Auto grouping</MenuItem>
    <MenuItem value={1}>Grouped by day</MenuItem>
    <MenuItem value={7}>Grouped by week</MenuItem>
    <MenuItem value={30}>Grouped by month</MenuItem>
  </Select>
);

const QueryTimeline = ({ match }) => {
  const { params: { query } } = match;
  const dispatch = useDispatch();
  const [groupBy, setGroupBy] = useState(false);
  const selectHandler = (e) => setGroupBy(e.target.value);

  const {
    analytics: { search, timeframe },
    app: { currentShop },
  } = useSelector((store) => store);
  const { curve: { isFetched, isFetching } } = search;

  useEffect(() => {
    dispatch(getAnalyticCurve(currentShop, timeframe, 'all', query));
  }, [timeframe, query]);

  const dateAlias = '_id';
  const wantedFields = ['click', 'noaction', 'total', 'refine'];
  const options = {
    title: { display: false },
    tooltips: { mode: 'index', intersect: false },
    responsive: true,
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }],
    },
  };


  function autoGroupCurve(arr) {
    let result = arr;
    if (arr.length > 27) {
      const granularity = arr.length > 175 ? 30 : 7;
      const ignoreGrouping = (arr.length < 45) && theCurveIsMostlyFull(arr, 'total', 0.5);
      if (!ignoreGrouping) {
        result = groupCurveByDays(arr, granularity, dateAlias, wantedFields);
      }
    }
    return result;
  }

  const prepareData = (curve, groupByDays) => {
    if (!curve.data) return; // todo: handle it better
    let curveData;

    if (groupByDays) {
      curveData = groupCurveByDays(curve.data, groupByDays, dateAlias, wantedFields);
    } else {
      curveData = autoGroupCurve(curve.data, groupByDays);
    }

    function getData(name) {
      return curveData.map((e) => e[name]);
    }

    return {
      labels: getData(dateAlias).map((date) => moment([date.year, date.month - 1, date.day]).format('YYYY-MM-DD')),
      datasets: [{
        label: 'No actions',
        backgroundColor: chartColors.gray.normal,
        data: getData('noaction'),
      }, {
        label: 'Other actions, like navigation to results page',
        backgroundColor: chartColors.accent.normal,
        data: getData('refine'),
      }, {
        label: 'Product clicks',
        backgroundColor: chartColors.main.normal,
        data: getData('click'),
      }],
    };
  };

  return (
    <CardContainer
      headerId="search.analytics.queryDetail.timeline.header"
      headerPostfix={query}
      actionComponent={<SelectGrouping onChange={selectHandler} value={groupBy} />}
    >
      {
        isFetching || !isFetched
          ? <PlaceholderSpinner height={520} />
          : <Bar data={() => prepareData(search.curve, groupBy)} height={150} options={options} />
      }
    </CardContainer>
  );
};


export default QueryTimeline;
