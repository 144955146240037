import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';

const Audience = ({ audience }) => {
  const options = {
    legend: {
      display: false,
      position: 'bottom',
    },
    textYIndex: 2,
  };


  if (!audience || audience.error) {
    return (
      <CardBox
        header="Audience"
      >
        <DashboardStub />
      </CardBox>
    );
  }

  const { main, different, gray } = chartColors;
  const data = {
    labels: [
      'Low quality traffic (non-interested or occasional visitors, bots, crawlers etc.)',
      'Did\'nt use search',
      'Used search',
    ],
    datasets: [{
      data: [
        audience.bad,
        audience.noSearch,
        audience.search,
      ],
      backgroundColor: [
        gray.normal,
        different.normal,
        main.normal,
      ],
      hoverBackgroundColor: [
        gray.dark,
        different.dark,
        main.dark,
      ],
    }],
    text: `${audience.searchPercentage}%`,
  };


  return (
    <CardBox
      header="Audience"
    >

      <div className="row">
        <div className="col-12 dashboard-text-block">
          <p>{`${audience.searchPercentage}% of interested visitors used search`}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 donutSegment">
          <Doughnut
            height={230}
            data={data}
            options={options}
          />
        </div>

        <div className="col-md-6 dashboard-text-block">
          <table className="chartTable">
            <tbody>
              <tr>
                <td>
                  <p>Total number of unique visitors</p>
                </td>
                <td>
                  <p className="textRight">{audience.total}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Got interested in the store
                    <sup>*</sup>
                    <br />
                    <sup>*</sup>
                    viewed at least 2 products
                  </p>
                </td>
                <td>
                  <p className="textRight">{audience.interested}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Number of interested visitors who used search</p>
                </td>
                <td>
                  <p className="textRight">{audience.search}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CardBox>
  );
};

Audience.propTypes = {
  audience: PropTypes.shape({
    bad: PropTypes.any,
    noSearch: PropTypes.any,
    search: PropTypes.any,
    searchPercentage: PropTypes.any,
    total: PropTypes.any,
    interested: PropTypes.any,


    error: PropTypes.any,
  }).isRequired,
};


export default Audience;
