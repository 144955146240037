/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeTimeframe, getProductById } from 'actions';
import ContainerHeader from 'components/ContainerHeader';
import DateRangePicker from 'components/kea/DateRangePicker';
import TrendingWidget from './TrendingWidget';
import ProductDetail from './ProductDetail';
import RelatedProductsByView from './RelatedProductsByView';
import RelatedProductsByPurchase from './RelatedProductsByPurchase';
import RelatedQueries from './RelatedQueries';
import StatsWidget from './StatsWidget';
import DiscoveryWidget from './DiscoveryWidget';

const Product = ({ match }) => {
  const { params: { productId } } = match;
  const dispatch = useDispatch();
  const {
    catalog: { product },
    app: { currentShop },
    analytics: { timeframe },
  } = useSelector((str) => str);
  const { data, stats } = product;

  useEffect(() => {
    dispatch(getProductById(currentShop, timeframe, productId));
  }, [timeframe, productId]);

  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));
  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  return (
    <div className="app-wrapper-full-width animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={data && data.name}
      />

      <Grid>
        <Grid container spacing={4}>

          <Grid xs={4} item>
            <Grid container spacing={4}>

              <Grid item xs={12}>
                <ProductDetail match={match} product={product} />
              </Grid>

              <Grid item xs={12}>
                <RelatedQueries productId={match.params.productId} />
              </Grid>

            </Grid>
          </Grid>


          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <StatsWidget stats={stats} />
              </Grid>

              <Grid item xs={6}>
                <DiscoveryWidget stats={stats} />
              </Grid>

              <Grid item xs={12}>
                <TrendingWidget match={match} chartData={product.trendingCurve} />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <RelatedProductsByView productId={productId} />
              </Grid>

              <Grid item xs={6}>
                <RelatedProductsByPurchase productId={productId} />
              </Grid>
            </Grid>

          </Grid>

        </Grid>
      </Grid>
    </div>
  );
};

export default Product;
