/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import PopularProductsByView from './PopularProductsByView';
import PopularProductsByPurchase from './PopularProductsByPurchase';

const PopularProducts = () => (
  <Grid>
    <Grid spacing={4} container>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <PopularProductsByView />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <PopularProductsByPurchase />
      </Grid>
    </Grid>
  </Grid>
);

export default PopularProducts;
