import React from 'react';
import { Route } from 'react-router-dom';
import PlaceholderError from '../placeholders/PlaceholderError';


function withErrorBoundary(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      // TODO:  Mount Sentry in this place
      console.log({ error, errorInfo });
    }

    render() {
      if (this.state.hasError) return <PlaceholderError />;
      return <WrappedComponent {...this.props} />;
    }
  };
}

const SafetyRoute = withErrorBoundary(Route);
export default SafetyRoute;
