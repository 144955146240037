// Some description
export const CHANGE_CURRENT_SHOP = 'app/CHANGE_CURRENT_SHOP';

export const KEA_SIGN_OUT = 'auth/KEA_SIGN_OUT';
export const KEA_SIGN_IN = 'auth/KEA_SIGN_IN';
export const KEA_SIGN_IN_SUCCESS = 'auth/KEA_SIGN_IN_SUCCESS';
export const KEA_SIGN_IN_FAILED = 'auth/KEA_SIGN_IN_FAILED';

export const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'auth/FORGOT_PASSWORD_FAILED';

export const SUBMIT_NEW_PASSWORD = 'auth/SUBMIT_NEW_PASSWORD';
export const SUBMIT_NEW_PASSWORD_SUCCESS = 'auth/SUBMIT_NEW_PASSWORD_SUCCESS';
export const SUBMIT_NEW_PASSWORD_FAILED = 'auth/SUBMIT_NEW_PASSWORD_FAILED';

export const KEA_SIGN_IN_WITH_SECRET = 'auth/KEA_SIGN_IN_WITH_SECRET';
export const KEA_SIGN_IN_WITH_SECRET_SUCCESS = 'auth/KEA_SIGN_IN_WITH_SECRET_SUCCESS';
export const KEA_SIGN_IN_WITH_SECRET_FAILED = 'auth/KEA_SIGN_IN_WITH_SECRET_FAILED';

export const GET_AUTHORIZATION_INFO = 'auth/GET_AUTHORIZATION_INFO';
export const GET_AUTHORIZATION_INFO_COMPLETE = 'auth/GET_AUTHORIZATION_INFO_COMPLETE';
export const GET_AUTHORIZATION_INFO_FAILED = 'auth/GET_AUTHORIZATION_INFO_FAILED';

export const GET_PROFILE_INFO = 'auth/GET_PROFILE_INFO';
export const GET_PROFILE_INFO_COMPLETE = 'auth/GET_PROFILE_INFO_COMPLETE';
export const GET_PROFILE_INFO_FAILED = 'auth/GET_PROFILE_INFO_FAILED';

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

// Contact Module const
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';

// Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';

// Chat Module const

export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';

// // Mail Module const
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';

// // TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const UPDATE_SEARCH = 'update_search';
