/* eslint-disable no-use-before-define */
import {
  put, select, takeLatest, takeEvery,
} from 'redux-saga/effects';
import _ from 'lodash';
import { objectDifference, wsClient } from 'util/appUtils';
import * as c from 'constants/actionTypes/settings';
import { onboardingChange } from '../slices/OnboardingSlice';

function* getAlerts({ payload }) {
  try {
    const { token, appId } = payload;
    const alerts = yield wsClient.fetchAlerts({ shopId: token, appId });

    yield put({ type: c.UPDATE_ALERT_BANNERS, payload: alerts });
  } catch (err) {
    yield put({ type: c.GET_ALERTS_FAILED, payload: err });
  }
}

// UPDATE_ALERT_BANNERS handler, use for add one or more alerts.
function* updateAlertBanners({ payload }) {
  const { alerts } = yield select((store) => store.settings);
  const alertIds = alerts.data.map((e) => e.id);
  const uniq = [...alerts.data];

  payload.forEach((e) => {
    if (!alertIds.includes(e.id)) uniq.push(e);
  });

  yield put({ type: c.REWRITE_ALERT_BANNERS, payload: uniq });
}


function* getSettings({ payload }) {
  try {
    const data = yield wsClient.fetchShopSettings(payload);

    const forDiff = {
      search: data.search,
      recs: data.recs,
      searchVisible: data.searchVisible,
      recsVisible: data.recsVisible,
    };

    yield put({ type: c.GET_ALL_SETTINGS_COMPLETE, payload: { ...data, forDiff } });
    yield put(onboardingChange({ recs: data.recs.onboarding, search: data.search.onboarding }));
  } catch (err) {
    yield put({ type: c.GET_ALL_SETTINGS_FAILED, payload: err });
  }
}

function* getSettingDescribes({ payload }) {
  try {
    const { token, appId } = payload;
    const data = yield wsClient.fetchSettingsDescribes(token, appId);

    yield put({ type: c.GET_DESCRIBES_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_DESCRIBES_FAILED, payload: err });
  }
}


function* updateAppSettings(token, appId, body, background = true) {
  const dataToUpdate = { ...body, background };
  const data = yield wsClient.updateShopSettings(token, dataToUpdate);

  yield getSettings({ payload: token });
  return data;
}

function* saveSearchSettings({ payload }) {
  try {
    const {
      search,
      forDiff,
      searchVisible: { value: searchVisible },
    } = yield select(({ settings }) => settings);

    const diff = objectDifference(search, forDiff.search);

    const dataToUpdate = {};

    if (!_.isEqual(diff, {})) dataToUpdate.search = diff;
    if (searchVisible !== forDiff.searchVisible) {
      dataToUpdate.searchVisible = searchVisible;
      if (searchVisible && !search.onboarding.completed) {
        dataToUpdate.search = { onboarding: { completed: true } };
      }
    }

    const response = yield updateAppSettings(payload, 'search', dataToUpdate, true);
    yield put({ type: c.SAVE_SEARCH_SETTINGS_COMPLETED, payload: response });
  } catch (err) {
    yield put({ type: c.SAVE_SEARCH_SETTINGS_FAILED, payload: err });
  }
}

function* saveRecsSettings({ payload }) {
  try {
    const {
      recs,
      forDiff,
      recsVisible: { value: recsVisible },
    } = yield select((store) => store.settings);

    const diff = objectDifference(recs, forDiff.recs);

    const dataToUpdate = {};

    if (!_.isEqual(diff, {})) dataToUpdate.recs = diff;
    if (recsVisible !== forDiff.recsVisible) {
      dataToUpdate.recsVisible = recsVisible;
      if (recsVisible && !recs.onboarding.completed) {
        dataToUpdate.recs = { onboarding: { completed: true } };
      }
    }

    const response = yield updateAppSettings(payload, 'recs', dataToUpdate, true);
    yield put({ type: c.SAVE_RECS_SETTINGS_COMPLETED, payload: response });
  } catch (err) {
    yield put({ type: c.SAVE_RECS_SETTINGS_FAILED, payload: err });
  }
}

function* forceUpdateRecsVisible({ payload }) {
  try {
    const { token, isVisible } = payload;
    const { forDiff: { recs: { onboarding } } } = yield select((store) => store.settings);
    const dataToUpdate = { recsVisible: isVisible };
    if (!onboarding.completed) dataToUpdate.recs = { onboarding: { completed: true, step3: true } };

    yield updateAppSettings(token, 'recs', dataToUpdate, true);

    yield put({ type: c.FORCE_UPDATE_RECS_VISIBLE_COMPLETE });
  } catch (err) {
    yield put({ type: c.FORCE_UPDATE_RECS_VISIBLE_FAILED, payload: err });
  }
}

function* forceUpdateSearchVisible({ payload }) {
  try {
    const { token, isVisible } = payload;
    const { forDiff: { search: { onboarding } } } = yield select((store) => store.settings);
    const dataToUpdate = { searchVisible: isVisible };
    if (!onboarding.completed) dataToUpdate.search = { onboarding: { completed: true } };
    yield updateAppSettings(token, 'search', dataToUpdate, true);

    yield put({ type: c.FORCE_UPDATE_SEARCH_VISIBLE_COMPLETE });
  } catch (err) {
    yield put({ type: c.FORCE_UPDATE_SEARCH_VISIBLE_FAILED, payload: err });
  }
}

export default function* settings() {
  yield takeLatest(c.GET_ALL_SETTINGS, getSettings);
  yield takeLatest(c.GET_DESCRIBES, getSettingDescribes);

  yield takeEvery(c.GET_ALERTS, getAlerts);
  yield takeLatest(c.UPDATE_ALERT_BANNERS, updateAlertBanners);

  yield takeLatest(c.SAVE_SEARCH_SETTINGS, saveSearchSettings);
  yield takeLatest(c.SAVE_RECS_SETTINGS, saveRecsSettings);

  yield takeLatest(c.FORCE_UPDATE_SEARCH_VISIBLE, forceUpdateSearchVisible);
  yield takeLatest(c.FORCE_UPDATE_RECS_VISIBLE, forceUpdateRecsVisible);
}
