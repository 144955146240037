import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import OnboardingButton from './OnboardingButton';
import StrangeIcon from './StrangeIcon';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '30px',
  },
  cardHeader1: {
    fontSize: '50px',
    height: '80px',
    verticalAlign: 'middle',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  cardSubheader1: {
    paddingBottom: '30px',
    height: '80px',
    textAlign: 'center',
  },
  cardText: {
    color: '#868b8e',
    fontSize: '14px',
    paddingTop: '10px',
    marginTop: '20px',
    // borderTop: '1px solid #efefef',
  },
}));

export default function OnboardingCard({ card }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Grid item xs={4}>
      <Paper className={classes.card}>
        <Typography className={classes.cardHeader1}>{card.step}</Typography>
        <Typography className={classes.cardSubheader1}>{card.header}</Typography>
        <OnboardingButton
          color={card.buttonColor}
          onClick={() => {
            dispatch(card.buttonOnClick());
          }}
        >
          <StrangeIcon icon={card.buttonIcon} />
          <Typography>{card.buttonText}</Typography>
        </OnboardingButton>
        <Typography className={classes.cardText}>
          <StrangeIcon icon="info" />
          {card.meta}
        </Typography>
      </Paper>
    </Grid>
  );
}

OnboardingCard.propTypes = {
  card: PropTypes.shape({
    header: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonIcon: PropTypes.string,
    buttonText: PropTypes.string,
    meta: PropTypes.string,
    buttonOnClick: PropTypes.func,
    step: PropTypes.number,
  }).isRequired,
};
