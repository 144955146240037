import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Slider from '../../Slider/Slider';

const SettingRangeSlider = ({
  min,
  max,
  value,
  step,
  title,
  description,
  onChange,
}) => (
  <div className="setting-item-container">
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={false} sm={1} />
      <Grid item xs={10} sm={8}>
        <div className="setting-item-title">{title}</div>
        <div className="setting-item-description">{description && description}</div>
      </Grid>

      <Grid
        item
        container
        justify="flex-end"
        xs={3}
        alignItems="center"
        direction="row"
      >
        <Grid item xs={2}>
          <div className="setting-item-description">
            {value}
          </div>
        </Grid>
        <Grid item xs={10}>
          <Slider
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            value={value}
            onChange={(e, newValue) => onChange(newValue)}
            min={min}
            max={max}
            step={step}
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

SettingRangeSlider.defaultProps = {
  description: '',
};

SettingRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SettingRangeSlider;
