import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import * as a from '../../../../../actions';
import RecsFieldSetting from '../../../../../components/kea/RecsFieldSetting';
import ContainerHeader from '../../../../../components/ContainerHeader';
import IntlMessages from '../../../../../util/IntlMessages';
import SaveActionBtnGrp from '../../../../../components/kea/settings/SaveActionBtnGrp';

const RecsFields = ({ match }) => {
  const dispatch = useDispatch();
  const { recs } = useSelector((store) => store);
  const { fields: { data, isUpdating } } = recs;

  const changeHandler = (id, value) => dispatch(a.fieldsDataUpdate(id, 'weight', value));
  const discard = () => dispatch(a.discardRecsFieldsSetting());
  const save = () => dispatch(a.saveRecsFields());

  const buttons = [
    { text: 'Save', action: save, color: 'primary' },
    { text: 'Discard', action: discard, color: 'secondary' },
  ];

  return (
    <div className="app-wrapper slideInUpTiny">
      <ContainerHeader
        match={match}
        title={<IntlMessages id="recommendations.fields.title" />}
        action={<SaveActionBtnGrp isFetching={isUpdating} buttons={buttons} disabled={isUpdating} />}
      />
      <Card className="jr-card full-height-card">
        <div className="settings-container">
          <div className="jr-card-header">
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={4}>
                <h3 className="card-heading">Field name</h3>
              </Grid>
              <Grid container item xs={8} justify="center">
                <h3 className="card-heading">Priority</h3>
              </Grid>
            </Grid>
          </div>
          {
            Object.entries(data).map(([key, value]) => (
              <RecsFieldSetting
                key={key}
                id={value.id}
                value={value.weight}
                onChange={changeHandler}
                title={value.title}
              />
            ))
          }
        </div>
      </Card>
    </div>
  );
};

export default RecsFields;
