// https://docs.appian.com/suite/help/20.4/ux_charts.html
// const a1 = "#015faa"
// const a2 = "#1f87ab"
// const a3 = "#45a98f"
// const a4 = "#82c272"
// const temp ={
//   light: '#82c272',
//   normal: '#45a98f',
//   dark: '#1f87ab',
//   darker: '#015faa',
// }
// https://www.tutorialrepublic.com/html-reference/html-color-picker.php
// https://maketintsandshades.com/#82c272,45a98f,1f87ab,015faa
export const chartColors = {
  main: {
    lighter: '#B4DAAA',
    light: '#9BCE8E',
    normal: '#82c272',
    dark: '#5B874F',
    darker: '#344D2D',
  },
  different: {
    lighter: '#78B7CC',
    light: '#4B9FBB',
    normal: '#1F88AB',
    dark: '#155F77',
    darker: '#0C3644',
  },
  accent: {
    lighter: '#78B7CC',
    light: '#4B9FBB',
    normal: '#45A98E',
    dark: '#307663',
    darker: '#1B4338',
  },
  howToNameIt: {
    lighter: '#669ECC',
    light: '#337EBB',
    normal: '#015EAA',
    dark: '#004177',
    darker: '#002544',
  },
  gray: {
    lighter: '#F9FAFB',
    light: '#F4F6F8',
    normal: '#DFE3E8',
    dark: '#C4CDD5',
    darker: '#C4CDD5',
  },
};

function buildchartGradients() {
  const result = {};
  result.chartColors = {
    color1: chartColors.main.light,
    color2: chartColors.main.dark,
  };
  result.gradient2 = {
    color1: chartColors.main.light,
    color2: chartColors.main.dark,
  };
  result.gradient3 = {
    color1: chartColors.different.normal,
    color2: chartColors.main.normal,
  };
  return result;
}

export const chartGradients = buildchartGradients();
