import React from 'react';

import { Line } from 'react-chartjs-2';
import { chartGradients } from 'util/chartUtils';

const WidgetPerformanceBlock = ({ widget }) => {
  const options = {
    type: 'line',
    title: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        ticks: {
          display: false,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
          tickMarkLength: 0,
        },
      }],
      yAxes: [{
        ticks: {
          // display: false,
          beginAtZero: true,
        },
      }],
    },
  };
  function getData(arr, name) {
    return arr.map((e) => e[name]);
  }

  function calcCtr(views, clicks) {
    return clicks.map((click, index) => (views[index] === 0 ? 0 : click / views[index]))
      .map((e) => e.toFixed(2) * 100)
      .map((e) => e.toString().split('.').shift());
  }
  const prepareData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 800, 0);
    gradient.addColorStop(0.1, chartGradients.gradient3.color1);
    gradient.addColorStop(0.95, chartGradients.gradient3.color2);
    return {
      // todo: format date
      labels: getData(widget.curve, 'date').map((e) => new Date(e).toISOString().slice(0, 10)),
      datasets: [{
        backgroundColor: gradient,
        borderWidth: 0,
        data: calcCtr(getData(widget.curve, 'view'), getData(widget.curve, 'click')),
        label: 'Click through rate',
        fill: 'start',
      }],
    };
  };
  // console.log("rendered " + widget.id);
  return (
    <div className="widget-block container-fluid">
      <div className="row">
        <div className="col-12">
          <h3 className="font-weight-light">{widget.data.title}</h3>
        </div>
      </div>
      <div className="row widget-body">
        <div className="col-7" style={{ height: '100px' }}>
          <Line data={prepareData} options={options} />
        </div>
        <div className="col-3 table-container">
          <table className="data-table default-table table-unbordered table table-sm">
            <thead className="th-border-b">
              <tr>
                <th>Showed</th>
                <th>Viewed</th>
                <th>Clicked</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{widget.counters.show}</td>
                <td>{widget.counters.view}</td>
                <td>{widget.counters.click}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-2 main-value-container">
          <div className="main-value">
            {widget.stats.clickRate}
            %
          </div>
        </div>
      </div>
    </div>
  );
};
export default WidgetPerformanceBlock;
