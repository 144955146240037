/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import { getRelatedProductsByQuery } from 'actions';
import { linkToProductDetail } from 'util/appUtils';
import CardContainerSmall from 'components/kea/CardContainerSmall';

const RelatedProducts = ({ match }) => {
  const { params: { query } } = match;
  const dispatch = useDispatch();
  const { analytics: { products, timeframe }, app: { currentShop } } = useSelector((store) => store);
  const { relatedByQuery: { isFetching, data, error } } = products;

  useEffect(() => {
    dispatch(getRelatedProductsByQuery(currentShop, timeframe, query));
  }, [timeframe, query]);

  const headCells = [
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'count', numeric: true, label: 'Count' },
  ];

  const paginationOptions = { rowsPerPageList: [6], initRowsPerPage: 6 };
  const prepareData = (e) => ({
    ...e,
    name: linkToProductDetail(currentShop, e.name, e.productId),
    id: e.productId,
  });


  return (
    <CardContainerSmall
      headerId="search.analytics.queryDetail.related.products.header"
      error={error}
      isFetching={isFetching}
      showMoreUrl={false}
    >
      <AnalyticTable
        paginationOptions={paginationOptions}
        rows={data.map(prepareData)}
        headCells={headCells}
      />
    </CardContainerSmall>
  );
};

export default RelatedProducts;
