/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { genRandomKey } from 'util/appUtils';

export default function WidgetTable({ headCells, rows }) {
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {
              headCells.map((e) => <TableCell key={e.id}>{e.label}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={(row.id || row.query) + genRandomKey()}>
              {
                headCells[0].label === '#'
                  ? <TableCell>{i + 1}</TableCell>
                  : null
              }
              {
                headCells[0].label === '#'
                  ? headCells.slice(1).map((cellId) => (
                    <TableCell key={cellId.id}>{row[cellId.id]}</TableCell>
                  ))
                  : headCells.map((cellId) => (
                    <TableCell key={cellId.id}>{row[cellId.id]}</TableCell>
                  ))

              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

WidgetTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
};
