import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from 'react-device-detect';
import { getStartMode } from 'util/appUtils';
import SideBar from '../../SideBar';

const Vertical = (props) => {
  const { drawerType } = useSelector(({ app }) => app);
  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

  // set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height');
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height');
  }

  return (
    <div className={`app-container ${drawerStyle}`}>
      <SideBar />

      <div className="app-main-container">
        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            {props.children}
          </div>
        </main>
      </div>
      <ColorOption />
    </div>
  );
};

export default withRouter(Vertical);
