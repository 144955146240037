import React from 'react';
import { Switch, Grid, FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';

const SettingsToggleSwitcher = ({ title, description, value, onChange }) => {
  const toggleHandler = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className="setting-item-container">
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Grid item xs={false} sm={1} />
        <Grid item xs={10} sm={8}>
          <div className="setting-item-title">{title}</div>
          <div className="setting-item-description">{description && description}</div>
        </Grid>
        <Grid
          container
          item
          xs={2}
          sm={3}
          justify="flex-end"
        >
          <FormGroup>
            <Switch
              color="primary"
              checked={value}
              onChange={toggleHandler}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
};

SettingsToggleSwitcher.defaultProps = {
  description: null,
};

SettingsToggleSwitcher.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.bool.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default SettingsToggleSwitcher;
