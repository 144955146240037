import React from 'react';
import { Link } from 'react-router-dom';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainer from 'components/kea/CardContainer';
import PropTypes from 'prop-types';

const MainSection = ({ match, queryPopular }) => {
  const { isFetching, data, error } = queryPopular;

  const headCells = [
    { id: 'query', numeric: false, label: 'Query' },
    { id: 'users', numeric: true, label: 'Users' },
    { id: 'total', numeric: true, label: 'Total' },
    { id: 'click', numeric: true, label: 'Click' },
    { id: 'noaction', numeric: true, label: 'No action' },
    { id: 'refine', numeric: true, label: 'Refine' },
    { id: 'ctr', numeric: true, label: 'CTR' },
  ];

  const prepareRows = (rows) => rows.map((el, index) => ({
    ...el,
    ctr: Math.round(el.ctr * 100),
    query: (<Link to={`${match.url}/query/${el.query}`}>{el.query}</Link>),
    id: `${el.query}-${index}`,
  }));


  return (
    <CardContainer
      headerId="search.analytics.queryPopular.header"
      styleName="min-height-700px"
      isFetching={isFetching}
      error={error}
    >
      <AnalyticTable
        rows={prepareRows(data)}
        headCells={headCells}
        orderById="total"
      />
    </CardContainer>
  );
};

MainSection.defaultProps = {
  queryPopular: {

  },
};

MainSection.propTypes = {
  match: PropTypes.any.isRequired,
  queryPopular: PropTypes.shape({
    isFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.array,
  }),
};

export default MainSection;
