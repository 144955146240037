import * as c from '../constants/actionTypes/catalog';

export const getProductById = (shopId, range, productId) => ({
  type: c.GET_PRODUCT_BY_ID,
  payload: { shopId, range, productId },
});

export const getProducts = (shopId, start = 0, limit = 0) => ({
  type: c.GET_PRODUCTS,
  payload: { shopId, start, limit },
});

export const filterProducts = (query, data) => ({
  type: c.FILTER_PRODUCTS_BY_QUERY,
  payload: { query, data },
});
