import React from 'react';

const DashboardStub = ({ imgName }) => (
  <div className="noDataAvailableChart">
    <img
      src={require(`assets/images/dashboard/${imgName || 'no-data-available-1.svg'}`)}
      alt="Data is not available yet"
    />
  </div>
);

export default DashboardStub;
