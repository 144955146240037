import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';

function AdditionalProfit({ money }) {
  const { currencyId } = useSelector((store) => store.settings);

  const chartOptions = {
    legend: {
      display: false,
      position: 'bottom',
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  };

  function prepareData(data) {
    const { main, different, gray } = chartColors;

    return {
      labels: ['No recs', 'With recs'],
      datasets: [
        {
          label: 'Other purchases',
          backgroundColor: gray.normal,
          data: [data.noRecs, data.noRecsDelta],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        }, {
          label: 'Purchases by the users who used recommendations',
          backgroundColor: different.normal,
          data: [0, data.recsFullDelta],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        }, {
          label: 'Additional sales by recommendations',
          backgroundColor: main.normal,
          data: [0, data.recs],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        },
      ],
    };
  }

  if (money.error) {
    return (
      <CardBox header="Additional Profit">
        <DashboardStub />
      </CardBox>
    );
  }
  const chartData = prepareData(money);


  return (
    <CardBox header="Additional Profit">
      <div className="row">
        <div className="col-6">
          <Bar data={chartData} options={chartOptions} height={240} />
        </div>
        <div className="col-6">
          <p>{`Visitors who used recommendations have made ${money.recsFullRate}% of sales.`}</p>
          <p>{`Products which have been found directly from recommendations increased sales on ${money.totalRecPureRate}%`}</p>
          <p>{`and additionally made ${money.recs} ${currencyId}`}</p>
        </div>
      </div>

    </CardBox>
  );
}

AdditionalProfit.propTypes = {
  money: PropTypes.shape({
    recs: PropTypes.any,
    noRecs: PropTypes.any,
    noRecsDelta: PropTypes.any,
    totalRecPureRate: PropTypes.any,
    recsFullRate: PropTypes.any,
    recsFullDelta: PropTypes.any,
    error: PropTypes.any,
  }).isRequired,
};

export default AdditionalProfit;
