/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, filterProducts } from 'actions/Catalog';
import CardContainer from 'components/kea/CardContainer';
import ContainerHeader from 'components/ContainerHeader';
import { useHistory } from 'react-router-dom';
import ProductsTable from 'components/kea/tables/ProductsTable';
import SearchInput from './SearchInput';

const ProductImage = ({ src, name }) => (
  <img
    style={{ height: 70, width: 70, border: '1px solid lightgrey' }}
    src={src}
    alt={name}
  />
);

const getDataForTable = (data, filteredData, filterQuery) => {
  if (filterQuery && filterQuery !== '') {
    return filteredData;
  }
  return data;
};

const ProductList = ({ match }) => {
  const {
    catalog: { productList },
    settings,
  } = useSelector((store) => store);
  const { data, isFetching, isFiltering, filterQuery, filteredData } = productList;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (settings.isFetched && !data) {
      dispatch(getProducts(settings.shopId));
    }
  }, [settings.isFetched]);

  const headCells = [
    { id: 'image', numeric: false, label: '' },
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'vendor', numeric: false, label: 'Vendor Code' },
  ];

  const preparedData = data && data.map((product) => ({
    ...product,
    image: <ProductImage src={product.thumbnail} name={product.name} />,
  }));

  const filterHandler = ({ target }) => {
    if (filterQuery !== target.value) {
      dispatch(filterProducts(target.value, preparedData));
    }
  };
  const clearFilter = () => {
    if (filterQuery !== '') {
      dispatch(filterProducts('', preparedData));
    }
  };

  const filterInput = (
    <SearchInput
      onClear={clearFilter}
      value={filterQuery}
      changeHandler={filterHandler}
      isFiltering={isFiltering}
    />
  );

  const rowClickHandler = (event, id) => {
    if (event.ctrlKey) {
      return window.open(`${window.location.href}/${id}`, '_blank');
    }
    history.push(`${match.url}/${id}`);
  };

  return (
    <div className="app-wrapper-full-width animated slideInUpTiny animation-duration-3">
      <ContainerHeader match={match} title="Product catalog" />
      <CardContainer
        styleName="min-height-700px noMargin"
        isFetching={isFetching}
        headerPostfix={filterInput}
      >
        {
          data && (
            <ProductsTable
              rowHandleClick={rowClickHandler}
              rows={getDataForTable(preparedData, filteredData, filterQuery)}
              headCells={headCells}
            />
          )
        }
      </CardContainer>
    </div>
  );
};

export default ProductList;
