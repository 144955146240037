import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import classes from './SetupRecs.module.scss';
import Header from '../Typography/Header';
import Subheader from '../Typography/Subheader';
import Center from '../Layout/Center';
import TextCenter from '../Typography/TextCenter';
import SetupRecsIndustry from './SetupRecsIndustry';
import SetupRecsFields from './SetupRecsFields';
import SetupRecsAudience from './SetupRecsAudience';
import {
  onboardingRecsChange,
  onboardingRecsWizardComplete,
} from '../../slices/OnboardingSlice';
import { toggleValueInArray } from '../../util/appUtils';

export default function SetupRecs() {
  const [page, changePage] = useState(0);
  const store = useSelector((s) => s);
  const { industries, fields } = store.onboarding.recs;
  const dispatch = useDispatch();
  const changeIndustries = (value) => {
    const copy = [...industries];
    dispatch(onboardingRecsChange({ industries: toggleValueInArray(value, copy) }));
  };

  const changeFields = (value) => {
    const copy = [...fields];
    dispatch(onboardingRecsChange({ fields: toggleValueInArray(value, copy) }));
  };

  const changeAudience = (value) => {
    dispatch(onboardingRecsChange({ audienceSize: value }));
  };

  const completeWizard = () => {
    dispatch(onboardingRecsWizardComplete());
  };

  const pages = [
    <SetupRecsIndustry onClick={changeIndustries} className={classes.page} />,
    <SetupRecsFields onClick={changeFields} className={classes.page} />,
    <SetupRecsAudience onClick={changeAudience} className={classes.page} />,
  ];

  const prevButton = (page === 0
    && <Button variant="contained" onClick={completeWizard}>Skip wizard</Button>)
    || <Button variant="contained" onClick={() => changePage(page - 1)}>Back</Button>;

  const nextButton = (page === 2
    && <Button variant="contained" color="primary" onClick={completeWizard}>Finish</Button>)
    || <Button variant="contained" color="primary" onClick={() => changePage(page + 1)}>Next</Button>;

  return (
    <Center>
      <TextCenter>
        <Header>Welcome to Kea Labs Advanced Recommendations</Header>
        <Subheader>Before enabling recommendations, please answer a few simple questions and Kea Labs will automatically adjust recommendations to your business.</Subheader>
      </TextCenter>

      { pages[page] }
      <div className={classes.buttons}>
        {prevButton}
        {nextButton}
      </div>
    </Center>
  );
}
