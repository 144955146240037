import * as c from '../constants/actionTypes/settings';

export const changeSearchOnboardingSettings = (settings) => ({
  type: c.CHANGE_SEARCH_ONBOARDING,
  payload: settings,
});

export const changeRecsOnboardingSettings = (settings) => ({
  type: c.CHANGE_RECS_ONBOARDING,
  payload: settings,
});
