import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const SettingTextInput = ({
  title, description, onChange, value,
}) => (
  <div className="setting-item-container">
    <Grid
      container
      direction="row"
      justify="center"
    >
      <Grid item xs={false} sm={1} />
      <Grid item xs={6}>
        <div className="setting-item-title">{title}</div>
        <div className="setting-item-description">{description && description}</div>
      </Grid>

      <Grid container item xs={5} justify="flex-end">
        <TextField
          style={{ width: 200, textAlign: 'center' }}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </Grid>
    </Grid>
  </div>
);

export default SettingTextInput;
