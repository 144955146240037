import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Onboarding from './Onboarding';
import SetupRecs from '../components/SetupRecs/SetupRecs';
import SupportRow from '../components/Onboarding/SupportRow';
import {
  onboardingRecsPreview,
  onboardingRecsCustomize,
  onboardingRecsActivate,
} from '../slices/OnboardingSlice';

function buildCards(history, shopId) {
  return [
    {
      id: 'step1',
      header: 'Safely preview Recommendations',
      buttonColor: '#0187ac',
      buttonText: 'Preview',
      buttonIcon: 'search',
      buttonOnClick: () => onboardingRecsPreview(),
      meta: 'Sneak peek Recommendations before making it enabled for all of your visitors.',
    }, {
      id: 'step2',
      header: 'Customize Recommendations and Style',
      buttonColor: '#0187ac',
      buttonText: 'Customize',
      buttonIcon: 'edit',
      buttonOnClick: () => {
        history.push(`/app/${shopId}/recs/settings`);
        return onboardingRecsCustomize();
      },
      meta: 'Edit visual style and behaviour of recommendations to match your needs',
    }, {
      id: 'step3',
      header: 'Make Recommendations enabled for your customers!',
      buttonColor: 'green',
      buttonText: 'Activate',
      buttonIcon: 'boat',
      buttonOnClick: () => {
        history.push(`/app/${shopId}/recs/dashboard`);
        return onboardingRecsActivate();
      },
      meta: 'Make Kea Labs Recommendations active and show it for all of your visitors.',
    },
  ];
}

export default function OnboardingRecs() {
  const store = useSelector((store) => store);
  const { shopId } = store.settings;
  const { onboarding } = store;
  const history = useHistory();
  const cards = buildCards(history, shopId);

  if (!onboarding.recs.wizardCompleted) {
    return (
      <>
        <SetupRecs />
        <Grid container justify="center" spacing={4}>
          <Grid item xs={9}>
            <SupportRow />
          </Grid>
        </Grid>
      </>
    );
  }

  if (!onboarding.recs.completed) {
    return (
      <Onboarding
        cards={cards}
        header="Setup Kea Labs Recommendations"
        subheader1="For your convenience, Kea Labs Recommendations is not enabled for all of your visitors yet, so you can safely preview it, adjust Recommendations behaviour, visual styles, and enable it once you'll be happy with results."
        subheader2="Configure and activate Kea Labs Recommendations in 3 easy steps:"
      />
    );
  }

  return <></>;
}
