import * as c from '../constants/actionTypes/search';

export const getSearchFields = (token) => ({
  type: c.GET_FIELDS,
  payload: { token },
});

export const fieldsDataUpdate = (field, key, value) => ({
  type: c.FIELDS_CHANGE_HANDLER,
  payload: { field, key, value },
});

export const discardSearchFieldsSetting = () => ({
  type: c.DISCARD_FIELDS_CHANGES,
});

export const saveSearchFields = (token) => ({
  type: c.SAVE_FIELDS_CHANGES,
  payload: token,
});
