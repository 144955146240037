import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import App from './App';
import Auth from './Auth';
import Billing from './Billing';
import Catalog from './Catalog';
import Analytics from './Analytics';
import Recs from './Recs';
import Search from './Search';
import Settings from './Settings';
import Notifications from './Notifications';
import OnboardingSlice from '../slices/OnboardingSlice';

export default (history) => combineReducers({
  router: connectRouter(history),
  app: App,
  auth: Auth,
  billing: Billing,
  catalog: Catalog,
  analytics: Analytics,
  recs: Recs,
  search: Search,
  settings: Settings,
  notifications: Notifications,
  onboarding: OnboardingSlice,
});
