import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as a from 'actions';
import SettingsToggleSwitcher from 'components/kea/settings/SettingsToggleSwitcher';
import SettingsSelectInput from 'components/kea/settings/SettingsSelectInput';
import SettingRangeSlider from 'components/kea/settings/SettingRangeSlider';
import SettingTextInput from 'components/kea/settings/SettingTextInput';


const Autocomplete = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store);
  const { search: { searchBox } } = settings;

  const sliderHandlerMinSymbols = (value) => dispatch(a.changeSearchBoxSetting('minSymbols', value));
  const changeHandlerSingleResult = (value) => dispatch(a.changeSearchBoxSetting('navigateToProductIfSingleResult', value));
  const changeHandlerSingleResultId = (value) => dispatch(a.changeSearchBoxSetting('navigateToProductIfSingleResultOnlyWhenIdMatches', value));
  const sliderHandlerMaxCategories = (value) => dispatch(a.changeSearchBoxSetting('maxCategories', value));
  const sliderHandlerMaxProducts = (value) => dispatch(a.changeSearchBoxSetting('maxProducts', value));
  const sliderHandlerMaxContent = (value) => dispatch(a.changeSearchBoxSetting('maxContent', value));
  const changeHandlerAlign = (value) => dispatch(a.changeSearchBoxSetting('align', value));
  const changeHandlerCategoryName = (value) => dispatch(a.changeSearchBoxSetting('categoryFacetName', value));

  return (
    <div className="settings-container">
      <div className="setting-group-header">Behavior</div>
      <SettingRangeSlider
        value={searchBox.minSymbols}
        onChange={sliderHandlerMinSymbols}
        title="Min number of symbols required for search"
        min={1}
        max={5}
        step={1}
      />

      <SettingsToggleSwitcher
        title="Automatically navigate to a product if only one item matches"
        value={searchBox.navigateToProductIfSingleResult}
        onChange={changeHandlerSingleResult}
      />

      <SettingsToggleSwitcher
        title="Automatically navigate to a product if only one item was found and it matches the product id"
        value={searchBox.navigateToProductIfSingleResultOnlyWhenIdMatches}
        onChange={changeHandlerSingleResultId}
      />

      <div className="setting-group-header"> Number of items to show </div>
      <div className="setting-group-note">
        Kea Labs can search over other types of data (i.e. articles, feedbacks...)
        Please contact our team if you need to search over
        something else than products and categories.
      </div>
      <SettingRangeSlider
        value={searchBox.maxCategories}
        onChange={sliderHandlerMaxCategories}
        title="Max number of categories to show"
        min={0}
        max={10}
        step={1}
      />

      <SettingRangeSlider
        value={searchBox.maxProducts}
        onChange={sliderHandlerMaxProducts}
        title="Max number of products to show"
        min={0}
        max={15}
        step={1}
      />

      <SettingRangeSlider
        value={searchBox.maxContent}
        onChange={sliderHandlerMaxContent}
        title="Max number of other items to show"
        min={0}
        max={15}
        step={1}
      />
      <div className="setting-group-header">Other settings</div>
      <div className="setting-group-note">
        {`
            Based on the style of the store,
            it's sometimes needed to change an alignment of search results. 
            For example, if it's located on the right side of the page,
             you may wish to align search results on the right side as well.
        `}
      </div>

      <SettingsSelectInput
        title="Align result items"
        items={[{ value: 'left', text: 'Left' }, { value: 'center', text: 'Center' }, { value: 'right', text: 'Right' }]}
        value={searchBox.align}
        onChange={changeHandlerAlign}
      />

      <SettingTextInput
        title="Title for the category section"
        value={searchBox.categoryFacetName}
        onChange={changeHandlerCategoryName}
      />

      {/* <div style={{ whiteSpace: 'pre' }}> */}
      {/*  { JSON.stringify(searchBox, null, 4) } */}
      {/* </div> */}
    </div>
  );
};

export default Autocomplete;
