import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import asyncComponent from 'util/asyncComponent';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import DateRangePicker from 'components/kea/DateRangePicker';
import { changeTimeframe } from 'actions';
import SafetyRoute from 'components/kea/SafetyRoute';
import RecsAnalyticsWidgetsPerformance from './RecsAnalyticsWidgetsPerformance/index';

const Analytics = ({ match }) => {
  const dispatch = useDispatch();
  const { analytics: { timeframe } } = useSelector((store) => store);

  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));

  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  return (
    <div className="app-wrapper animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={<IntlMessages id="recs.analytics" />}
      />

      <Switch>
        <SafetyRoute path={`${match.url}/widgets-performance`}><RecsAnalyticsWidgetsPerformance /></SafetyRoute>

        <Route component={asyncComponent(() => import('app/routes/404'))} />
      </Switch>
    </div>
  );
};

export default Analytics;
