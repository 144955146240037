import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import billingSagas from './Billing';
import analyticSagas from './Analytic';
import catalogSagas from './Catalog';
import settingsSagas from './Settings';
import recs from './Recs';
import search from './Search';
import notifications from './Notifications';
import errorHandlers from './ErrorHandlers';
import { sagas as Onboarding } from '../slices/OnboardingSlice';

export default function* rootSaga() {
  yield all([
    catalogSagas(),
    authSagas(),
    billingSagas(),
    analyticSagas(),
    settingsSagas(),
    recs(),
    search(),
    notifications(),
    errorHandlers(),
    Onboarding(),
  ]);
}
