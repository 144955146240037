/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import SearchRouter from 'app/routes/search/SearchRouter';
import RecsRouter from 'app/routes/recs/RecsRouter';
import Error404 from 'app/routes/404';
import { useDispatch, useSelector } from 'react-redux';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import IncorrectShop from 'components/kea/placeholders/IncorrectShop';
import * as actions from 'actions';
import StoreRouter from './store/StoreRouter';
import { appInIframe } from '../../util/appUtils';

const AppRouter = ({ match }) => {
  const { auth: { authorization }, app: { currentShop } } = useSelector((store) => store);

  const spinnerIsSpinning = !currentShop || authorization.isFetching;
  if (spinnerIsSpinning) {
    return <PlaceholderSpinner />;
  }

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={(props) => <IncorrectShop {...props} authorization={authorization} />} />
      <Route path={`${match.url}/:token`} component={NestedRoutes} />
      <Route component={Error404} />
    </Switch>
  );
};


const NestedRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const [wrongShop, setWrongShop] = useState(false);

  const fetchShopData = useCallback((token) => {
    dispatch(actions.getSettings(token));
    dispatch(actions.getAlerts(token, 'search'));
    dispatch(actions.getAlerts(token, 'recs'));
    dispatch(actions.getSearchFields(token));
  }, [dispatch]);

  const { app, auth } = useSelector((store) => store);
  const { currentShop } = app;
  const { authorization } = auth;
  const { shopMap } = authorization;

  useEffect(() => {
    if (shopMap[currentShop]) {
      fetchShopData(currentShop);
    } else if (appInIframe()) {
      fetchShopData(currentShop);
    } else {
      setWrongShop(true);
    }
  }, [currentShop, shopMap]);

  if (wrongShop) {
    return (
      <IncorrectShop authorization={authorization} />
    );
  }

  const { url } = match;
  return (
    <>
      <Switch>
        <Route path={`${url}/store`} component={StoreRouter} />
        <Route path={`${url}/search`} component={SearchRouter} />
        <Route path={`${url}/recs`} component={RecsRouter} />
      </Switch>
    </>
  );
};

export default withRouter(AppRouter);
