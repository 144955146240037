import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import classes from './Subheader.module.scss';

export default function Subheader({ variant, children }) {
  if (variant === '1') {
    return <Typography className={classes.subheader1}>{children}</Typography>;
  }
  if (variant === '2') {
    return <Typography className={classes.subheader2}>{children}</Typography>;
  }
}

Subheader.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

Subheader.defaultProps = {
  variant: '1',
  children: undefined,
};
