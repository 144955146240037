import React from 'react';


const PlaceholderError = ({ height = 250, error }) => {
  if (error === 'NO_DATA') {
    return (
      <div
        className="loader-view placeholder-no-data"
        style={{ height }}
      >
        <img
          src={require('assets/images/dashboard/no-data-available-3.svg')}
          alt={error}
        />
      </div>
    );
  }

  return (
    <div className="loader-view placeholder-error">
      <img
        src={require('assets/images/placeholder/server-is-no-available-1.svg')}
        alt={error}
      />
      <br />
      <h1>Sorry, something went wrong.</h1>
    </div>
  );
};
export default PlaceholderError;
