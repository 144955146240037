/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import QueryTimeline from './QueryTimeline';
import RelatedQueries from './RelatedQueries';
import RelatedProducts from './RelatedProducts';

const QueryDetail = ({ match }) => (
  <Grid>
    <Grid spacing={4} container>
      <Grid item xs={8}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QueryTimeline match={match} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <RelatedQueries match={match} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <RelatedProducts match={match} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default QueryDetail;
