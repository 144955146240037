/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { linkToQueryDetail } from 'util/appUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getRelatedQueriesByProduct } from 'actions';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainerSmall from 'components/kea/CardContainerSmall';

const RelatedQueries = ({ productId }) => {
  const dispatch = useDispatch();
  const { analytics: { search, timeframe }, app: { currentShop } } = useSelector((str) => str);
  const { relatedQueriesByProduct: { isFetching, data, error } } = search;

  useEffect(() => {
    dispatch(getRelatedQueriesByProduct(currentShop, timeframe, productId));
  }, [timeframe, productId]);

  const headCells = [
    { id: 'query', numeric: false, label: 'Query' },
    { id: 'count', numeric: true, label: 'Count' },
  ];

  const sortDesc = (a, b) => b.total - a.total;
  const attachLinkToArray = (e) => ({ ...e, query: linkToQueryDetail(currentShop, e.query) });
  const paginationOptions = {
    rowsPerPageList: [10],
    initRowsPerPage: 10,
  };

  return (
    <CardContainerSmall
      headerId="catalog.product.related.queries"
      error={error}
      isFetching={isFetching}
    >
      <AnalyticTable
        paginationOptions={paginationOptions}
        rows={data.sort(sortDesc).map(attachLinkToArray)} // .slice(0, 5)
        headCells={headCells}
      />
    </CardContainerSmall>
  );
};

export default RelatedQueries;
