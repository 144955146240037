import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';
import { shopifyColors } from '../../../../util/appUtils';

const SearchTrends = ({ demand }) => {
  const options = {
    legend: {
      display: true,
      position: 'bottom',
    },
  };

  if (!demand || demand.error) {
    return (
      <CardBox
        header="Search trends"
        subheader="Chart shows how demand for the most popular product categories is changing"
        subsubheader="*Tip: click on categories to exclude them from the analysis."
      >
        <DashboardStub imgName="no-data-available-4.svg" />
      </CardBox>
    );
  }

  const data = {
    labels: demand.xAxis,
    datasets: _.map(demand.curves, (e, i) => ({
      label: e.name,
      data: e.data,
      fill: false,
      borderWidth: 1,
      borderColor: shopifyColors.bundle[i],
      backgroundColor: shopifyColors.bundle[i],
      pointRadius: 2,
    })),
  };

  return (
    <CardBox
      header="Search trends"
      subheader="Chart shows how demand for the most popular product categories is changing"
      subsubheader="*Tip: click on categories to exclude them from the analysis."
    >
      <div className="row">
        <div className="col-12">
          <Line
            data={data}
            options={options}
            height={130}
          />
        </div>
      </div>
    </CardBox>
  );
};

SearchTrends.propTypes = {
  demand: PropTypes.shape({
    curves: PropTypes.any,
    xAxis: PropTypes.any,


    error: PropTypes.any,
  }).isRequired,
};


export default SearchTrends;
