import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import NotificationSnackbar from 'containers/NotificationSnackbar';
import configureStore, { history } from './store';
import App from './containers/App';

export const store = configureStore();
const snackbarAnchor = { vertical: 'bottom', horizontal: 'center' };

const MainApp = () => (
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={snackbarAnchor}
    >
      <ConnectedRouter history={history}>
        <NotificationSnackbar />
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </SnackbarProvider>
  </Provider>
);

export default MainApp;
