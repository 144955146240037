import * as c from 'constants/actionTypes/analytics';

export const getABResults = (token, range) => ({
  type: c.GET_A_B_RESULTS,
  payload: { token, range },
});

export const getABCarts = (payload) => ({
  type: c.GET_A_B_CARTS,
  payload,
});

export const changeTimeframe = (range) => ({
  type: c.CHANGE_TIMEFRAME_RANGE,
  payload: range,
});

export const getSearchDashboard = (token, range) => ({
  type: c.GET_SEARCH_DASHBOARD,
  payload: { token, range },
});

export const getRecsDashboard = (token, range) => ({
  type: c.GET_RECS_DASHBOARD,
  payload: { range, token },
});

export const getAnalyticStats = (token, range) => ({
  type: c.GET_SEARCH_STATS,
  payload: { token, range },
});

export const getAnalyticFilterPopular = (token, range) => ({
  type: c.GET_SEARCH_FILTER_POPULAR,
  payload: { token, range },
});

export const getAnalyticLostOpportunities = (token, range) => ({
  type: c.GET_SEARCH_LOST_OPPORTUNITIES,
  payload: { token, range },
});

export const getAnalyticCurve = (token, range, type = 'all', query = null) => ({
  type: c.GET_SEARCH_CURVE,
  payload: { token, range, type, query },
});

export const getAnalyticQueryPopular = (token, range) => ({
  type: c.GET_SEARCH_QUERY_POPULAR,
  payload: { token, range },
});

export const getAnalyticQuerySorted = (token, range) => ({
  type: c.GET_SEARCH_QUERY_SORTED,
  payload: { token, range },
});

export const getAnalyticQueryNoResult = (token, range) => ({
  type: c.GET_SEARCH_QUERY_NO_RESULT,
  payload: { token, range },
});

export const getRelatedQueries = (token, range, query) => ({
  type: c.GET_SEARCH_RELATED_QUERIES,
  payload: { token, range, query },
});

export const getConversionBest = (token, range) => ({
  type: c.GET_PRODUCTS_CONVERSION_BEST,
  payload: { token, range },
});

export const getConversionWorst = (token, range) => ({
  type: c.GET_PRODUCTS_CONVERSION_WORST,
  payload: { token, range },
});

export const getPopularByView = (token, range) => ({
  type: c.GET_PRODUCTS_POPULAR_BY_VIEW,
  payload: { token, range },
});

export const getPopularByPurchase = (token, range) => ({
  type: c.GET_PRODUCTS_POPULAR_BY_PURCHASE,
  payload: { token, range },
});

export const getRelatedProductsByView = (token, range, productId) => ({
  type: c.GET_PRODUCTS_RELATED_BY_VIEW,
  payload: { token, range, productId },
});

export const getRelatedProductsByPurchase = (token, range, productId) => ({
  type: c.GET_PRODUCTS_RELATED_BY_PURCHASE,
  payload: { token, range, productId },
});

export const getRelatedProductsByQuery = (token, range, query) => ({
  type: c.GET_RELATED_PRODUCTS_BY_QUERY,
  payload: { token, range, query },
});

export const getRelatedQueriesByProduct = (token, range, productId) => ({
  type: c.GET_RELATED_QUERIES_BY_PRODUCT,
  payload: { token, range, productId },
});

export const getAnalyticsWidgetsPerformance = (token, range) => ({
  type: c.GET_RECS_WIDGETS_PERFORMANCE,
  payload: { token, range },
});
