import React from 'react';
import Grid from '@material-ui/core/Grid';

import OnboardingCard from '../components/Onboarding/OnboardingCard';
import SupportRow from '../components/Onboarding/SupportRow';
import Subheader from '../components/Typography/Subheader';
import Header from '../components/Typography/Header';
import Center from '../components/Layout/Center';
import TextCenter from '../components/Typography/TextCenter';

export default function Onboarding({ cards, header, subheader1, subheader2 }) {
  const cardsComponents = cards.map((card, index) => {
    // eslint-disable-next-line no-param-reassign
    card.step = index + 1;
    return <OnboardingCard key={card.id} card={card} />;
  });

  return (
    <Center>
      <TextCenter>
        <Header>{header}</Header>
        <Subheader>{subheader1}</Subheader>
        <Subheader variant="2">{subheader2}</Subheader>
      </TextCenter>
      <Grid container spacing={4}>{cardsComponents}</Grid>
      <SupportRow />
    </Center>
  );
}
