import * as c from 'constants/actionTypes/catalog';

const defaultState = {
  product: {
    isFetching: false,
  },
  productList: {
    isFetching: false,
    isFiltering: false,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    /**
       ***  CATALOG GET_PRODUCTS
       */

    case c.GET_PRODUCTS:
      return {
        ...state,
        productList: {
          ...state.productList,
          isFetching: true,
        },
      };

    case c.GET_PRODUCTS_COMPLETE:
      return {
        ...state,
        productList: {
          ...state.productList,
          data: action.payload,
          isFetching: false,
        },
      };

    case c.GET_PRODUCTS_FAILED:
      return {
        ...state,
        productList: {
          ...state.productList,
          isFetching: false,
        },
      };


      /**
       *** CATALOG GET_PRODUCT BY ID
       */

    case c.GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: {
          ...state.product,
          isFetching: true,
        },
      };

    case c.GET_PRODUCT_BY_ID_COMPLETE:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
          isFetching: false,
        },
      };

    case c.GET_PRODUCT_BY_ID_FAILED:
      return {
        ...state,
        product: {
          ...state.product,
          isFetching: false,
        },
      };

      /**
       *** CATALOG FILTER_PRODUCTS_BY_QUERY
       */

    case c.FILTER_PRODUCTS_BY_QUERY:
      return {
        ...state,
        productList: {
          ...state.productList,
          isFiltering: true,
          filterQuery: action.payload.query,
        },
      };

    case c.FILTER_PRODUCTS_BY_QUERY_COMPLETE:
      return {
        ...state,
        productList: {
          ...state.productList,
          isFiltering: false,
          filteredData: action.payload.data,
        },
      };

    default:
      return state;
  }
};
