import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ r }) => (
  <svg className="circular" viewBox="25 25 50 50">
    <circle className="path" cx="50" cy="50" r={r} fill="none" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

Spinner.defaultProps = {
  r: 15,
};

Spinner.propTypes = {
  r: PropTypes.number,
};

export default Spinner;
