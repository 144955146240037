import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import * as a from 'actions/Search';
import SearchFieldSetting from 'components/kea/SearchFieldSetting';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import SaveActionBtnGrp from 'components/kea/settings/SaveActionBtnGrp';
import { Card } from 'reactstrap';
import { objectDifference } from 'util/appUtils';

const SearchFields = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((s) => s);
  const { search, app } = store;
  const { currentShop } = app;
  const { fields } = search;
  const { data, isFetching, forDiff } = fields;
  const { match } = props;

  const weightChangeHandler = (id, value) => dispatch(a.fieldsDataUpdate(id, 'weight', value));
  const autoCompleteChangeHandler = (id, value) => dispatch(a.fieldsDataUpdate(id, 'autocomplete', value));
  const save = () => dispatch(a.saveSearchFields(currentShop));
  const discard = () => dispatch(a.discardSearchFieldsSetting());

  const buttons = [
    { text: 'Save', action: save, color: 'primary' },
    { text: 'Discard', action: discard, color: 'secondary' },
  ];

  const settingsChanged = Object.keys(objectDifference(forDiff, fields.data)).length !== 0;
  const disableSaving = !(settingsChanged);

  return (
    <div className="app-wrapper animated animation-duration-3 slideInUpTiny">
      <ContainerHeader
        match={match}
        title={<IntlMessages id="search.customization.title" />}
        action={<SaveActionBtnGrp isFetching={isFetching} buttons={buttons} disabled={disableSaving} />}
      />
      <Card className="jr-card full-height-card">
        <div className="settings-container">
          <div className="jr-card-header">
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Grid item xs={3}>
                <h3 className="card-heading">Field name</h3>
              </Grid>
              <Grid container item xs={6} justify="center">
                <h3 className="card-heading">Priority</h3>
              </Grid>
              <Grid container item xs={3} justify="flex-end">
                <h3 className="card-heading">Autocomplete</h3>
              </Grid>
            </Grid>
            {
              Object.entries(data).map(([key, value]) => (
                <SearchFieldSetting
                  key={key}
                  id={value.id}
                  value={value.weight}
                  autocomplete={value.autocomplete}
                  onChangeWeight={weightChangeHandler}
                  onChangeAutocomplete={autoCompleteChangeHandler}
                  title={value.title}
                />
              ))
            }
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SearchFields;
