import * as c from 'constants/actionTypes/billing';

const defaultState = {
  plans: {
    isFetching: true,
    isActivation: null,
    data: [],
    app: {
      status: 'ACTIVE',
      trialDaysLeft: 0,
    },
  },
  confirmation: {
    isFetching: true,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case c.GET_PLANS:
      return {
        ...state,
        plans: {
          ...state.plans,
          isFetching: true,
        },
      };

    case c.GET_PLANS_COMPLETE:
    case c.GET_PLANS_FAILED:
      return {
        ...state,
        plans: {
          isFetching: false,
          data: action.payload.plans,
          active: action.payload.active,
          usage: action.payload.usage,
          app: action.payload.app,
        },
      };

    case c.ACTIVATE_PLAN:
      return {
        ...state,
        plans: {
          ...state.plans,
          isActivation: action.payload,
        },
      };
    case c.ACTIVATE_PLAN_FAILED:
    case c.ACTIVATE_PLAN_COMPLETE:
      return {
        ...state,
        plans: {
          ...state.plans,
          isActivation: null,
        },
      };

    case c.CONFIRM_CHARGE:
      return {
        ...state,
        confirmation: {
          isFetching: true,
        },
      };
    case c.CONFIRM_CHARGE_COMPLETE:
    case c.CONFIRM_CHARGE_FAILED:
      return {
        ...state,
        confirmation: {
          isFetching: false,
          charge: action.payload.charge,
        },
      };


    default:
      return state;
  }
};
