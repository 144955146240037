import * as c from 'constants/actionTypes/search';

const defaultState = {
  fields: {
    isUpdating: false,
    isFetching: true,
    isFetched: false,
    data: {},
    attributes: {},
    forDiff: {},
    updated: {},
    changes: {},
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case c.GET_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          isFetching: true,
        },
      };

    case c.GET_FIELDS_COMPLETE:
      return {
        ...state,
        fields: {
          isFetching: false,
          data: action.payload.fields,
          forDiff: action.payload.fields,
          attributes: action.payload.attributes,
        },
      };

    case c.FIELDS_CHANGE_HANDLER:
      return {
        ...state,
        fields: {
          ...state.fields,
          data: {
            ...state.fields.data,
            [action.payload.field]: {
              ...state.fields.data[action.payload.field],
              [action.payload.key]: action.payload.value,
            },
          },
        },
      };

    case c.DISCARD_FIELDS_CHANGES:
      return {
        ...state,
        fields: {
          ...state.fields,
          data: state.fields.forDiff,
        },
      };

    case c.SAVE_FIELDS_CHANGES:
      return {
        ...state,
        fields: {
          ...state.fields,
          isUpdating: true,
        },
      };

    case c.SAVE_FIELDS_CHANGES_COMPLETED:
    case c.SAVE_FIELDS_CHANGES_FAILED:
      return {
        ...state,
        fields: {
          ...state.fields,
          isUpdating: false,
        },
      };

    default:
      return state;
  }
};
