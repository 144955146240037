import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CardContainer from 'components/kea/CardContainer';
import { Grid } from '@material-ui/core';
import { genRandomInteger } from 'util/appUtils';

const ProductThumbnail = ({ imageUrl }) => {
  const genStubId = genRandomInteger(0, 7);
  const fallback = require(`assets/images/dummy/product_stub_${genStubId}.png`);
  if (!imageUrl) imageUrl = fallback;

  const onError = (e) => {
    e.target.onerror = null;
    e.target.src = fallback;
  };
  return <img className="product-detail-image" src={imageUrl} alt="Product imageUrl" onError={onError} />;
};

const getProductParam = (product) => {
  const defaultStringParam = '---';
  const productParams = {};
  Object.entries(product)
    .forEach(([key, value]) => {
      if (key === 'tags') {
        value = value.join(', ');
      }
      if (key === 'price' || key === 'oldprice') {
        value = `${value} ${product.currencyId}`;
      }
      if (key === 'available') {
        value = value ? 'Yes' : 'No';
      }
      productParams[key] = value;
    });
  return (key) => {
    if (!productParams[key]) {
      return defaultStringParam;
    }
    return productParams[key];
  };
};

const ProductSection = ({ product: { data: product } }) => {
  const getParam = getProductParam(product);
  const imageSource = product.picture ? getParam('picture') : getParam('thumbnail');
  return (
    <Grid container>
      <Grid item md={12}>
        <ProductThumbnail imageUrl={imageSource} />
        <p>
          {getParam('name')}
          {' '}
          {getParam('id')}
        </p>
        <p>{`Vendor: ${getParam('vendor')}`}</p>
        <p>{`VendorCode: ${getParam('vendorCode')}`}</p>
        <p>{`Available: ${getParam('available')}`}</p>
        <p>{`Tags: ${getParam('tags')}`}</p>
        <p>{`Price: ${getParam('price')}`}</p>
        <p>{`Old price: ${getParam('oldprice')}`}</p>
      </Grid>
    </Grid>
  );
};

ProductSection.defaultProps = {
  product: {
    data: {},
  },
};

ProductSection.propTypes = {
  product: PropTypes.shape({
    data: PropTypes.object,
  }),
};

const ExternalLink = ({ title, product }) => {
  const { settings: { shopAddress } } = useSelector((str) => str);
  if (product && product.data) {
    const href = `https://${shopAddress}${product.data.url}`;
    return <a target="_blank" rel="noopener noreferrer" href={href}>{title}</a>;
  }
  return null;
};

const ProductDetail = ({ product }) => {
  const {
    isFetching,
    data,
  } = product;

  return (
    <CardContainer
      headerPostfix="Product details"
      actionComponent={<ExternalLink product={product} title="Open in shop" />}
      styleName="product-detail-container noMargin"
      isFetching={isFetching}
    >
      {
        data && <ProductSection product={product} />
      }
    </CardContainer>
  );
};


ProductDetail.propTypes = {
  product: PropTypes.shape({
    isFetched: PropTypes.bool,
    isFetching: PropTypes.bool,
    data: PropTypes.object,
  }).isRequired,
};


export default ProductDetail;
