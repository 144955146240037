/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { getComparator, stableSort } from '../utils';
import EnhancedTableHead from '../EnhancedTableHead';
import { genRandomKey } from '../../../../util/appUtils';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const defaultPaginationOptions = {
  rowsPerPageList: [25, 50, 100],
  initRowsPerPage: 25,
};

export default function AnalyticTable({
  headCells,
  rows,
  hidePagination,
  paginationOptions = defaultPaginationOptions,
  orderById = 'total',
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(orderById);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(paginationOptions.initRowsPerPage);

  const rowsCount = rows.length;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsCount - page * rowsPerPage);

  return (
    <div className={classes.root}>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={headCells}
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowsCount}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const isItemSelected = isSelected(row.name);
                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={(row.id || row.query) + genRandomKey()}
                    selected={isItemSelected}
                  >
                    {
                      headCells.map((e) => (
                        <TableCell
                          key={e.id + genRandomKey()}
                          align={e.numeric ? 'right' : 'left'}
                          component="th"
                        >
                          {row[e.id]}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
            <TableRow style={{ height: 33 * emptyRows }}>
              <TableCell colSpan={rowsCount} />
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        hidePagination
          ? null
          : (
            <TablePagination
              rowsPerPageOptions={paginationOptions.rowsPerPageList}
              component="div"
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )

      }
    </div>
  );
}


AnalyticTable.defaultProps = {
  hidePagination: false,
  orderById: 'total',
};
AnalyticTable.propTypes = {
  orderById: PropTypes.any,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  hidePagination: PropTypes.bool,
};
