/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardContainer from 'components/kea/CardContainer';
import { getAnalyticsWidgetsPerformance } from 'actions';
import { genRandomKey } from 'util/appUtils';
import PagePerformanceBlock from './PagePerformanceBlock';

const MainSection = () => {
  const dispatch = useDispatch();
  const { analytics: { recs, timeframe }, app: { currentShop } } = useSelector((store) => store);
  const { widgetsPerformance: { isFetching, pages } } = recs;

  useEffect(() => {
    dispatch(getAnalyticsWidgetsPerformance(currentShop, timeframe));
  }, [timeframe]);

  return (
    <>
      {
          pages && pages
            .map((page) => {
              const widgetsCount = page.widgets.length;
              const error = (widgetsCount === 0) ? 'NO_DATA' : false;
              return (
                <CardContainer
                  key={genRandomKey()}
                  error={error}
                  headerPostfix={page.data && page.data.title}
                  styleName="widgets-performance"
                  isFetching={isFetching}
                  height={150}
                >
                  <div className="pages-container">
                    <PagePerformanceBlock key={page.data.id} page={page} />
                  </div>
                </CardContainer>
              );
            })
      }
    </>
  );
};

export default MainSection;
