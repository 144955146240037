import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  onboardingButton: {
    width: '100%',
    padding: '20px 30px',
    color: 'white',
    fontSize: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
}));

export default function OnboardingButton(props) {
  const styles = useStyles();
  const { color } = props;
  return <Button className={styles.onboardingButton} {...props} color={undefined} style={{ background: color }} />;
}

OnboardingButton.propTypes = {
  color: PropTypes.string,
};

OnboardingButton.defaultProps = {
  color: undefined,
};
