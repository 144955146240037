import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SafetyRoute from 'components/kea/SafetyRoute';
import asyncComponent from 'util/asyncComponent';
import RecsFields from './fields';
import Page from './widgets';
import Widget from './widgets/pageEditor/widget';
import PageEditor from './widgets/pageEditor';

export default ({ match }) => (
  <Switch>
    <SafetyRoute exact path={`${match.url}/fields`} component={(props) => <RecsFields {...props} />} />
    <SafetyRoute exact path={`${match.url}/widgets`} component={(props) => <Page {...props} />} />
    <SafetyRoute exact path={`${match.url}/widgets/:pageId`} component={(props) => <PageEditor {...props} />} />
    <SafetyRoute exact path={`${match.url}/widgets/:pageId/:widgetId`} component={(props) => <Widget {...props} />} />

    <Route component={asyncComponent(() => import('app/routes/404'))} />
  </Switch>
);
