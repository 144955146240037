/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'qs';
import { rewriteAlertBanners, submitNewPassword } from '../../actions';

const SubmitNewPassword = ({ location }) => {
  const dispatch = useDispatch();
  const { settings, auth } = useSelector((store) => store);
  const { loader, alertMessage, showMessage } = auth;
  const { alerts } = settings;
  const { actions } = alerts;

  const parseOptions = { ignoreQueryPrefix: true };
  const queryString = qs.parse(location.search, parseOptions);
  const { token } = queryString;

  const newPasswordRef = useRef(null);
  const repeatNewPasswordRef = useRef(null);

  const submitHandler = () => {
    const newPassword = newPasswordRef.current.value;
    const repeatNewPassword = repeatNewPasswordRef.current.value;

    // todo: better validation
    if (!token) {
      dispatch(rewriteAlertBanners([actions.PASSWORD_SUBMIT_TOKEN_NOT_FOUND]));
      return;
    }

    if (newPassword !== repeatNewPassword) {
      dispatch(rewriteAlertBanners([actions.PASSWORDS_NOT_MATCHED]));
      return;
    }

    if (newPassword.length < 8) {
      dispatch(rewriteAlertBanners([actions.PASSWORD_TOO_SHORT]));
      return;
    }

    dispatch(submitNewPassword({
      password: newPasswordRef.current.value,
      token,
    }));
  };

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
    >
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <img src={require('assets/images/kealogo.svg')} alt="kea_logo" />
        </div>
        <div className="app-login-content">
          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.enterNewPassword" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  inputRef={newPasswordRef}
                  disabled={loader}
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.repeatNewPassword" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  inputRef={repeatNewPasswordRef}
                  disabled={loader}
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    onClick={submitHandler}
                    variant="contained"
                    color="primary"
                    disabled={loader}
                  >
                    <IntlMessages id="appModule.updatePassword" />
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {
        loader
        && (
        <div className="loader-view">
          <CircularProgress />
        </div>
        )
      }
      {showMessage && NotificationManager.error(alertMessage)}
      <NotificationContainer />
    </div>
  );
};


export default SubmitNewPassword;
