import React from 'react';
import Widget from 'components/Widget/index';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import WidgetRainbow from 'components/kea/widgets/common/WidgetRainbow';
import { chartColors } from 'util/chartUtils';

const StatsWidget = ({ stats }) => {
  if (!stats) {
    return (
      <Widget styleName="jr-card-full noMargin">
        <PlaceholderSpinner height={129} />
      </Widget>
    );
  }
  const totalPurchases = stats && stats.userPurchase;
  const totalViews = stats && stats.totalViews;
  const conversion = (stats && stats.conversion) ? `${stats.conversion}%` : '-';

  const pieData = [
    { id: '1', name: 'Number of purchases', fill: chartColors.main.normal, value: totalPurchases },
    { id: '2', name: 'Number of views', fill: chartColors.different.normal, value: totalViews - totalPurchases },
  ];

  return (
    <WidgetRainbow
      pieData={pieData}
      title="Product stats"
      mainNumber={conversion}
      description="conversion"
    />
  );
};

export default StatsWidget;
