import withStyles from '@material-ui/core/styles/withStyles';
import { Slider } from '@material-ui/core';

const thumbSize = 50;
const railSize = 6;


export default withStyles({
  root: {
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: thumbSize,
    width: thumbSize,
    backgroundColor: '#fff',
    marginTop: -thumbSize / 2,
    marginLeft: -thumbSize / 2,
  },
  track: {
    height: railSize,
  },
  rail: {
    height: railSize - 2,
    opacity: 0.5,
  },
  mark: {
    height: 0,
    width: 0,
  },
  markLabel: {
    top: thumbSize,
    fontSize: thumbSize / 2,
  },
  markLabelActive: {
    top: thumbSize,
    fontSize: thumbSize / 2,
  },
})(Slider);
