export const GET_A_B_RESULTS = 'analytics/GET_A_B_RESULTS';
export const GET_A_B_RESULTS_COMPLETE = 'analytics/GET_A_B_RESULTS_COMPLETE';
export const GET_A_B_RESULTS_FAILED = 'analytics/GET_A_B_RESULTS_FAILED';

export const GET_A_B_CARTS = 'analytics/GET_A_B_CARTS';
export const GET_A_B_CARTS_COMPLETE = 'analytics/GET_A_B_CARTS_COMPLETE';
export const GET_A_B_CARTS_FAILED = 'analytics/GET_A_B_CARTS_FAILED';

export const GET_FAKE_ANALYTICS = 'analytics/GET_FAKE_ANALYTICS';
export const CHANGE_TIMEFRAME_RANGE = 'analytics/CHANGE_TIMEFRAME_RANGE';

export const GET_SEARCH_DASHBOARD = 'analytics/GET_SEARCH_DASHBOARD';
export const GET_SEARCH_DASHBOARD_COMPLETE = 'analytics/GET_SEARCH_DASHBOARD_COMPLETE';
export const GET_SEARCH_DASHBOARD_FAILED = 'analytics/GET_SEARCH_DASHBOARD_FAILED';

export const GET_RECS_DASHBOARD = 'analytics/GET_RECS_DASHBOARD';
export const GET_RECS_DASHBOARD_COMPLETE = 'analytics/GET_RECS_DASHBOARD_COMPLETE';
export const GET_RECS_DASHBOARD_FAILED = 'analytics/GET_RECS_DASHBOARD_FAILED';


export const GET_SEARCH_STATS = 'analytics/GET_SEARCH_STATS';
export const GET_SEARCH_STATS_COMPLETE = 'analytics/GET_SEARCH_STATS_COMPLETE';
export const GET_SEARCH_STATS_FAILED = 'analytics/GET_SEARCH_STATS_FAILED';

export const GET_SEARCH_FILTER_POPULAR = 'analytics/GET_SEARCH_FILTER_POPULAR';
export const GET_SEARCH_FILTER_POPULAR_COMPLETE = 'analytics/GET_SEARCH_FILTER_POPULAR_COMPLETE';
export const GET_SEARCH_FILTER_POPULAR_FAILED = 'analytics/GET_SEARCH_FILTER_POPULAR_FAILED';

export const GET_SEARCH_LOST_OPPORTUNITIES = 'analytics/GET_SEARCH_LOST_OPPORTUNITIES';
export const GET_SEARCH_LOST_OPPORTUNITIES_COMPLETE = 'analytics/GET_SEARCH_LOST_OPPORTUNITIES_COMPLETE';
export const GET_SEARCH_LOST_OPPORTUNITIES_FAILED = 'analytics/GET_SEARCH_LOST_OPPORTUNITIES_FAILED';

export const GET_SEARCH_CURVE = 'analytics/GET_SEARCH_CURVE';
export const GET_SEARCH_CURVE_COMPLETE = 'analytics/GET_SEARCH_CURVE_COMPLETE';
export const GET_SEARCH_CURVE_FAILED = 'analytics/GET_SEARCH_CURVE_FAILED';

export const GET_SEARCH_QUERY_POPULAR = 'analytics/GET_SEARCH_QUERY_POPULAR';
export const GET_SEARCH_QUERY_POPULAR_COMPLETE = 'analytics/GET_SEARCH_QUERY_POPULAR_COMPLETE';
export const GET_SEARCH_QUERY_POPULAR_FAILED = 'analytics/GET_SEARCH_QUERY_POPULAR_FAILED';

export const GET_SEARCH_QUERY_SORTED = 'analytics/GET_SEARCH_QUERY_SORTED';
export const GET_SEARCH_QUERY_SORTED_COMPLETE = 'analytics/GET_SEARCH_QUERY_SORTED_COMPLETE';
export const GET_SEARCH_QUERY_SORTED_FAILED = 'analytics/GET_SEARCH_QUERY_SORTED_FAILED';

export const GET_SEARCH_QUERY_NO_RESULT = 'analytics/GET_SEARCH_QUERY_NO_RESULT';
export const GET_SEARCH_QUERY_NO_RESULT_COMPLETE = 'analytics/GET_SEARCH_QUERY_NO_RESULT_COMPLETE';
export const GET_SEARCH_QUERY_NO_RESULT_FAILED = 'analytics/GET_SEARCH_QUERY_NO_RESULT_FAILED';

export const GET_SEARCH_RELATED_QUERIES = 'analytics/GET_SEARCH_RELATED_QUERIES';
export const GET_SEARCH_RELATED_QUERIES_COMPLETE = 'analytics/GET_SEARCH_RELATED_QUERIES_COMPLETE';
export const GET_SEARCH_RELATED_QUERIES_FAILED = 'analytics/GET_SEARCH_RELATED_QUERIES_FAILED';


export const GET_PRODUCTS_CONVERSION_BEST = 'analytics/GET_PRODUCTS_CONVERSION_BEST';
export const GET_PRODUCTS_CONVERSION_BEST_COMPLETE = 'analytics/GET_PRODUCTS_CONVERSION_BEST_COMPLETE';
export const GET_PRODUCTS_CONVERSION_BEST_FAILED = 'analytics/GET_PRODUCTS_CONVERSION_BEST_FAILED';

export const GET_PRODUCTS_CONVERSION_WORST = 'analytics/GET_PRODUCTS_CONVERSION_WORST';
export const GET_PRODUCTS_CONVERSION_WORST_COMPLETE = 'analytics/GET_PRODUCTS_CONVERSION_WORST_COMPLETE';
export const GET_PRODUCTS_CONVERSION_WORST_FAILED = 'analytics/GET_PRODUCTS_CONVERSION_WORST_FAILED';

export const GET_PRODUCTS_POPULAR_BY_VIEW = 'analytics/GET_PRODUCTS_POPULAR_BY_VIEW';
export const GET_PRODUCTS_POPULAR_BY_VIEW_COMPLETE = 'analytics/GET_PRODUCTS_POPULAR_BY_VIEW_COMPLETE';
export const GET_PRODUCTS_POPULAR_BY_VIEW_FAILED = 'analytics/GET_PRODUCTS_POPULAR_BY_VIEW_FAILED';

export const GET_PRODUCTS_POPULAR_BY_PURCHASE = 'analytics/GET_PRODUCTS_POPULAR_BY_PURCHASE';
export const GET_PRODUCTS_POPULAR_BY_PURCHASE_COMPLETE = 'analytics/GET_PRODUCTS_POPULAR_BY_PURCHASE_COMPLETE';
export const GET_PRODUCTS_POPULAR_BY_PURCHASE_FAILED = 'analytics/GET_PRODUCTS_POPULAR_BY_PURCHASE_FAILED';

export const GET_PRODUCTS_RELATED_BY_VIEW = 'analytics/GET_PRODUCTS_RELATED_BY_VIEW';
export const GET_PRODUCTS_RELATED_BY_VIEW_COMPLETE = 'analytics/GET_PRODUCTS_RELATED_BY_VIEW_COMPLETE';
export const GET_PRODUCTS_RELATED_BY_VIEW_FAILED = 'analytics/GET_PRODUCTS_RELATED_BY_VIEW_FAILED';

export const GET_PRODUCTS_RELATED_BY_PURCHASE = 'analytics/GET_PRODUCTS_RELATED_BY_PURCHASE';
export const GET_PRODUCTS_RELATED_BY_PURCHASE_COMPLETE = 'analytics/GET_PRODUCTS_RELATED_BY_PURCHASE_COMPLETE';
export const GET_PRODUCTS_RELATED_BY_PURCHASE_FAILED = 'analytics/GET_PRODUCTS_RELATED_BY_PURCHASE_FAILED';

export const GET_RELATED_QUERIES_BY_PRODUCT = 'analytics/GET_RELATED_QUERIES_BY_PRODUCT';
export const GET_RELATED_QUERIES_BY_PRODUCT_COMPLETE = 'analytics/GET_RELATED_QUERIES_BY_PRODUCT_COMPLETE';
export const GET_RELATED_QUERIES_BY_PRODUCT_FAILED = 'analytics/GET_RELATED_QUERIES_BY_PRODUCT_FAILED';

export const GET_RELATED_PRODUCTS_BY_QUERY = 'analytics/GET_RELATED_PRODUCTS_BY_QUERY';
export const GET_RELATED_PRODUCTS_BY_QUERY_COMPLETE = 'analytics/GET_RELATED_PRODUCTS_BY_QUERY_COMPLETE';
export const GET_RELATED_PRODUCTS_BY_QUERY_FAILED = 'analytics/GET_RELATED_PRODUCTS_BY_QUERY_FAILED';

export const GET_RECS_WIDGETS_PERFORMANCE = 'analytics/GET_RECS_WIDGETS_PERFORMANCE';
export const GET_RECS_WIDGETS_PERFORMANCE_COMPLETE = 'analytics/GET_RECS_WIDGETS_PERFORMANCE_COMPLETE';
export const GET_RECS_WIDGETS_PERFORMANCE_FAILED = 'analytics/GET_RECS_WIDGETS_PERFORMANCE_FAILED';
