import * as c from 'constants/actionTypes/settings';
import {
  updatePreview,
} from '../actions';

const defaultState = {
  isFetched: false,
  hexRegEx: /^#([A-Fa-f0-9]{6})$/,

  isFetching: false,
  isUpdatingSearch: false,
  isUpdatingRecs: false,

  describes: {
    isFetching: true,
  },

  searchVisible: { isFetching: false, value: true },
  recsVisible: { isFetching: false, value: true },

  alerts: {
    isFetching: true,
    data: [],
    actions: {
      COMPILE_BUNDLE: { id: 'COMPILE_BUNDLE', title: 'Note:', status: 'info', message: 'Success! Your changes will take effect in a few minutes!' },
      REBUILD_INDEX: { id: 'REBUILD_INDEX', title: 'Note:', status: 'info', message: 'Success! Your changes will take effect in a few minutes!' },
      SING_IN_FAILED: { id: 'SING_IN_FAILED', title: 'Error!', status: 'warning', message: 'Warning! Your credentials are wrong! Try to login by user password or contact us!' },
      PASSWORD_RESET_FAILED: { id: 'PASSWORD_RESET_FAILED', title: 'Error!', status: 'warning', message: 'Password reset failed. Please contact us at support@kealabs.com' },
      PASSWORD_RESET: { id: 'RESET_PASSWORD', title: 'Note!', status: 'info', message: 'We send a link to change your password. Check your email' },
      PASSWORDS_NOT_MATCHED: { id: 'PASSWORDS_NOT_MATCHED', title: 'Note!', status: 'warning', message: 'Passwords not matched' },
      PASSWORD_TOO_SHORT: { id: 'PASSWORD_TOO_SHORT', title: 'Note!', status: 'warning', message: 'Password too short' },
      PASSWORD_UPDATED_SUCCESSFULLY: { id: 'PASSWORD_UPDATED_SUCCESSFULLY', title: 'Note!', status: 'info', message: 'Password successfully updated' },
      PASSWORD_SUBMIT_TOKEN_NOT_FOUND: { id: 'PASSWORD_SUBMIT_TOKEN_NOT_FOUND', title: 'Note!', status: 'info', message: 'Password token is unvalid' },
    },
  },
  forDiff: { searchVisible: true, recsVisible: true },
  recs: {},
  search: {},
};

export default (state = defaultState, { type, payload }) => {
  const stateCopy = { ...state };
  switch (type) {
    case c.GET_ALL_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };

    case c.GET_ALL_SETTINGS_COMPLETE:
      return {
        ...state,
        ...payload,
        isFetching: false,
        isFetched: true,
        searchVisible: {
          ...state.searchVisible,
          value: payload.searchVisible,
        },
        recsVisible: {
          ...state.recsVisible,
          value: payload.recsVisible,
        },
      };

    case c.UPDATE_RECS_VISIBLE:
      stateCopy.recsVisible.value = payload;
      return stateCopy;

    case c.UPDATE_SEARCH_VISIBLE:
      stateCopy.searchVisible.value = payload;
      return stateCopy;

    case updatePreview().type:
      stateCopy.isPreview = payload;
      return stateCopy;

    case c.GET_DESCRIBES_COMPLETE:
      return {
        ...state,
        describes: {
          isFetching: false,
          ...payload,
        },
      };

      /**
       * Recs Settings
       */
    case c.CHANGE_RECS_THEME_SETTING:
      return {
        ...state,
        recs: {
          ...state.recs,
          theme: {
            ...state.recs.theme,
            [payload.key]: payload.value,
          },
        },
      };

    case c.CHANGE_RECS_ONBOARDING:
      return {
        ...state,
        recs: {
          ...state.recs,
          onboarding: {
            ...state.recs.onboarding,
            ...payload,
          },
        },
      };

    /**
       * Search Settings
       */
    case c.CHANGE_SEARCH_ONBOARDING:
      return {
        ...state,
        search: {
          ...state.search,
          onboarding: {
            ...state.search.onboarding,
            ...payload,
          },
        },
      };

    case c.CHANGE_SEARCH_GENERAL_SETTING:
      return {
        ...state,
        search: {
          ...state.search,
          general: {
            ...state.search.general,
            [payload.key]: payload.value,
          },
        },
      };

    case c.CHANGE_SEARCH_BOX_SETTING:
      return {
        ...state,
        search: {
          ...state.search,
          searchBox: {
            ...state.search.searchBox,
            [payload.key]: payload.value,
          },
        },
      };

    case c.CHANGE_SEARCH_RESULT_SETTING:
      return {
        ...state,
        search: {
          ...state.search,
          searchResultPage: {
            ...state.search.searchResultPage,
            [payload.key]: payload.value,
          },
        },
      };

    case c.CHANGE_SEARCH_FACETS_SETTING:
      return {
        ...state,
        search: {
          ...state.search,
          facets: {
            ...state.search.facets,
            [payload.key]: payload.value,
          },
        },
      };

    case c.CHANGE_SEARCH_THEME_SETTING:
      return {
        ...state,
        search: {
          ...state.search,
          theme: {
            ...state.search.theme,
            [payload.key]: payload.value,
          },
        },
      };

      /**
       * Reset settings
       */

    case c.RESET_SEARCH_SETTINGS_TO_DEFAULT:
      return {
        ...state,
        search: state.forDiff.search,
        searchVisible: {
          ...state.searchVisible,
          value: state.forDiff.searchVisible,
        },
      };

    case c.RESET_RECS_SETTINGS_TO_DEFAULT:
      return {
        ...state,
        recs: state.forDiff.recs,
        recsVisible: {
          ...state.recsVisible,
          value: state.forDiff.recsVisible,
        },
      };

    case c.RESET_SETTINGS_TO_DEFAULT:
      return {
        ...state,
        recs: {
          ...state.recs,
          theme: state.forDiff.recs.theme,
        },
        fields: {
          ...state.fields,
          data: state.fields.examplar,
        },
        recsVisible: {
          ...state.recsVisible,
          value: state.forDiff.recsVisible,
        },
        searchVisible: {
          ...state.searchVisible,
          value: state.forDiff.searchVisible,
        },
      };


      /**
       * Save settings flags
       */

    case c.SAVE_RECS_SETTINGS:
      return {
        ...state,
        isUpdatingRecs: true,
      };

    case c.SAVE_RECS_SETTINGS_COMPLETED:
    case c.SAVE_RECS_SETTINGS_FAILED:
      return {
        ...state,
        isUpdatingRecs: false,
      };

    case c.SAVE_SEARCH_SETTINGS:
      return {
        ...state,
        isUpdatingSearch: true,
      };

    case c.SAVE_SEARCH_SETTINGS_COMPLETED:
    case c.SAVE_SEARCH_SETTINGS_FAILED:
      return {
        ...state,
        isUpdatingSearch: false,
      };

      /**
       * Alert banners
       */
    case c.REWRITE_ALERT_BANNERS:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isFetching: false,
          data: payload,
        },
      };

    default:
      return state;
  }
};
