import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';

const Carts = ({ withProducts }) => {
  const options = {
    textYIndex: 2,
    legend: {
      display: false,
      position: 'bottom',
    },
  };

  if (!withProducts || withProducts.error) {
    return (
      <CardBox
        header="Carts"
      >
        <DashboardStub />
      </CardBox>
    );
  }

  const { main, gray } = chartColors;
  const data = {
    labels: ['Other ways of product discovery', 'Discovered from search'],
    datasets: [{
      data: [
        withProducts.cartsNoSearch,
        withProducts.cartsSearch,
      ],
      backgroundColor: [
        gray.normal,
        main.normal,
      ],
      hoverBackgroundColor: [
        gray.dark,
        main.dark,
      ],
    }],
    text: `${withProducts.cartsSearchPercentage}%`,
  };


  return (
    <CardBox
      header="Carts"
      subheader=" *NOTE: in every store most of carts get abandoned."
      subsubheader={'This chart doesn\'t analyse whether the purchase was completed or not'}
    >

      <div className="row">
        <div className="col-12 dashboard-text-block">
          <p>{`${withProducts.cartsSearchPercentage}% of products in carts`}</p>
          <br />
          have been viewed for the first time after the search.
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 donutSegment">
          <Doughnut
            height={230}
            data={data}
            options={options}
          />
        </div>

        <div className="col-md-6 dashboard-text-block">
          <table className="chartTable">
            <tbody>
              <tr>
                <td>
                  <p>Total number of products in all carts</p>
                </td>
                <td>
                  <p className="text-right">{withProducts.cartsTotal}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Number of products which have been discovered for the first time from the search</p>
                </td>
                <td>
                  <p className="text-right">{withProducts.cartsSearch}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CardBox>
  );
};

Carts.propTypes = {
  withProducts: PropTypes.shape({
    cartsNoSearch: PropTypes.any,
    cartsSearch: PropTypes.any,
    cartsSearchPercentage: PropTypes.any,
    cartsTotal: PropTypes.any,

    error: PropTypes.any,
  }).isRequired,
};


export default Carts;
