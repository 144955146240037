import * as c from 'constants/actionTypes/recs';

const defaultState = {
  widget: {
    data: [],
    isFetched: false,
    isFetching: false,
    isDeleting: false,
    isUpdating: false,
  },
  model: {
    data: [],
    isFetched: false,
    isFetching: false,
  },
  page: {
    data: [],
    isFetched: false,
    isFetching: false,
  },
  fields: {
    isUpdating: false,
    isFetching: true,
    isFetched: false,
    data: {},
    attributes: {},
    updated: {},
    changes: {},

    forDiff: {},
  },
  algorithm: {
    SIMILARITY: 'Similar Items',
    RELATED_ITEMS_VIEW: 'Related Items',
    RELATED_ITEMS_PURCHASE: 'Bought together',
    AFTER_VIEW_BOUGHT: 'Customers buy after viewing',
    SAME_COLLECTION: 'Same Collection',
    POPULAR_PRODUCTS_FROM_RELATED_CATEGORIES: 'Popular Items from Related Categories',
    POPULAR_PRODUCTS_IN_CATEGORY: 'Popular Items in Category',
    POPULAR_PRODUCTS_IN_SHOP: 'Popular Items',
    RELATED_PRODUCTS_FOR_CART: 'Related Items for Cart',
    YOU_MAY_LIKE: 'Personalised Recommendations',
    BROWSING_HISTORY: 'Browsing History',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case c.GET_FIELDS_COMPLETE:
      return {
        ...state,
        fields: {
          isFetching: false,
          data: action.payload.fields,
          attributes: action.payload.attributes,
          flat: action.payload.flat,

          forDiff: action.payload.fields,
        },
      };

    case c.FIELDS_CHANGE_HANDLER:
      return {
        ...state,
        fields: {
          ...state.fields,
          data: {
            ...state.fields.data,
            [action.payload.field]: {
              ...state.fields.data[action.payload.field],
              [action.payload.key]: action.payload.value,
            },
          },
        },
      };

    case c.DISCARD_FIELDS_CHANGES:
      return {
        ...state,
        fields: {
          ...state.fields,
          data: state.fields.forDiff,
        },
      };

    case c.SAVE_FIELDS_CHANGES:
      return {
        ...state,
        fields: {
          ...state.fields,
          isUpdating: true,
        },
      };

    case c.SAVE_FIELDS_CHANGES_COMPLETED:
    case c.SAVE_FIELDS_CHANGES_FAILED:
      return {
        ...state,
        fields: {
          ...state.fields,
          isUpdating: false,
        },
      };

    case c.RECS_LOAD_ALL_WIDGETS:
      return {
        ...state,
        widget: {
          ...state.widget,
          isFetching: true,
        },
      };

    case c.RECS_LOAD_ALL_WIDGETS_COMPLETE:
      return {
        ...state,
        widget: {
          ...state.widget,
          data: action.payload.data,
          dataMap: action.payload.dataMap,
          isFetched: true,
          isFetching: false,
        },
      };

    case c.RECS_CREATE_WIDGET:
    case c.RECS_UPDATE_ONE_WIDGET:
      return {
        ...state,
        widget: {
          ...state.widget,
          isUpdating: true,
        },
      };

    case c.RECS_DELETE_WIDGET:
      return {
        ...state,
        widget: {
          ...state.widget,
          isDeleting: true,
        },
      };

    case c.RECS_UPDATE_ONE_WIDGET_COMPLETE:
    case c.RECS_UPDATE_ONE_WIDGET_FAILED:
    case c.RECS_CREATE_WIDGET_COMPLETE:
    case c.RECS_CREATE_WIDGET_FAILED:
    case c.RECS_UPDATE_MANY_WIDGETS_COMPLETE:
    case c.RECS_UPDATE_MANY_WIDGETS_FAILED:
      return {
        ...state,
        widget: {
          ...state.widget,
          isUpdating: false,
        },
      };

    case c.RECS_LOAD_ALL_MODELS_COMPLETE:
      return {
        ...state,
        model: {
          data: action.payload.data,
          dataForSelect: action.payload.dataForSelect,
          isFetched: true,
          isFetching: false,
        },
      };

    case c.RECS_LOAD_ALL_PAGES_COMPLETE:
      return {
        ...state,
        page: {
          data: action.payload.data,
          dataMap: action.payload.dataMap,
          dataMapDefault: action.payload.dataMapDefault,
          isFetched: true,
          isFetching: false,
          isUpdating: false,
        },
      };

    case c.RECS_CUSTOMIZE_SECTIONS_IN_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          dataMap: {
            ...state.page.dataMap,
            [action.payload.pageId]: {
              ...state.page.dataMap[action.payload.pageId],
              sections: action.payload.sections,
            },
          },
        },
      };


    case c.RECS_UPDATE_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          isUpdating: true,
        },
      };

    case c.RECS_UPDATE_PAGE_COMPLETE:
    case c.RECS_UPDATE_PAGE_FAILED:
      return {
        ...state,
        page: {
          ...state.page,
          isUpdating: false,
        },
      };

    default:
      return state;
  }
};
