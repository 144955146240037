import React, { useState } from 'react';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import CardContainer from 'components/kea/CardContainer';
import { Line } from 'react-chartjs-2';
import { groupCurveByDays, theCurveIsMostlyFull } from 'util/appUtils';
import { chartGradients } from 'util/chartUtils';

const TrendingWidget = ({ chartData }) => {
  const [groupBy] = useState(false);

  const options = {
    maintainAspectRatio: false,
    title: { display: false },
    tooltips: { mode: 'index', intersect: false },
    fill: true,
    responsive: true,
    elements: {
      // line:{
      //   tension: 0
      // },
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }],
    },
  };
  const dateAlias = 'label';
  const wantedFields = ['count'];

  function autoGroupCurve(arr) {
    let result = arr;
    if (arr.length > 35) {
      const granularity = arr.length > 175 ? 30 : 7;
      const ignoreGrouping = (arr.length < 45) && theCurveIsMostlyFull(arr, 'count', 0.5);
      if (!ignoreGrouping) {
        result = groupCurveByDays(arr, granularity, dateAlias, wantedFields);
      }
    }
    return result;
  }

  const renderData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 800, 0);
    gradient.addColorStop(0.1, chartGradients.gradient3.color1);
    gradient.addColorStop(0.95, chartGradients.gradient3.color2);
    let renderData = chartData;
    if (groupBy) {
      renderData = groupCurveByDays(chartData, groupBy, dateAlias, wantedFields);
    } else {
      renderData = autoGroupCurve(chartData, groupBy);
    }

    function getData(name) {
      return renderData.map((e) => e[name]);
    }

    const curve = getData('count');
    const total = curve.reduce((obj, e) => obj + e, 0);
    return {
      labels: getData(dateAlias),
      datasets: [{
        backgroundColor: gradient,
        borderWidth: 0,
        data: curve,
        label: 'Number of views',
        fill: (total > 0) ? 'start' : false,
      }],
    };
  };

  return (
    <CardContainer headerPostfix="Views trending">
      <div style={{ height: '190px' }}>
        {
          chartData
            ? <Line data={renderData} options={options} />
            : <PlaceholderSpinner height={190} />
        }
      </div>
    </CardContainer>
  );
};

export default TrendingWidget;
