/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainer from 'components/kea/CardContainer';
import { getRelatedProductsByPurchase } from 'actions';
import { linkToProductDetail } from 'util/appUtils';

const RelatedProductsByPurchase = ({ productId }) => {
  const dispatch = useDispatch();
  const { analytics: { products, timeframe }, app: { currentShop } } = useSelector((str) => str);
  const { relatedByPurchase: { isFetching, data, error } } = products;
  useEffect(() => {
    dispatch(getRelatedProductsByPurchase(currentShop, timeframe, productId));
  }, [timeframe, productId]);

  const headCells = [
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'count', numeric: true, label: 'count' },
  ];

  const prepareData = (rows) => rows.map((e) => ({
    ...e,
    name: linkToProductDetail(currentShop, e.name, e.id),
  }));


  return (
    <CardContainer
      headerId="search.analytics.relatedProductsByPurchase.header"
      styleName="min-height-700px noMargin"
      isFetching={isFetching}
      error={error}
    >
      <AnalyticTable
        rows={prepareData(data)}
        headCells={headCells}
      />
    </CardContainer>
  );
};

export default RelatedProductsByPurchase;
