import { createTheme } from '@material-ui/core/styles';
import {
  AMBER,
  BLUE,
  CYAN, DARK_AMBER, DARK_BLUE,
  DARK_CYAN, DARK_DEEP_ORANGE, DARK_DEEP_PURPLE, DARK_GREEN,
  DARK_INDIGO, DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from '../constants/ThemeColors';
import indigoTheme from '../containers/themes/indigoTheme';
import cyanTheme from '../containers/themes/cyanTheme';
import amberTheme from '../containers/themes/amberTheme';
import orangeTheme from '../containers/themes/orangeTheme';
import pinkTheme from '../containers/themes/pinkTheme';
import blueTheme from '../containers/themes/blueTheme';
import purpleTheme from '../containers/themes/purpleTheme';
import greenTheme from '../containers/themes/greenTheme';

export default (themeColor, applyTheme) => {
  switch (themeColor) {
    case INDIGO: {
      applyTheme = createTheme(indigoTheme);
      break;
    }
    case CYAN: {
      applyTheme = createTheme(cyanTheme);
      break;
    }
    case AMBER: {
      applyTheme = createTheme(amberTheme);
      break;
    }
    case DEEP_ORANGE: {
      applyTheme = createTheme(orangeTheme);
      break;
    }
    case PINK: {
      applyTheme = createTheme(pinkTheme);
      break;
    }
    case BLUE: {
      applyTheme = createTheme(blueTheme);
      break;
    }
    case DEEP_PURPLE: {
      applyTheme = createTheme(purpleTheme);
      break;
    }
    case GREEN: {
      applyTheme = createTheme(greenTheme);
      break;
    }
    case DARK_INDIGO: {
      applyTheme = createTheme({ ...indigoTheme, direction: 'rtl' });
      break;
    }
    case DARK_CYAN: {
      applyTheme = createTheme(cyanTheme);
      break;
    }
    case DARK_AMBER: {
      applyTheme = createTheme(amberTheme);
      break;
    }
    case DARK_DEEP_ORANGE: {
      applyTheme = createTheme(orangeTheme);
      break;
    }
    case DARK_PINK: {
      applyTheme = createTheme(pinkTheme);
      break;
    }
    case DARK_BLUE: {
      applyTheme = createTheme(blueTheme);
      break;
    }
    case DARK_DEEP_PURPLE: {
      applyTheme = createTheme(purpleTheme);
      break;
    }
    case DARK_GREEN: {
      applyTheme = createTheme(greenTheme);
      break;
    }
    default: createTheme(greenTheme);
  }
  return applyTheme;
};
