import React from 'react';
import Grid from '@material-ui/core/Grid';
import classes from './TextCenter.module.scss';

export default function TextCenter({ children }) {
  return (
    <Grid item className={classes.center}>
      {children}
    </Grid>
  );
}
