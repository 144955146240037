import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';

function Conversion({ conversion }) {
  const chartOptions = {
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [{
        stacked: false,
      }],
      yAxes: [{
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  };

  function prepareData(data) {
    const { main, different } = chartColors;
    return {
      labels: ['conversion'],
      datasets: [
        {
          label: 'Didn\'t use recommendations',
          backgroundColor: different.normal,
          data: [data.noRecs.rate],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        }, {
          label: 'Used recommendations',
          backgroundColor: main.normal,
          data: [data.recs.rate],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        },
      ],
    };
  }

  if (conversion.error) {
    return (
      <CardBox header="Conversion">
        <DashboardStub />
      </CardBox>
    );
  }
  const chartData = prepareData(conversion);


  return (
    <CardBox header="Conversion">
      <div className="row">
        <div className="col-6">
          <Bar data={chartData} options={chartOptions} height={240} />
        </div>
        <div className="col-6">
          <p>
            Conversion of visitors who used recommendations is
            <br/>{conversion.ratio} times higher than<br/>
            conversion of other interested visitors.
            <br />
          </p>
        </div>
      </div>

    </CardBox>
  );
}

Conversion.propTypes = {
  conversion: PropTypes.shape({
    recs: PropTypes.shape({ rate: PropTypes.any }),
    noRecs: PropTypes.shape({ rate: PropTypes.any }),
    ratio: PropTypes.any,
    error: PropTypes.any,
  }).isRequired,
};

export default Conversion;
