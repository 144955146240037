import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';

function AverageOrder({ aov }) {
  const chartOptions = {
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [{
        stacked: false,
      }],
      yAxes: [{
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  };

  function prepareData(data) {
    const { main, different } = chartColors;
    return {
      labels: ['aov'],
      datasets: [
        {
          label: 'Didn\'t use recommendations',
          backgroundColor: different.normal,
          data: [data.noRecs],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        }, {
          label: 'Used recommendations',
          backgroundColor: main.normal,
          data: [data.recs],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        },
      ],
    };
  }

  if (aov.error) {
    return (
      <CardBox header="Average Order Value (AOV)">
        <DashboardStub />
      </CardBox>
    );
  }
  const chartData = prepareData(aov);


  return (
    <CardBox header="Average Order Value (AOV)">
      <div className="row">
        <div className="col-6">
          <Bar data={chartData} options={chartOptions} height={240} />
        </div>
        <div className="col-6">
          <p>
            Average Order Value(AOV) for visitors who used recommendations is higher on {aov.rate}%.
          </p>
        </div>
      </div>

    </CardBox>
  );
}

AverageOrder.propTypes = {
  aov: PropTypes.shape({
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    noRecs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    error: PropTypes.any,
  }).isRequired,
};

export default AverageOrder;
