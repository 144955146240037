import { put, takeLatest } from 'redux-saga/effects';
import * as c from 'constants/actionTypes/analytics';
import { GET } from '@kealabs/kea-services/util/constants';
import {
  calcWidgetCurve, prepareDateRange,
  wsClient,
  SEARCH, RECS,
} from '../util/appUtils';

const fields = ['records', 'users', 'queries', 'curve'].join('+');

/**
 * AB ex
 */

function* getABResults(action) {
  try {
    const { token, range } = action.payload;
    const { from, to } = prepareDateRange(range);
    const qs = { from, to, token };
    const path = 'system/analytics/common/ab/report';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_A_B_RESULTS_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_A_B_RESULTS_FAILED, payload: err });
  }
}

function* getABCarts(action) {
  try {
    const { token, timeframe, page, group, limit } = action.payload;
    const { from, to } = prepareDateRange(timeframe);
    const qs = { from, to, shopId: token, limit, skip: 0 };
    if (page !== 1) qs.skip = limit * (page - 1);
    if (group) qs.group = group;
    const data = yield wsClient.prepareRequest(GET, '/system/catalog/carts', { qs });
    yield put({ type: c.GET_A_B_CARTS_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_A_B_CARTS_FAILED, payload: err });
  }
}


/**
 * Dashboard analytics
 */

function* getRecsDashboard(action) {
  try {
    const { token, range } = action.payload;
    const { from, to } = prepareDateRange(range);
    const data = yield wsClient.fetchReports(token, RECS, from, to);
    yield put({ type: c.GET_RECS_DASHBOARD_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_RECS_DASHBOARD_FAILED, payload: err });
  }
}

function* getSearchDashboard(action) {
  try {
    const { token, range } = action.payload;
    const { from, to } = prepareDateRange(range);
    const data = yield wsClient.fetchReports(token, SEARCH, from, to);
    yield put({ type: c.GET_SEARCH_DASHBOARD_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_DASHBOARD_FAILED, payload: err });
  }
}


/**
 * Queries analytics
 */

function* getSearchStats(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const query = { token, from, to, appId: SEARCH };
    const path = `system/analytics/${SEARCH}/stats`;
    const data = yield wsClient.prepareRequest(GET, path, { qs: query });

    yield put({ type: c.GET_SEARCH_STATS_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_STATS_FAILED, payload: err });
  }
} // ok

function* getAnalyticFilterPopular(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);
    const appId = SEARCH;

    const query = { token, from, to, appId };
    const path = `system/analytics/${appId}/filter/popular`;
    const data = yield wsClient.prepareRequest(GET, path, { qs: query });

    yield put({ type: c.GET_SEARCH_FILTER_POPULAR_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_FILTER_POPULAR_FAILED, payload: err });
  }
} // ok

function* getAnalyticLostOpportunities(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, fields };
    const path = 'system/analytics/search/lostOpportunities';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    const calculatedData = calcWidgetCurve(data.curve);
    data.curve = {
      data: Object
        .keys(calculatedData)
        .map((el) => ({ total: calculatedData[el].total, label: calculatedData[el].label })),
    };

    yield put({ type: c.GET_SEARCH_LOST_OPPORTUNITIES_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_LOST_OPPORTUNITIES_FAILED, payload: err });
  }
} // ok

function* getSearchAnalyticCurve(action) {
  try {
    const { range, token, type, query } = action.payload;
    const { from, to } = prepareDateRange(range);
    const appId = SEARCH;

    const qs = {
      token,
      from,
      to,
      fields,
      type,
      appId,
    };
    if (query) qs.query = query;
    const path = `system/analytics/${appId}/curve`;
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_SEARCH_CURVE_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_CURVE_FAILED, payload: err });
  }
}

function* getAnalyticQueryPopular(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, fields, type: 'search' };
    const path = 'system/analytics/search/popular';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    const calculatedData = calcWidgetCurve(data.curve);
    data.curve = {
      data: Object
        .keys(calculatedData)
        .map((el) => ({
          total: calculatedData[el].total,
          ctr: (calculatedData[el].ctr * 100).toFixed(2),
          label: calculatedData[el].label,
        })),
    };

    yield put({ type: c.GET_SEARCH_QUERY_POPULAR_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_QUERY_POPULAR_FAILED, payload: err });
  }
}

function* getAnalyticQuerySorted(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);
    const appId = SEARCH;

    const qs = { token, from, to, fields, type: 'search', appId };
    const path = `system/analytics/${appId}/queriesSorted`;
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_SEARCH_QUERY_SORTED_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_QUERY_SORTED_FAILED, payload: err });
  }
} // deprecated?

function* getAnalyticQueryNoResult(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, fields, type: 'all' };
    const path = 'system/analytics/search/queriesWithoutResults';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    const calculatedData = calcWidgetCurve(data.curve);
    data.curve = {
      data: Object
        .keys(calculatedData)
        .map((el) => ({ pv: calculatedData[el].total, label: calculatedData[el].label })),
    };

    yield put({ type: c.GET_SEARCH_QUERY_NO_RESULT_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_QUERY_NO_RESULT_FAILED, payload: err });
  }
}

function* getRelatedQueries(action) {
  try {
    const { range, token, query } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, query };
    const path = 'system/analytics/search/related';
    const { records, error } = yield wsClient.prepareRequest(GET, path, { qs });
    const payload = {
      error,
      records: records.map((el) => ({ query: el._id.query, count: el.count })),
    };
    yield put({ type: c.GET_SEARCH_RELATED_QUERIES_COMPLETE, payload });
  } catch (err) {
    yield put({ type: c.GET_SEARCH_RELATED_QUERIES_FAILED, payload: err });
  }
}

/**
 * Products analytics
 */

function* getProductConversionBest(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to };
    const path = 'system/analytics/product/conversion/best';
    const data = yield wsClient.prepareRequest(GET, path, { qs });
    yield put({ type: c.GET_PRODUCTS_CONVERSION_BEST_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_PRODUCTS_CONVERSION_BEST_FAILED, payload: err });
  }
}

function* getProductConversionWorst(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to };
    const path = 'system/analytics/product/conversion/worst';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_PRODUCTS_CONVERSION_WORST_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_PRODUCTS_CONVERSION_WORST_FAILED, payload: err });
  }
}

function* getProductPopularView(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to };
    const path = 'system/analytics/product/popular/view';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_PRODUCTS_POPULAR_BY_VIEW_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_PRODUCTS_POPULAR_BY_VIEW_FAILED, payload: err });
  }
}

function* getProductPopularPurchase(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to };
    const path = 'system/analytics/product/popular/purchase';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_PRODUCTS_POPULAR_BY_PURCHASE_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_PRODUCTS_POPULAR_BY_PURCHASE_FAILED, payload: err });
  }
}


function* getProductsRelatedByView(action) {
  try {
    const { range, token, productId: id } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, id };
    const path = `system/analytics/product/${id}/related/view`;
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_PRODUCTS_RELATED_BY_VIEW_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_PRODUCTS_RELATED_BY_VIEW_FAILED, payload: err });
  }
}

function* getProductsRelatedByPurchase(action) {
  try {
    const { range, token, productId: id } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, id };
    const path = `system/analytics/product/${id}/related/purchase`;
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_PRODUCTS_RELATED_BY_PURCHASE_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_PRODUCTS_RELATED_BY_PURCHASE_FAILED, payload: err });
  }
}


function* getRelatedProductsByQuery(action) {
  try {
    const { range, token, query } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, query };
    const path = 'system/analytics/search/related/products';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_RELATED_PRODUCTS_BY_QUERY_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_RELATED_PRODUCTS_BY_QUERY_FAILED, payload: err });
  }
}

function* getRelatedQueriesByProduct(action) {
  try {
    const { range, token, productId } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { token, from, to, productId };
    const path = 'system/analytics/product/related/queries';
    const data = yield wsClient.prepareRequest(GET, path, { qs });

    yield put({ type: c.GET_RELATED_QUERIES_BY_PRODUCT_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_RELATED_QUERIES_BY_PRODUCT_FAILED, payload: err });
  }
}

function* getAnalyticsWidgetsPerformance(action) {
  try {
    const { range, token } = action.payload;
    const { from, to } = prepareDateRange(range);
    const appId = RECS;

    const query = { token, from, to, appId };
    const path = `system/analytics/${appId}/widgets`;
    const data = yield wsClient.prepareRequest(GET, path, { qs: query });

    yield put({ type: c.GET_RECS_WIDGETS_PERFORMANCE_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_RECS_WIDGETS_PERFORMANCE_FAILED, payload: err });
  }
} // ok

export default function* analyticSagas() {
  yield takeLatest(c.GET_A_B_RESULTS, getABResults);
  yield takeLatest(c.GET_A_B_CARTS, getABCarts);

  yield takeLatest(c.GET_RECS_DASHBOARD, getRecsDashboard);
  yield takeLatest(c.GET_SEARCH_DASHBOARD, getSearchDashboard);

  yield takeLatest(c.GET_SEARCH_STATS, getSearchStats);
  yield takeLatest(c.GET_SEARCH_FILTER_POPULAR, getAnalyticFilterPopular);
  yield takeLatest(c.GET_SEARCH_LOST_OPPORTUNITIES, getAnalyticLostOpportunities);
  yield takeLatest(c.GET_SEARCH_CURVE, getSearchAnalyticCurve);
  yield takeLatest(c.GET_SEARCH_QUERY_POPULAR, getAnalyticQueryPopular);
  yield takeLatest(c.GET_SEARCH_QUERY_SORTED, getAnalyticQuerySorted);
  yield takeLatest(c.GET_SEARCH_QUERY_NO_RESULT, getAnalyticQueryNoResult);
  yield takeLatest(c.GET_SEARCH_RELATED_QUERIES, getRelatedQueries);

  yield takeLatest(c.GET_PRODUCTS_CONVERSION_BEST, getProductConversionBest);
  yield takeLatest(c.GET_PRODUCTS_CONVERSION_WORST, getProductConversionWorst);
  yield takeLatest(c.GET_PRODUCTS_POPULAR_BY_VIEW, getProductPopularView);
  yield takeLatest(c.GET_PRODUCTS_POPULAR_BY_PURCHASE, getProductPopularPurchase);

  yield takeLatest(c.GET_PRODUCTS_RELATED_BY_VIEW, getProductsRelatedByView);
  yield takeLatest(c.GET_PRODUCTS_RELATED_BY_PURCHASE, getProductsRelatedByPurchase);

  yield takeLatest(c.GET_RELATED_PRODUCTS_BY_QUERY, getRelatedProductsByQuery);
  yield takeLatest(c.GET_RELATED_QUERIES_BY_PRODUCT, getRelatedQueriesByProduct);

  yield takeLatest(c.GET_RECS_WIDGETS_PERFORMANCE, getAnalyticsWidgetsPerformance);
}
