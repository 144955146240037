import { put, takeLatest, select, all } from 'redux-saga/effects';
import {
  GET_AUTHORIZATION_INFO_FAILED,
  KEA_SIGN_IN_FAILED,
  KEA_SIGN_IN_WITH_SECRET_FAILED,
} from 'constants/ActionTypes';

import {
  FORCE_UPDATE_RECS_VISIBLE_COMPLETE,
  FORCE_UPDATE_SEARCH_VISIBLE_COMPLETE,
  GET_ALERTS_FAILED,
  GET_ALL_SETTINGS_FAILED,
  GET_DESCRIBES_FAILED,
  SAVE_RECS_SETTINGS_COMPLETED,
  SAVE_RECS_SETTINGS_FAILED,
  SAVE_SEARCH_SETTINGS_COMPLETED,
  SAVE_SEARCH_SETTINGS_FAILED,
} from '../constants/actionTypes/settings';

import * as search from '../constants/actionTypes/search';
import * as recs from '../constants/actionTypes/recs';
import * as analytics from '../constants/actionTypes/analytics';


import {
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
} from '../actions/Notifications';
import { GET_PRODUCT_BY_ID_FAILED, GET_PRODUCTS_FAILED } from '../constants/actionTypes/catalog';
import { GET_PLANS_FAILED } from '../constants/actionTypes/billing';


function* loginFailed(action) {
  const { payload: error } = action;
  if (/401/.test(error.message)) yield put(enqueueErrorSnackbar('The username or password is not correct.'));
  else yield put(enqueueErrorSnackbar('Something went wrong'));
}

function* unexpectedError({ payload: error }) {
  yield put(enqueueErrorSnackbar(`Sorry, something went wrong. ${error.message}`, 3000));
}

function* fetchingFailed() {
  yield put(enqueueErrorSnackbar('Can\'t get data from server'));
}

function* savedSuccess() {
  yield put(enqueueSuccessSnackbar('Saved'));
}

function* savedSuccessWithActions({ payload }) {
  const { actions } = yield select((store) => store.settings.alerts);
  const alerts = [];
  payload.actions.forEach((e) => { if (actions.hasOwnProperty(e)) alerts.push(actions[e]); });
  if (alerts.length === 0) {
    yield put(enqueueSuccessSnackbar('Saved'));
  } else {
    yield all(alerts.map((action) => put(enqueueSuccessSnackbar(action.message, 4000))));
  }
}

function* savedSuccessWithMessage() {
  yield put(enqueueSuccessSnackbar('Success! Your changes will take effect in a few minutes!', 4000));
}


export default function* notifications() {
  yield takeLatest(KEA_SIGN_IN_FAILED, loginFailed);
  yield takeLatest(KEA_SIGN_IN_WITH_SECRET_FAILED, loginFailed);

  // unexpectedError
  yield takeLatest(SAVE_SEARCH_SETTINGS_FAILED, unexpectedError);
  yield takeLatest(SAVE_RECS_SETTINGS_FAILED, unexpectedError);
  yield takeLatest(search.SAVE_FIELDS_CHANGES_FAILED, unexpectedError);
  yield takeLatest(recs.SAVE_FIELDS_CHANGES_FAILED, unexpectedError);
  yield takeLatest(recs.RECS_UPDATE_ONE_WIDGET_FAILED, unexpectedError);
  yield takeLatest(recs.RECS_DELETE_WIDGET_FAILED, unexpectedError);
  yield takeLatest(recs.RECS_CREATE_WIDGET_FAILED, unexpectedError);
  yield takeLatest(recs.RECS_UPDATE_PAGE_FAILED, unexpectedError);


  // fetchingFailed
  yield takeLatest(GET_AUTHORIZATION_INFO_FAILED, fetchingFailed);
  yield takeLatest(GET_DESCRIBES_FAILED, fetchingFailed);
  yield takeLatest(GET_ALL_SETTINGS_FAILED, fetchingFailed);
  yield takeLatest(GET_ALERTS_FAILED, fetchingFailed);
  yield takeLatest(search.GET_FIELDS_FAILED, fetchingFailed);
  yield takeLatest(recs.GET_FIELDS_FAILED, fetchingFailed);
  yield takeLatest(recs.RECS_LOAD_ALL_MODELS_FAILED, fetchingFailed);
  yield takeLatest(recs.RECS_LOAD_ALL_WIDGETS_FAILED, fetchingFailed);
  yield takeLatest(recs.RECS_LOAD_ALL_PAGES_FAILED, fetchingFailed);
  yield takeLatest(GET_PRODUCT_BY_ID_FAILED, fetchingFailed);
  yield takeLatest(GET_PRODUCTS_FAILED, fetchingFailed);
  yield takeLatest(GET_PLANS_FAILED, fetchingFailed);

  yield takeLatest(analytics.GET_RECS_DASHBOARD_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_DASHBOARD_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_STATS_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_FILTER_POPULAR_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_LOST_OPPORTUNITIES_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_CURVE_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_QUERY_POPULAR_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_QUERY_SORTED_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_QUERY_NO_RESULT_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_SEARCH_RELATED_QUERIES_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_PRODUCTS_CONVERSION_BEST_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_PRODUCTS_CONVERSION_WORST_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_PRODUCTS_POPULAR_BY_VIEW_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_PRODUCTS_POPULAR_BY_PURCHASE_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_PRODUCTS_RELATED_BY_VIEW_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_PRODUCTS_RELATED_BY_PURCHASE_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_RELATED_PRODUCTS_BY_QUERY_FAILED, fetchingFailed);
  yield takeLatest(analytics.GET_RELATED_QUERIES_BY_PRODUCT_FAILED, fetchingFailed);

  // savedSuccessWithActions
  yield takeLatest(SAVE_SEARCH_SETTINGS_COMPLETED, savedSuccessWithActions);
  yield takeLatest(SAVE_RECS_SETTINGS_COMPLETED, savedSuccessWithActions);
  yield takeLatest(recs.RECS_UPDATE_ONE_WIDGET_COMPLETE, savedSuccessWithActions);
  yield takeLatest(recs.RECS_UPDATE_PAGE_COMPLETE, savedSuccessWithActions);

  // savedSuccessWithMessage
  yield takeLatest(search.SAVE_FIELDS_CHANGES_COMPLETED, savedSuccessWithMessage);
  yield takeLatest(recs.SAVE_FIELDS_CHANGES_COMPLETED, savedSuccessWithMessage);

  // savedSuccess
  yield takeLatest(FORCE_UPDATE_RECS_VISIBLE_COMPLETE, savedSuccess);
  yield takeLatest(FORCE_UPDATE_SEARCH_VISIBLE_COMPLETE, savedSuccess);
  yield takeLatest(recs.RECS_CREATE_WIDGET_COMPLETE, savedSuccess);
}
