import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsToggleSwitcher from 'components/kea/settings/SettingsToggleSwitcher';
import SettingsSelectInput from 'components/kea/settings/SettingsSelectInput';
import SettingsSelectRadio from 'components/kea/settings/SettingsSelectRadio';
import * as a from 'actions';

const General = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store);
  const { search: { general }, searchVisible } = settings;

  const handleClickEnableService = (value) => dispatch(a.updateSearchVisible(value));
  const handleClickFuzzySearch = (value) => dispatch(a.changeSearchGeneralSetting('searchFuzzy', value));
  const handleClickSearchInDescription = (value) => dispatch(a.changeSearchGeneralSetting('searchInDescription', value));
  const handleClickFuzzyInDescription = (value) => dispatch(a.changeSearchGeneralSetting('searchFuzzyInDescription', value));
  const handleClickSearchOperator = (value) => dispatch(a.changeSearchGeneralSetting('searchOperator', value));
  const handleClickAvailableProducts = (value) => dispatch(a.changeSearchGeneralSetting('availability', value));

  return (
    <div className="settings-container">
      <div className="setting-group-header">Kea Labs Search integration</div>
      <SettingsToggleSwitcher
        title="Enable Kea Labs Search"
        description={'You can switch off Kea Labs Search if is  required. So nobody can see it.  \n After that you can safely test all of your changes with a Preview tool.'}
        value={searchVisible.value}
        onChange={handleClickEnableService}
      />

      <div className="setting-group-header">Fuzzy search</div>
      <SettingsToggleSwitcher
        title="Use fuzzy search"
        description="Fuzzy search locates items that are likely to be relevant to a search argument even when the argument does not exactly correspond to the desired information."
        value={general.searchFuzzy}
        onChange={handleClickFuzzySearch}
      />

      <SettingsToggleSwitcher
        title="Perform search over a product description fields."
        description="By default Search processes more important data fields, like product name, brand, tags etc."
        value={general.searchInDescription}
        onChange={handleClickSearchInDescription}
      />

      <SettingsToggleSwitcher
        title="Use fuzzy search in product description fields"
        description="It's not recommended as it may bring low-relevant results, especially if you have detailed product descriptions."
        value={general.searchFuzzyInDescription}
        onChange={handleClickFuzzyInDescription}
      />

      <div className="setting-group-header">Behaviour</div>
      <SettingsSelectInput
        items={[{ value: 'all', text: 'Show all' }, { value: false, text: 'Only available' }]}
        title="Show only available products"
        value={general.availability}
        onChange={handleClickAvailableProducts}
        description="By default, search works over all products(even if they are not available for purchase or out of stock).Disable this setting to search only within available products."
      />

      <SettingsSelectRadio
        value={general.searchOperator}
        onChange={handleClickSearchOperator}
        groupTitle="Default search operator"
        items={[
          {
            value: 'OR',
            text: 'OR - search results returns product containing ANY input words. It may return too many irrelevant results.',
          },
          {
            value: 'AND',
            text: 'AND - search results have to contain all of input words. Each word refines search criteria.',
          },
        ]}
      />

      {/* <div style={{ whiteSpace: 'pre' }}> */}
      {/*  { JSON.stringify({ searchVisible, general }, null, 4) } */}
      {/* </div> */}
    </div>
  );
};

export default General;
