import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as a from 'actions';
import SettingsToggleSwitcher from 'components/kea/settings/SettingsToggleSwitcher';
import SettingRangeSlider from 'components/kea/settings/SettingRangeSlider';


const Filters = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store);
  const { search: { facets } } = settings;

  const changeHandlerKeepOpened = (value) => dispatch(a.changeSearchFacetsSetting('collapseKeepOpened', value));
  const changeHandlerLimitFacets = (value) => dispatch(a.changeSearchFacetsSetting('limitFacets', value));
  const changeHandlerMaxColors = (value) => dispatch(a.changeSearchFacetsSetting('maxColors', value));
  const changeHandlerCollapseDef = (value) => dispatch(a.changeSearchFacetsSetting('collapseDefault', value));

  return (
    <div className="settings-container">
      <div className="setting-group-header">Filters</div>
      <SettingsToggleSwitcher
        title="Keep all filters collapsed."
        description="Collapsed filters are easier to view, especially if products have many parameters."
        value={facets.collapseDefault}
        onChange={changeHandlerCollapseDef}
      />

      <SettingRangeSlider
        value={facets.collapseKeepOpened}
        onChange={changeHandlerKeepOpened}
        description="The rest of the filters will be collapsed by default."
        title="Number of expanded filters"
        min={0}
        max={15}
        step={1}
      />

      <SettingRangeSlider
        value={facets.limitFacets}
        onChange={changeHandlerLimitFacets}
        description="If filter has more values, 'view more' button is shown. So, user can easily expand the list and see the rest of the values."
        title="Number of visible values per filter."
        min={2}
        max={10}
        step={1}
      />

      <SettingRangeSlider
        value={facets.maxColors}
        onChange={changeHandlerMaxColors}
        description="The rest of the values get visible after clicking on 'view more' button."
        title="Number of visible values of the Colors filter."
        min={5}
        max={20}
        step={1}
      />

      {/* <div style={{ whiteSpace: 'pre' }}> */}
      {/*  { JSON.stringify(facets, null, 4) } */}
      {/* </div> */}
    </div>
  );
};

export default Filters;
