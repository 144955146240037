export const GET_FIELDS = 'search/GET_FIELDS';
export const GET_FIELDS_COMPLETE = 'search/GET_FIELDS_COMPLETE';
export const GET_FIELDS_FAILED = 'search/GET_FIELDS_FAILED';
export const FIELDS_CHANGE_HANDLER = 'search/FIELDS_CHANGE_HANDLER';
export const DISCARD_FIELDS_CHANGES = 'search/DISCARD_FIELDS_CHANGES';

export const SAVE_FIELDS_CHANGES = 'search/SAVE_FIELDS_CHANGES';
export const SAVE_FIELDS_CHANGES_COMPLETED = 'search/SAVE_FIELDS_CHANGES_COMPLETED';
export const SAVE_FIELDS_CHANGES_FAILED = 'search/SAVE_FIELDS_CHANGES_FAILED';

export const SEARCH_VISIBLE_TOGGLE = 'search/SEARCH_VISIBLE_TOGGLE';
