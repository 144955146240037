import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import timeframeList from './timeframeList';

function isSelectingFirstDay(from, to, day) {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
  const isRangeSelected = from && to;
  return !from || isBeforeFirstDay || isRangeSelected;
}
const dateForReset = {
  from: null,
  to: null,
  enteredTo: null,
  text: null,
  id: null,
};

class DateRangePicker extends React.Component {
  state = {
    anchorEl: null,
    range: this.getInitialRange(),
  }

  getInitialRange() {
    const { timeframe } = this.props;
    if (timeframe) return { ...timeframe, enteredTo: timeframe.to };
    return timeframeList.thisMonth;
  }

  handleClose = () => this.setState({ anchorEl: null });

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });

  updateRange = (dataToUpdate) => {
    const { range } = this.state;
    const newRange = {
      ...range,
      ...dataToUpdate,
      enteredTo: dataToUpdate.to,
    };
    this.setState({ range: newRange });
  }

   handleClickTimeframe = (id) => {
     const range = timeframeList[id];
     this.props.onSubmitRange(range);
     this.handleClose();
     this.updateRange(range);
   }

   handleResetClick = () => this.updateRange(dateForReset);

   render() {
     const { isFetching, onSubmitRange } = this.props;
     const {
       anchorEl,
       range,
       range: {
         from, to, enteredTo, text,
       },
     } = this.state;

     const modifiers = {
       start: from,
       end: enteredTo,
       futureDay: (day) => DateUtils.isFutureDay(day),
     };

     const labelText = (from && to)
       ? (moment(from).isSame(to, 'day'))
         ? text || `${moment(from.toLocaleDateString()).format('MMM Do YYYY')}`
         : text || `Range: ${from.toLocaleDateString()} - ${to.toLocaleDateString()}`
       : 'Select date';


     const handleDayMouseEnter = (day) => {
       if (!isSelectingFirstDay(from, to, day) && !DateUtils.isFutureDay(day)) {
         this.setState({ range: { ...range, enteredTo: day } });
       }
     };

     const handleDayClick = (day) => {
       if (from && to && day >= from && day <= to) {
         this.handleResetClick();
         return;
       }
       if (DateUtils.isFutureDay(day)) return;

       if (isSelectingFirstDay(from, to, day)) {
         this.updateRange({
           from: day,
           to: null,
           enteredTo: null,
         });
       } else {
         this.handleClose();
         this.updateRange({ to: day, id: null, text: null });
         onSubmitRange({ from, to: day, enteredTo: day });
       }
     };

     const initialMonth = DateUtils.addMonths(from || new Date(), -1);

     return (
       <div>
         <Button
           className="jr-btn text-white"
           color="primary"
           size="small"
           variant="contained"
           onClick={this.handleClick}
         >
           {isFetching ? '...' : labelText}
         </Button>

         <Popover
           id={anchorEl ? 'simple-popover' : undefined}
           open={Boolean(anchorEl)}
           anchorEl={anchorEl}
           onClose={this.handleClose}
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           transformOrigin={{
             vertical: 'top',
             horizontal: 'center',
           }}
         >
           <div style={{ padding: 30 }}>

             <div className="RangeExample">
               <p>
                 {!from && !to && 'Please select the first day.'}
                 {from && !to && 'Please select the last day.'}
                 {from
                && to
                && `Selected from ${from.toLocaleDateString()} to
                          ${to.toLocaleDateString()}`}
               </p>
               <div className="datepicker-tf-btn-grp">
                 {
                  Object.keys(timeframeList).map((id) => {
                    const item = timeframeList[id];
                    return (
                      <Button
                        key={item.id}
                        size="small"
                        className="jr-btn text-white"
                        color="primary"
                        variant="contained"
                        onClick={() => this.handleClickTimeframe(item.id)}
                      >
                        {item.text}
                      </Button>
                    );
                  })
                }
               </div>
               <DayPicker
                 className="Range"
                 numberOfMonths={3}
                 pagedNavigation
                 firstDayOfWeek={1}
                 selectedDays={[from, { from, to: enteredTo }]}
                 initialMonth={initialMonth}
                 modifiers={modifiers}
                 onDayClick={handleDayClick}
                 onDayMouseEnter={handleDayMouseEnter}
               />
             </div>
           </div>
         </Popover>
       </div>
     );
   }
}
DateRangePicker.defaultProps = {
  // timeframe: timeframeList.thisMonth,
  isFetching: false,
};

DateRangePicker.propTypes = {
  timeframe: PropTypes.shape({
    from: PropTypes.any,
    to: PropTypes.any,
  }),
  onSubmitRange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,

};

export default DateRangePicker;
