import * as c from '../constants/actionTypes/recs';

export const getRecsFields = (token) => ({
  type: c.GET_FIELDS,
  payload: { token },
});

export const recsLoadAllPages = (token) => ({
  type: c.RECS_LOAD_ALL_PAGES,
  payload: { token },
});

export const recsLoadOnePage = (pageId) => ({
  type: c.RECS_LOAD_ONE_PAGE,
  payload: pageId,
});

export const recsUpdatePage = (page) => ({
  type: c.RECS_UPDATE_PAGE,
  payload: { page },
});

export const recsCustomizePageSections = (pageId, sections) => ({ // It change values in app store.
  type: c.RECS_CUSTOMIZE_SECTIONS_IN_PAGE,
  payload: { sections, pageId },
});

export const recsLoadAllModels = (token) => ({
  type: c.RECS_LOAD_ALL_MODELS,
  payload: { token },
});

export const recsLoadOneModel = (shopId, modelId) => ({
  type: c.RECS_LOAD_ONE_MODEL,
  payload: modelId,
});


export const recsLoadAllWidgets = (token) => ({
  type: c.RECS_LOAD_ALL_WIDGETS,
  payload: { token },
});

export const recsLoadOneWidget = (widgetId) => ({
  type: c.RECS_LOAD_ONE_WIDGET,
  payload: widgetId,
});

export const recsUpdateOneWidget = (widget, pageId) => ({
  type: c.RECS_UPDATE_ONE_WIDGET,
  payload: { widget, pageId },
});

export const recsCreateWidget = (widget, pageId, sectionId) => ({
  type: c.RECS_CREATE_WIDGET,
  payload: { widget, pageId, sectionId },
});

export const recsDeleteWidget = (alias, pageId) => ({
  type: c.RECS_DELETE_WIDGET,
  payload: { alias, pageId },
});

export const recsUpdateManyWidgets = (arrayToUpdate) => ({
  type: c.RECS_UPDATE_MANY_WIDGETS,
  payload: arrayToUpdate,
});

export const forceUpdateRecsVisible = (isVisible) => ({
  type: c.FORCE_UPDATE_RECS_VISIBLE,
  payload: isVisible,
});

export const fieldsDataUpdate = (field, key, value) => ({
  type: c.FIELDS_CHANGE_HANDLER,
  payload: { field, key, value },
});

export const discardRecsFieldsSetting = () => ({
  type: c.DISCARD_FIELDS_CHANGES,
});

export const saveRecsFields = () => ({
  type: c.SAVE_FIELDS_CHANGES,
});
