import React from 'react';
import PropsType from 'prop-types';

export default function StrangeIcon({ icon }) {
  return <i className={`zmdi zmdi-hc-fw zmdi-${icon}`} />;
}

StrangeIcon.propTypes = {
  icon: PropsType.string.isRequired,
};
