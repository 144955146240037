import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '@material-ui/core/Card';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import * as actions from 'actions/Recs';
import ContainerHeader from 'components/ContainerHeader';
import SettingsToggleSwitcher from 'components/kea/settings/SettingsToggleSwitcher';
import SettingTextInput from 'components/kea/settings/SettingTextInput';
import SettingRangeSlider from 'components/kea/settings/SettingRangeSlider';
import SettingsSelectInput from 'components/kea/settings/SettingsSelectInput';
import IntlMessages from 'util/IntlMessages';
import SaveActionBtnGrp from 'components/kea/settings/SaveActionBtnGrp';
import { objectDifference } from 'util/appUtils';

class Widget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: {},
      widgetDefault: {},
    };

    this.changeHandler = (value, key) => {
      const { widget } = this.state;
      this.setState({ widget: { ...widget, [key]: value } });
    };

    this.discardChanges = () => {
      this.setState({ widget: this.state.widgetDefault });
    };
  }

  componentDidMount() {
    const { settings, recs: { model, widget, page } } = this.props;
    if (!model.isFetched) actions.recsLoadAllModels(settings.shopId);
    if (!widget.isFetched) actions.recsLoadAllWidgets(settings.shopId);
    if (!page.isFetched) actions.recsLoadAllPages(settings.shopId);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      match: { params: { widgetId } },
      recs: { widget: widgets },
    } = props;

    if (widgets.isFetched && widgets.dataMap[widgetId]) {
      const currentWidget = widgets.dataMap[widgetId];
      const stateShouldBeUpdated = _.isEqual(state.widgetDefault, currentWidget) === false;
      if (stateShouldBeUpdated) {
        return { widget: currentWidget, widgetDefault: currentWidget };
      }
    }
    return null;
  }

  detectNewWidget({ sections }, { sections: sectionsDef }) {
    const widgets = _.flatten(sections.map((section) => section.widgets));
    const widgetsDef = _.flatten(sectionsDef.map((section) => section.widgets));

    return _.difference(widgets, widgetsDef);
  }

  render() {
    const { match, recs, settings } = this.props;
    const { params: { pageId, widgetId } } = match;
    const { model: models, widget: widgets, page } = recs;

    const { widget, widgetDefault } = this.state;

    const ableToRender = !settings.isFetched || !models.isFetched || !widgets.isFetched || !page.isFetched;
    if (ableToRender) return <PlaceholderSpinner />;


    const widgetSettingsIsNotChanged = () => {
      const diff = objectDifference(widget, widgetDefault);
      return Object.keys(diff).length === 0;
    };

    const thisWidgetIsNew = () => {
      const widgetsDiff = this.detectNewWidget(page.dataMap[pageId], page.dataMapDefault[pageId]);
      return widgetsDiff.includes(widgetId);
    };

    const disableSaveBtn = () => widgetSettingsIsNotChanged() && !thisWidgetIsNew();

    const saveActionHandler = (pageId) => {
      const { widget } = this.state;
      if (!widgetSettingsIsNotChanged()) this.props.actions.recsUpdateOneWidget(widget, pageId);
      if (thisWidgetIsNew()) this.props.actions.recsUpdatePage(page.dataMap[pageId]);
    };

    const widgetMap = _.keyBy(widgets.data, 'alias');
    const currentWidget = widgetMap[widgetId];

    const buttons = [
      { text: 'Save', action: () => saveActionHandler(pageId), active: true, disabled: disableSaveBtn(), color: 'primary' },
      { text: 'Discard', action: this.discardChanges, active: true, disabled: widgetSettingsIsNotChanged(), color: 'secondary' },
    ];

    return (
      <div className="app-wrapper slideInUpTiny">
        <ContainerHeader
          match={match}
          title={<IntlMessages id="recommendations.customization.widget.edit.title" values={{ widgetName: currentWidget.title }} />}
          action={(
            <SaveActionBtnGrp
              isFetching={widgets.isUpdating}
              buttons={buttons}
            />
          )}
        />
        <Card className="jr-card-full jr-card-tabs-right jr-card-profile jr-card jr-card-widget ">
          <div className="settings-container">

            <div className="setting-group-header">Widget settings</div>
            <SettingsToggleSwitcher
              title="Show title"
              value={widget.showTitle}
              onChange={(value) => this.changeHandler(value, 'showTitle')}
            />

            <SettingTextInput
              title="Title"
              value={widget.title}
              onChange={(value) => this.changeHandler(value, 'title')}
            />

            <SettingRangeSlider
              value={widget.maxItemsCount}
              onChange={(value) => this.changeHandler(value, 'maxItemsCount')}
              title="Maximal items count"
              min={2}
              max={16}
              step={1}
            />

            <SettingsToggleSwitcher
              title='Show "Add to cart" button'
              value={widget.showAddToCartButton}
              onChange={(value) => this.changeHandler(value, 'showAddToCartButton')}
            />

            <SettingsToggleSwitcher
              title="Force show widget"
              value={widget.showIfLittleRecs}
              onChange={(value) => this.changeHandler(value, 'showIfLittleRecs')}
              description="Show the widget even when the amount of recommendations is less than 4"
            />

            <div className="setting-group-header">Widget behaviour</div>

            <SettingsSelectInput
              title="Algorithm of widget behaviour "
              items={models.dataForSelect}
              value={widget.model}
              onChange={(value) => this.changeHandler(value, 'model')}
            />

            <SettingsToggleSwitcher
              title="Shuffle items "
              value={widget.shuffle}
              onChange={(value) => this.changeHandler(value, 'shuffle')}
            />
          </div>
        </Card>
      </div>
    );
  }
}


export default connect(
  (state) => ({
    recs: state.recs,
    settings: state.settings,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(Widget);
