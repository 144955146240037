import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const IncorrectShop = ({ authorization: { shopList } }) => (
  <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
    <div className="page-error-content">
      <div className="error-code mb-4 animated zoomInDown">Oops...</div>
      <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
        Incorrect shop!
        <br />
        {
          shopList.length
            ? 'Chose the shop below:'
            : 'Try to reload the page, or contact our support.'
        }
        {
          shopList.map((shop) => (
            <Link
              to={`/app/${shop.shopId}/home`}
              key={shop.shopId}
            >
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
                className="jr-btn"
              >
                {shop.shopId}
              </Button>
            </Link>
          ))
        }
      </h2>

    </div>
  </div>
);

export default IncorrectShop;
