import React from 'react';
import { useSelector } from 'react-redux';
import SetupCard from './SetupCard';
import TextCenter from '../Typography/TextCenter';
import Header from '../Typography/Header';
import Subheader from '../Typography/Subheader';
import RealGrid from '../Layout/RealGrid';

const industries = [
  {
    id: 'clothing',
    title: 'Clothing, Apparel & Fashion',
    icon: 'clothing.svg',
  },
  {
    id: 'shoes',
    title: 'Shoes & Accessories',
    icon: 'accessories.svg',
  },
  {
    id: 'electronics',
    title: 'Electronics and Cell Phones',
    icon: 'electronics.svg',
  },
  {
    id: 'interior',
    title: 'Furniture, Art & Design',
    icon: 'interior.svg',
  },
  {
    id: 'diy',
    title: 'Tools, Automotive and DIY',
    icon: 'diy.svg',
  },
  {
    id: 'kids',
    title: 'Kids Clothing, Accessories & Toys',
    icon: 'kids.svg',
  },
  {
    id: 'sport',
    title: 'Sport and Equipment',
    icon: 'sport.svg',
  },
  {
    id: 'exterior',
    title: 'Home & Garden',
    icon: 'exterior.svg',
  },
  {
    id: 'pets',
    title: 'Pets',
    icon: 'pets.svg',
  },
  {
    id: 'books',
    title: 'Books, Music and Films',
    icon: 'books.svg',
  },
  {
    id: 'food',
    title: 'Food & Beverages',
    icon: 'food.svg',
  },
  {
    id: 'other',
    title: 'Other',
    icon: 'other.svg',
  },
];

export default function SetupRecsIndustry({ onClick }) {
  const activeCards = useSelector((store) => store.onboarding.recs.industries);
  const cards = industries.map((industry) => {
    const { id, title, icon } = industry;
    const active = activeCards.indexOf(id) > -1;
    return (
      <SetupCard
        active={active}
        key={id}
        title={title}
        icon={icon}
        onClick={() => onClick(id)}
      />
    );
  });

  return (
    <TextCenter>
      <Header variant="2">What is your industry?</Header>
      <Subheader>Please select industries which apply to your business. You can choose multiple industries.</Subheader>
      <RealGrid>{cards}</RealGrid>
    </TextCenter>
  );
}
