import React from 'react';
import { Switch } from 'react-router-dom';
import Catalog from 'app/routes/store/catalog';
import SafetyRoute from 'components/kea/SafetyRoute';
import StoreAnalytics from './analytisc';
import Experiment from './experiment';
import Carts from './experiment/carts';

export default function StoreRouter({ match }) {
  const { url } = match;
  return (
    <Switch>
      <SafetyRoute path={`${url}/catalog`} render={(props) => <Catalog {...props} />} />
      <SafetyRoute path={`${url}/analytics`} render={(props) => <StoreAnalytics {...props} />} />
      <SafetyRoute path={`${url}/experiment`} exact render={(props) => <Experiment {...props} />} />
      <SafetyRoute path={`${url}/experiment/carts`} exact render={(props) => <Carts {...props} />} />
    </Switch>
  );
}
