import Grid from '@material-ui/core/Grid';
import { Link, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StrangeIcon from './StrangeIcon';
import Header from '../Typography/Header';
import TextCenter from '../Typography/TextCenter';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '30px',
    height: '100%',
  },
}));

export default function SupportRow() {
  const classes = useStyles();
  return (
    <>
      <TextCenter>
        <Header>Need help ?</Header>
      </TextCenter>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Paper className={classes.card}>
            <Typography variant="h5">Email support</Typography>
            <Typography variant="subtitle1">Require custom integrations or customization?</Typography>
            <Typography variant="subtitle1">{'Please contact our Customer Success Team and we\'ll help you.'}</Typography>
            <Link href="mailto:support@kealabs.com">
              <StrangeIcon icon="email" />
              support@kealabs.com
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.card}>
            <Typography variant="h5">Live chat support</Typography>
            <Typography variant="subtitle1">Have questions ? </Typography>
            <Typography variant="subtitle1">Contact our team with a live chat on our website, or on this page in the right bottom corner.</Typography>
            <Typography variant="subtitle1">Note: team is mostly available from 10am to 6pm Central European Time. </Typography>
            <Link target="_blank" href="https://kealabs.com">
              <StrangeIcon icon="home" />
              kealabs.com
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
