import Grid from '@material-ui/core/Grid';
import React from 'react';

export default function Center({ children }) {
  return (
    <Grid container justify="center" spacing={4}>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
}
