import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Add } from '@material-ui/icons';
import _ from 'lodash';

class DialogSelect extends React.Component {
  render() {
    const {
      pageId,
      selected,
      widgetList,

      pagesMap,
      widgetsMap,
      algorithm,

      isOpen,
      sectionId,
      onClose,
      recsCustomizePageSections,
    } = this.props;


    const handleClick = (widgetId) => {
      const { sections } = pagesMap[pageId];
      const sectionsMap = _.keyBy(sections, 'id');

      const newSections = {
        ...sectionsMap,
        [sectionId]: {
          ...sectionsMap[sectionId],
          widgets: [...sectionsMap[sectionId].widgets, widgetId],
        },
      };

      recsCustomizePageSections(pageId, _.flatMap(newSections));

      onClose();
    };

    const filterWidget = (widget) => {
      const isKnownWidget = widgetsMap.hasOwnProperty(widget.alias);
      const isApplicableOnThisPage = (!widget.pages || widget.pages.length === 0 || widget.pages.includes(pageId));
      const isNotSelected = !selected.includes(widget.alias);

      return (isKnownWidget && isApplicableOnThisPage && isNotSelected);
    };

    const availableWidgets = widgetList.filter(filterWidget);
    return (
      <Dialog
        className="dialog-menu"
        open={isOpen}
        onClose={onClose}
        transitionDuration={0}
      >
        <DialogTitle>Select widget</DialogTitle>
        <DialogContent>
          <div className="container-fluid">
            {
              availableWidgets.length === 0
                ? `You already have added all recommendation widgets available on the "${pagesMap[pageId].title}" page`
                : availableWidgets.map((el) => {
                  const { alias: widgetId } = el;
                  return (
                    <div
                      onClick={() => handleClick(widgetId)}
                      key={widgetId}
                      className="widget-items-container"
                    >

                      <div className="widget-items-title">
                        <div className="widget-item-header">
                          {widgetsMap[widgetId].title}
                        </div>
                        <div className="widget-item-sub-header">
                          {`Algorithm: ${algorithm[widgetsMap[widgetId].model]}`}
                        </div>
                      </div>

                      <div className="widget-items-add-icon">
                        <Add />
                      </div>
                    </div>
                  );
                })
            }

          </div>
        </DialogContent>
        <DialogActions>
          <Button className="jr-btn" onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogSelect;
