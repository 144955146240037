/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { forgotPassword } from 'actions/Auth';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);

  const emailInputRef = useRef(null);

  const submitForgotPassword = () => {
    dispatch(forgotPassword({
      email: emailInputRef.current.value,
    }));
  };

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
    >
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <img src={require('assets/images/kealogo.svg')} alt="kea_logo" />
        </div>
        <div className="app-login-content">
          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  inputRef={emailInputRef}
                  disabled={loader}
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    onClick={submitForgotPassword}
                    variant="contained"
                    color="primary"
                    disabled={loader}
                  >
                    <IntlMessages id="appModule.resetPassword" />
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {
        loader
        && (
        <div className="loader-view">
          <CircularProgress />
        </div>
        )
      }
      {showMessage && NotificationManager.error(alertMessage)}
      <NotificationContainer />
    </div>
  );
};

export default ForgotPassword;
