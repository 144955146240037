import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';
import ButtonGrp from '../../../../components/kea/ButtonGrp';

function Profitability({ chart }) {
  const barIsWeekly = (chart.daily && !chart.error) ? (chart.daily.money.curve.length > 25) : false;
  const [weeklyBar, toggleBarType] = useState(barIsWeekly);

  const barOptions = {
    legend: {
      position: 'bottom',
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
      }],
    },
  };

  function prepareData(raw) {
    const { gray, main } = chartColors;
    const result = {
      labels: [],
      datasets: [
        {
          label: 'Other items',
          backgroundColor: gray.dark,
          data: [],
        }, {
          label: 'Items discovered from the search',
          backgroundColor: main.normal,
          data: [],
        }],
    };
    raw.forEach((e) => {
      result.labels.push(e.date);
      result.datasets[0].data.push(e.noSearch.toFixed(0));
      result.datasets[1].data.push(e.search.toFixed(0));
    });

    return result;
  }

  if (chart.error || !chart.weekly || !chart.daily) {
    return (
      <CardBox header="Profitability">
        <DashboardStub />
      </CardBox>
    );
  }

  const { money } = weeklyBar ? chart.weekly : chart.daily;
  const data = prepareData(money.curve);

  const actionComponent = <ButtonGrp value={weeklyBar} buttons={[{ value: true, text: 'Weekly' }, { value: false, text: 'Daily' }]} onClick={toggleBarType} />;

  return (
    <CardBox
      header="Profitability"
      subheader="Chart represents cumulative price off all products added to carts during the day/week. Each
                    column highlights products which have been for the first time discovered from search and added
                    into cart within the same session. "
      subsubheader={'*NOTE: chart doesn\'t represent state of carts as Kea Labs doesn\'t know which of them were bought.'}
      actionComponent={actionComponent}
    >
      <div className="row">
        <div className="col-12">
          <Bar data={data} options={barOptions} height={140} />
        </div>
      </div>

    </CardBox>
  );
}

Profitability.propTypes = {
  chart: PropTypes.shape({
    weekly: PropTypes.shape({
      money: PropTypes.shape({
        curve: PropTypes.arrayOf(PropTypes.shape({
          noRec: PropTypes.any,
          rec: PropTypes.any,
        })),
      }),
    }),
    daily: PropTypes.shape({
      money: PropTypes.shape({
        curve: PropTypes.arrayOf(PropTypes.shape({
          noRec: PropTypes.any,
          rec: PropTypes.any,
        })),
      }),
    }),

    error: PropTypes.any,
  }).isRequired,
};

export default Profitability;
