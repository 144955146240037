/* eslint-disable max-len */
import * as c from 'constants/actionTypes/analytics';
import timeframeList from '../components/kea/DateRangePicker/timeframeList';

const defaultState = {
  timeframe: timeframeList.last30days,
  experimentCarts: { isFetching: true, error: null, data: {} },
  experiment: {
    isFetching: true,
    error: null,
    a: {},
    b: {},
    diff: {},
  },
  recs: {
    dashboard: { isFetching: false },
    widgetsPerformance: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false },
  },

  search: {
    dashboard: { timeframe: timeframeList.thisMonth, isFetching: false },
    stats: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false },
    filterPopular: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    lostOpportunities: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    curve: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    queryPopular: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    querySorted: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    queryNoResult: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    relatedQueries: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    relatedQueriesByProduct: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
  },

  products: {
    conversionBest: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    conversionWorst: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    popularByView: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    popularByPurchase: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    relatedByView: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    relatedByPurchase: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
    relatedByQuery: { timeframe: timeframeList.thisMonth, isFetching: false, isFetched: false, data: [] },
  },

  // TODO: maybe move to new reducer: Catalog
  product: { isFetching: false, isFetched: false },
  productList: { isFetching: false, isFetched: false },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case c.CHANGE_TIMEFRAME_RANGE:
      return {
        ...state,
        timeframe: action.payload,
      };

      /**
       ***  AB TEST  ***
       */

    case c.GET_A_B_RESULTS:
      return {
        ...state,
        experiment: {
          ...state.experiment,
          isFetching: true,
          error: null,
        },
      };
    case c.GET_A_B_RESULTS_COMPLETE:
      return {
        ...state,
        experiment: {
          ...state.experiment,
          ...action.payload,
          isFetching: false,
        },
      };
    case c.GET_A_B_RESULTS_FAILED:
      return {
        ...state,
        experiment: {
          ...state.experiment,
          isFetching: false,
          error: action.payload.message,
        },
      };

    case c.GET_A_B_CARTS:
      return {
        ...state,
        experimentCarts: {
          ...state.experimentCarts,
          isFetching: true,
          error: null,
        },
      };
    case c.GET_A_B_CARTS_COMPLETE:
      return {
        ...state,
        experimentCarts: {
          ...state.experimentCarts,
          ...action.payload,
          isFetching: false,
        },
      };
    case c.GET_A_B_CARTS_FAILED:
      return {
        ...state,
        experimentCarts: {
          ...state.experimentCarts,
          isFetching: false,
          error: action.payload.message,
        },
      };

      /**
       ***  RECS ***
     */

    case c.GET_RECS_DASHBOARD:
      return {
        ...state,
        recs: {
          ...state.recs,
          dashboard: {
            ...state.recs.dashboard,
            isFetching: true,
          },
        },
      };
    case c.GET_RECS_DASHBOARD_COMPLETE:
      return {
        ...state,
        recs: {
          ...state.recs,
          dashboard: {
            isFetched: true,
            isFetching: false,
            ...action.payload,
          },

        },
      };
    case c.GET_RECS_DASHBOARD_FAILED:
      return {
        ...state,
        recs: {
          ...state.recs,
          dashboard: {
            ...state.recs.dashboard,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };

      /**
     ***  RECS ANALYTICS***
     */

    case c.GET_RECS_WIDGETS_PERFORMANCE:
      return {
        ...state,
        recs: {
          ...state.recs,
          widgetsPerformance: {
            ...state.recs.widgetsPerformance,
            isFetching: true,
          },
        },
      };
    case c.GET_RECS_WIDGETS_PERFORMANCE_COMPLETE:
      return {
        ...state,
        recs: {
          ...state.recs,
          widgetsPerformance: {
            ...state.recs.widgetsPerformance,
            isFetched: true,
            isFetching: false,
            ...action.payload,
          },

        },
      };
    case c.GET_RECS_WIDGETS_PERFORMANCE_FAILED:
      return {
        ...state,
        recs: {
          ...state.recs,
          widgetsPerformance: {
            ...state.recs.widgetsPerformance,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };

      /**
       ***  SEARCH DASHBOARD ***
       */

    case c.GET_SEARCH_DASHBOARD:
      return {
        ...state,
        search: {
          ...state.search,
          dashboard: {
            ...state.search.dashboard,
            isFetching: true,
          },
        },
      };
    case c.GET_SEARCH_DASHBOARD_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          dashboard: {
            isFetched: true,
            isFetching: false,
            ...action.payload,
          },

        },
      };
    case c.GET_SEARCH_DASHBOARD_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          dashboard: {
            ...state.search.dashboard,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS SEARCH_STATS***
       */

    case c.GET_SEARCH_STATS:
      return {
        ...state,
        search: {
          ...state.search,
          stats: {
            ...state.search.stats,
            isFetching: true,
            timeframe: action.payload.range,
          },
        },
      };
    case c.GET_SEARCH_STATS_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          stats: {
            ...state.search.stats,
            data: action.payload,
            error: action.payload.error,
            isFetched: true,
            isFetching: false,
          },
        },
      };
    case c.GET_SEARCH_STATS_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          stats: {
            ...state.search.stats,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS SEARCH_FILTER_POPULAR***
       */

    case c.GET_SEARCH_FILTER_POPULAR:
      return {
        ...state,
        search: {
          ...state.search,
          filterPopular: {
            ...state.search.filterPopular,
            isFetching: true,
            timeframe: action.payload.range,
          },
        },
      };
    case c.GET_SEARCH_FILTER_POPULAR_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          filterPopular: {
            ...state.search.filterPopular,
            data: action.payload,
            error: action.payload.error,
            isFetched: true,
            isFetching: false,
          },
        },
      };
    case c.GET_SEARCH_FILTER_POPULAR_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          filterPopular: {
            ...state.search.filterPopular,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_SEARCH_LOST_OPPORTUNITIES***
       */

    case c.GET_SEARCH_LOST_OPPORTUNITIES:
      return {
        ...state,
        search: {
          ...state.search,
          lostOpportunities: {
            ...state.search.lostOpportunities,
            isFetching: true,
            timeframe: action.payload.range,
          },
        },
      };
    case c.GET_SEARCH_LOST_OPPORTUNITIES_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          lostOpportunities: {
            ...state.search.lostOpportunities,
            data: action.payload.records,
            users: action.payload.users,
            queries: action.payload.queries,
            curve: action.payload.curve,
            error: action.payload.error,
            isFetched: true,
            isFetching: false,
          },
        },
      };
    case c.GET_SEARCH_LOST_OPPORTUNITIES_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          lostOpportunities: {
            ...state.search.lostOpportunities,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_SEARCH_CURVE***
       */

    case c.GET_SEARCH_CURVE:
      return {
        ...state,
        search: {
          ...state.search,
          curve: {
            ...state.search.curve,
            isFetching: true,
            timeframe: action.payload.range,
          },
        },
      };
    case c.GET_SEARCH_CURVE_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          curve: {
            ...state.search.curve,
            data: action.payload.curve,
            error: action.payload.error,
            isFetched: true,
            isFetching: false,
          },
        },
      };
    case c.GET_SEARCH_CURVE_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          curve: {
            ...state.search.curve,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_SEARCH_QUERY_POPULAR***
       */

    case c.GET_SEARCH_QUERY_POPULAR:
      return {
        ...state,
        search: {
          ...state.search,
          queryPopular: {
            ...state.search.queryPopular,
            isFetching: true,
            timeframe: action.payload.range,
          },
        },
      };
    case c.GET_SEARCH_QUERY_POPULAR_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          queryPopular: {
            ...state.search.queryPopular,
            data: action.payload.records,
            users: action.payload.users,
            queries: action.payload.queries,
            curve: action.payload.curve,
            error: action.payload.error,
            isFetched: true,
            isFetching: false,
          },
        },
      };
    case c.GET_SEARCH_QUERY_POPULAR_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          queryPopular: {
            ...state.search.queryPopular,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_SEARCH_QUERY_SORTED***
       */

    case c.GET_SEARCH_QUERY_SORTED:
      return {
        ...state,
        search: {
          ...state.search,
          querySorted: {
            ...state.search.querySorted,
            isFetching: true,
            timeframe: action.payload.range,
          },
        },
      };
    case c.GET_SEARCH_QUERY_SORTED_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          querySorted: {
            ...state.search.querySorted,
            data: action.payload,
            error: action.payload.error,
            isFetched: true,
            isFetching: false,
          },
        },
      };
    case c.GET_SEARCH_QUERY_SORTED_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          querySorted: {
            ...state.search.querySorted,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_SEARCH_QUERY_NO_RESULT***
       */

    case c.GET_SEARCH_QUERY_NO_RESULT:
      return {
        ...state,
        search: {
          ...state.search,
          queryNoResult: {
            ...state.search.queryNoResult,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_SEARCH_QUERY_NO_RESULT_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          queryNoResult: {
            ...state.search.queryNoResult,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            users: action.payload.users,
            queries: action.payload.queries,
            curve: action.payload.curve,
            error: action.payload.error,
          },
        },
      };
    case c.GET_SEARCH_QUERY_NO_RESULT_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          queryNoResult: {
            ...state.search.queryNoResult,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };

      /**
       ***  SEARCH ANALYTICS GET_SEARCH_RELATED_QUERIES***
       */

    case c.GET_SEARCH_RELATED_QUERIES:
      return {
        ...state,
        search: {
          ...state.search,
          relatedQueries: {
            ...state.search.relatedQueries,
            timeframe: action.payload.range,
            query: action.payload.query,
            isFetching: true,
          },
        },
      };
    case c.GET_SEARCH_RELATED_QUERIES_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          relatedQueries: {
            ...state.search.relatedQueries,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_SEARCH_RELATED_QUERIES_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          relatedQueries: {
            ...state.search.relatedQueries,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_PRODUCTS_CONVERSION_BEST
       */

    case c.GET_PRODUCTS_CONVERSION_BEST:
      return {
        ...state,
        products: {
          ...state.products,
          conversionBest: {
            ...state.products.conversionBest,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_PRODUCTS_CONVERSION_BEST_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          conversionBest: {
            ...state.products.conversionBest,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_PRODUCTS_CONVERSION_BEST_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          conversionBest: {
            ...state.products.conversionBest,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_PRODUCTS_CONVERSION_WORST
       */

    case c.GET_PRODUCTS_CONVERSION_WORST:
      return {
        ...state,
        products: {
          ...state.products,
          conversionWorst: {
            ...state.products.conversionWorst,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_PRODUCTS_CONVERSION_WORST_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          conversionWorst: {
            ...state.products.conversionWorst,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_PRODUCTS_CONVERSION_WORST_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          conversionWorst: {
            ...state.products.conversionWorst,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_PRODUCTS_POPULAR_BY_VIEW
       */

    case c.GET_PRODUCTS_POPULAR_BY_VIEW:
      return {
        ...state,
        products: {
          ...state.products,
          popularByView: {
            ...state.products.popularByView,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_PRODUCTS_POPULAR_BY_VIEW_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          popularByView: {
            ...state.products.popularByView,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_PRODUCTS_POPULAR_BY_VIEW_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          popularByView: {
            ...state.products.popularByView,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };

      /**
       ***  SEARCH ANALYTICS GET_PRODUCTS_POPULAR_BY_PURCHASE
       */

    case c.GET_PRODUCTS_POPULAR_BY_PURCHASE:
      return {
        ...state,
        products: {
          ...state.products,
          popularByPurchase: {
            ...state.products.popularByPurchase,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_PRODUCTS_POPULAR_BY_PURCHASE_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          popularByPurchase: {
            ...state.products.popularByPurchase,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_PRODUCTS_POPULAR_BY_PURCHASE_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          popularByPurchase: {
            ...state.products.popularByPurchase,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  SEARCH ANALYTICS GET_PRODUCTS_RELATED_BY_VIEW
       */

    case c.GET_PRODUCTS_RELATED_BY_VIEW:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByView: {
            ...state.products.relatedByView,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_PRODUCTS_RELATED_BY_VIEW_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByView: {
            ...state.products.relatedByView,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_PRODUCTS_RELATED_BY_VIEW_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByView: {
            ...state.products.relatedByView,
            isFetching: false,
            error: action.payload.message,
          },
        },
      };

      /**
       ***  SEARCH ANALYTICS GET_PRODUCTS_RELATED_BY_VIEW
       */

    case c.GET_PRODUCTS_RELATED_BY_PURCHASE:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByPurchase: {
            ...state.products.relatedByPurchase,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_PRODUCTS_RELATED_BY_PURCHASE_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByPurchase: {
            ...state.products.relatedByPurchase,
            isFetched: true,
            isFetching: false,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_PRODUCTS_RELATED_BY_PURCHASE_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByPurchase: {
            ...state.products.relatedByPurchase,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };

      /**
       ***  SEARCH ANALYTICS GET_RELATED_QUERIES_BY_PRODUCT
       */

    case c.GET_RELATED_QUERIES_BY_PRODUCT:
      return {
        ...state,
        search: {
          ...state.search,
          relatedQueriesByProduct: {
            ...state.search.relatedQueriesByProduct,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_RELATED_QUERIES_BY_PRODUCT_COMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          relatedQueriesByProduct: {
            ...state.search.relatedQueriesByProduct,
            isFetching: false,
            isFetched: true,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_RELATED_QUERIES_BY_PRODUCT_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          relatedQueriesByProduct: {
            ...state.search.relatedQueriesByProduct,
            error: action.payload.message,
            isFetching: false,
          },
        },
      };


      /**
       ***  STORE ANALYTICS GET_RELATED_PRODUCTS_BY_QUERY
       */

    case c.GET_RELATED_PRODUCTS_BY_QUERY:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByQuery: {
            ...state.products.relatedByQuery,
            timeframe: action.payload.range,
            isFetching: true,
          },
        },
      };
    case c.GET_RELATED_PRODUCTS_BY_QUERY_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByQuery: {
            ...state.products.relatedByQuery,
            isFetching: false,
            isFetched: true,
            data: action.payload.records,
            error: action.payload.error,
          },
        },
      };
    case c.GET_RELATED_PRODUCTS_BY_QUERY_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          relatedByQuery: {
            ...state.products.relatedByQuery,
            isFetching: false,
            error: action.payload.message,
          },
        },
      };

    default:
      return state;
  }
};
