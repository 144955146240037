import React from 'react';
import { useSelector } from 'react-redux';
import AppLayouts from './AppLayouts';
import AppRouter from '../../app/routes/Router';

const AppLayout = () => {
  const horizontalNavPosition = useSelector(({ app }) => app.horizontalNavPosition);
  const navigationStyle = useSelector(({ app }) => app.navigationStyle);
  const onGetLayout = (layout) => {
    switch (layout) {
      case 'inside_the_header':
        return 'InsideHeaderNav';

      case 'above_the_header':
        return 'AboveHeaderNav';

      case 'below_the_header':
        return 'BelowHeaderNav';
      default:
        return 'Vertical';
    }
  };

  const Layout = AppLayouts[
    navigationStyle === 'vertical_navigation'
      ? 'Vertical'
      : onGetLayout(horizontalNavPosition)
  ];

  return (
    <Layout>
      <AppRouter />
    </Layout>
  );
};

export default AppLayout;
