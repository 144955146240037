/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import RTL from 'util/RTL';
import { getToken } from 'util/authUtils';
import asyncComponent from 'util/asyncComponent';
import greenTheme from './themes/greenTheme';
import SignIn from './SignIn';
import * as actions from '../actions';
import AppLayout from './AppLayout';
import getColorTheme from '../util/getColorTheme';
import { changeShop } from '../actions';
import { getInitUrlByShop, getShopIdFromLocation } from '../util/appUtils';
import enLang from '../lngProvider/entries/en-US';
import AlertBanner from './AlertBanner';

const App = (props) => {
  const dispatch = useDispatch();
  const { app, auth } = useSelector((store) => store);
  const { themeColor, darkTheme } = app;
  const { initURL, authorization } = auth;
  const { match, location, history } = props;
  const isDarkTheme = darkTheme;

  const shopToken = getShopIdFromLocation();
  useEffect(() => {
    if (shopToken) dispatch(changeShop(shopToken));
  }, [shopToken, dispatch]);

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') dispatch(actions.setInitUrl(history.location.pathname));
  }, [initURL]);

  let applyTheme = createTheme(greenTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }

  const sessionToken = getToken();

  useEffect(() => {
    if (sessionToken) dispatch(actions.getAuthorization());
  }, [sessionToken]);

  if (location.pathname === '/') {
    if (!authorization.isFetched) {
      return (<Redirect to="/signin" />);
    }
    if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return (<Redirect to="/app" />);
    }
    return (<Redirect to={initURL} />);
  }

  if (['/signin', '/signin/embedded'].includes(location.pathname) && sessionToken) {
    return (<Redirect to="/app" />);
  }

  if (location.pathname === '/app' && authorization.isFetched) {
    if (authorization.shopList.length) {
      const shopConfig = authorization.shopList[0];
      const initUrlForShop = getInitUrlByShop(shopConfig);
      return <Redirect to={initUrlForShop} />;
    }
  }

  return (
    <ThemeProvider theme={{ ...applyTheme, direction: 'ltr' }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider locale={enLang.locale} messages={enLang.messages}>
          <RTL>
            <AlertBanner location={location} />
            <div className="app-main">
              <Switch>
                <RestrictedRoute
                  path={`${match.url}app`}
                  sessionToken={sessionToken}
                  component={AppLayout}
                />
                <Route path="/signin" component={SignIn} />
                {/* <Route path="/signup" component={SignUp} /> */}
                <Route
                  component={asyncComponent(() => import('app/routes/404'))}
                />
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;

const RestrictedRoute = ({ component: Component, sessionToken, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      sessionToken
        ? <Component {...props} />
        : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        ))}
  />
);
