/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainer from '../../../../../components/kea/CardContainer';
import { linkToQueryDetail } from '../../../../../util/appUtils';

const MainSection = ({ lostOpportunities, currentShop }) => {
  const { isFetching, data, error } = lostOpportunities;

  const headCells = [
    { id: 'query', numeric: false, label: 'Query' },
    { id: 'users', numeric: true, label: 'Users' },
    { id: 'total', numeric: true, label: 'Total' },
  ];

  const prepareRows = (rows) => rows.map((e, i) => ({
    ...e,
    id: `${e.query}-${i}`,
    query: linkToQueryDetail(currentShop, e.query),
  }));

  return (
    <CardContainer
      error={error}
      isFetching={isFetching}
      headerId="search.analytics.lostOpportunities.header"
      subheaderId="search.analytics.lostOpportunities.subheader"
      styleName="min-height-700px noMargin"
    >
      <AnalyticTable
        rows={prepareRows(data)}
        headCells={headCells}
        orderById="total"
      />
    </CardContainer>
  );
};


MainSection.propTypes = {
  lostOpportunities: PropTypes.shape({
    error: PropTypes.any,
    isFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.array,
  }).isRequired,
  currentShop: PropTypes.string.isRequired,
};

export default MainSection;
