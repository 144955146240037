import React from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from '../Slider/Slider';

const FieldPrioritySetting = ({
  value, title, description, onChange, id,
}) => {
  const priorities = ['NEGATIVE', 'DECREASE', 'IGNORE', 'LOW', 'NORMAL', 'BOOST'];

  return (
    <div className="setting-item-container">
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Grid item xs={4}>
          <div className="setting-item-title">{title}</div>
          <div className="setting-item-description">{description && description}</div>
        </Grid>

        <Grid container item xs={8} justify="center">
          <Grid item xs={3} className="field-weight-title">
            {value}
          </Grid>
          <Grid item xs={9}>
            <Slider
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              value={priorities.indexOf(value) || 0}
              onChange={(e, index) => onChange(id, priorities[index])}
              min={0}
              max={priorities.length - 1}
              step={1}
              valueLabelFormat={(index) => priorities[index]}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FieldPrioritySetting;
