import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import React from 'react';
import CustomTooltip from '../../common/CustomToolTIp';
import { chartColors } from 'util/chartUtils';

const PreparedChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={71}>
    <LineChart
      data={[{ pv: 0 }, ...data]}
      margin={{ top: 5, right: 5, left: 3, bottom: 5 }}
    >
      <Line dataKey="pv" stroke={chartColors.main.normal} strokeWidth={2} />
      <Tooltip content={<CustomTooltip dataKey="pv" />} />
    </LineChart>
  </ResponsiveContainer>
);

export default PreparedChart;
