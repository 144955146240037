/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import ContainerHeader from 'components/ContainerHeader';
import { changeTimeframe, getRecsDashboard } from 'actions';
import DateRangePicker from 'components/kea/DateRangePicker';
import IntlMessages from 'util/IntlMessages';
import { matchDatesDashboardCase } from 'util/appUtils';
import Summary from './Summary';
import Audience from './Audience';
import CartProducts from './CartProducts';
import Conversion from './Conversion';
import AdditionalProfit from './AdditionalProfit';
import AverageOrder from './AOV';
import AverageViews from './AverageViews';
import Profitability from './Profitability';

const Dashboard = ({ match }) => {
  const dispatch = useDispatch();
  const {
    analytics: { recs, timeframe },
    app: { currentShop },
  } = useSelector((store) => store);
  const { dashboard: { isFetching, projection, meta } } = recs;

  useEffect(() => {
    const dataIsUnavailable = !isFetching && currentShop;
    const datesIsMatched = meta && matchDatesDashboardCase(meta, timeframe);
    if (dataIsUnavailable || !datesIsMatched) dispatch(getRecsDashboard(currentShop, timeframe));
  }, [timeframe, currentShop]);

  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));

  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  if (isFetching || !projection) return <PlaceholderSpinner />;

  return (
    <div className="app-wrapper dashboard animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={<IntlMessages id="recs.dashboard.header" />}
      />

      <div className="row">
        <Summary
          summary={projection.summary}
        />
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Audience
            audience={projection.audience}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <CartProducts
            withProducts={projection.withProducts}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Conversion
            conversion={projection.conversion}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <AdditionalProfit
            money={projection.money}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <AverageOrder
            aov={projection.aov}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <AverageViews
            averageViews={projection.averageViews}
          />
        </div>
      </div>


      <div className="row">
        <div className="col-12">
          <Profitability
            chart={projection.chart}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
