import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { chartColors } from 'util/chartUtils';
import DashboardStub from '../../../../components/kea/DashboardStub';
import CardBox from '../../../../components/kea/CardBox';

function CartProducts({ withProducts }) {
  const options = {
    legend: {
      display: false,
      position: 'bottom',
    },
    textYIndex: 2,
  };

  const { main, different, gray } = chartColors;
  const data = {
    labels: [
      'Low quality traffic (non-interested or occasional visitors, bots, crawlers etc.)',
      'Did\'nt use search',
      'Used search',
    ],
    datasets: [{
      data: [
        withProducts.noRecs,
        withProducts.recs,
      ],
      backgroundColor: [
        gray.normal,
        different.normal,
        main.normal,
      ],
      hoverBackgroundColor: [
        gray.dark,
        different.dark,
        main.dark,
      ],
    }],
    text: `${withProducts.recsPercentage}%`,
  };

  return (
    <CardBox header="Carts with products">
      {
        (withProducts.error)
          ? (<DashboardStub text={`Receive error - ${withProducts.error}`} />)
          : (
            <div>
              <div className="row">
                <div className="col-sm-6 donutSegment">
                  <Doughnut
                    height={230}
                    data={data}
                    options={options}
                  />
                </div>
                <div className="col-sm-6">
                  <p>
                    {withProducts.recsPercentage}
                    % of visitors with products in cart used recommendations
                    <br />
                  </p>
                </div>
              </div>
            </div>
          )
            }
    </CardBox>
  );
}

CartProducts.propTypes = {
  withProducts: PropTypes.shape({
    recsPercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    noRecs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.any,
  }).isRequired,
};

export default CartProducts;
