import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { chartColors } from 'util/chartUtils';
import CardBox from '../../../../components/kea/CardBox';
import DashboardStub from '../../../../components/kea/DashboardStub';

function AverageViews({ averageViews }) {
  const chartOptions = {
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [{
        stacked: false,
      }],
      yAxes: [{
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  };

  function prepareData(data) {
    const { main, different } = chartColors;
    return {
      labels: ['Page views'],
      datasets: [
        {
          label: 'Didn\'t use recommendations',
          backgroundColor: different.normal,
          data: [data.noRecs],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        }, {
          label: 'Used recommendations',
          backgroundColor: main.normal,
          data: [data.recs],
          categoryPercentage: 0.9,
          barPercentage: 0.9,
        },
      ],
    };
  }

  if (averageViews.error) {
    return (
      <CardBox header="Average Page Views">
        <DashboardStub />
      </CardBox>
    );
  }
  const chartData = prepareData(averageViews);


  return (
    <CardBox header="Average Page Views">
      <div className="row">
        <div className="col-6">
          <Bar data={chartData} options={chartOptions} height={240} />
        </div>
        <div className="col-6">
          <p>
            Visitors who used recommendations view on {averageViews.rate}% more products in average.
          </p>
        </div>
      </div>

    </CardBox>
  );
}

AverageViews.propTypes = {
  averageViews: PropTypes.shape({
    recs: PropTypes.any,
    noRecs: PropTypes.any,
    rate: PropTypes.any,

    error: PropTypes.any,
  }).isRequired,
};

export default AverageViews;
