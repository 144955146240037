/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import WidgetTable from 'components/kea/tables/WidgetTable';
import { linkToQueryDetail } from 'util/appUtils';
import CardContainerSmall from '../../CardContainerSmall';

const BriefLostOpportunities = ({ match, lostOpportunities, currentShop }) => {
  const { isFetching, data, error } = lostOpportunities;
  const headCells = [
    { id: '#', numeric: false, label: '#' },
    { id: 'query', numeric: false, label: 'Query' },
    { id: 'total', numeric: true, label: 'Total' },
  ];

  const sortDesc = (a, b) => b.total - a.total;
  const attachLinkToArray = (e) => ({ ...e, query: linkToQueryDetail(currentShop, e.query) });
  const prepareRows = (rows) => {
    if (Array.isArray(rows)) {
      return data
        .sort(sortDesc)
        .slice(0, 7)
        .map(attachLinkToArray);
    }
    return [];
  };

  return (
    <CardContainerSmall
      error={error}
      isFetching={isFetching}
      headerId="search.analytics.lostOpportunities.header"
      showMoreUrl={`${match.url}/lost-opportunities`}
    >
      <WidgetTable
        hidePagination
        rows={prepareRows(data)}
        headCells={headCells}
      />
    </CardContainerSmall>
  );
};

export default BriefLostOpportunities;
