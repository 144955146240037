/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { keaSignInWithSecret } from 'actions/Auth';
import { setStartMode } from '../../util/appUtils';

const SignInEmbedded = ({ match }) => {
  const dispatch = useDispatch();
  const submitLogin = (domain, secret, appId) => dispatch(keaSignInWithSecret(domain, secret, appId));

  useEffect(() => {
    const { secret, domain, app } = match.params;
    if (secret && domain) {
      setStartMode('iframe');
      submitLogin(domain, secret, app);
    }
  }, []);

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
    >
      <div className="app-login-main-content-embedded" />
      <div className="loader-view">
        <CircularProgress />
      </div>
    </div>
  );
};


export default SignInEmbedded;
