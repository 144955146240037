import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import DateRangePicker from 'components/kea/DateRangePicker';
import { changeTimeframe } from 'actions';
import SafetyRoute from 'components/kea/SafetyRoute';
import asyncComponent from '../../../../util/asyncComponent';
import LostOpportunities from './LostOpportunities';
import QueryPopular from './QueryPopular';
import QueryNoResult from './QueryNoResult';
import Dashboard from '../dashboard';
import QueryDetail from './QueryDetail';


const Reports = ({ match }) => {
  const dispatch = useDispatch();
  const { analytics: { timeframe } } = useSelector((store) => store);

  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));

  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  return (
    <div className="app-wrapper-full-width animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={<IntlMessages id="search.analytics" />}
      />

      <Switch>
        <SafetyRoute path={`${match.url}/query-popular`} render={() => <QueryPopular match={match} />} />
        <SafetyRoute path={`${match.url}/lost-opportunities`} render={() => <LostOpportunities match={match} />} />
        <SafetyRoute path={`${match.url}/query-no-result`} render={() => <QueryNoResult match={match} />} />

        <SafetyRoute path={`${match.url}/dashboard`} render={() => <Dashboard />} />
        {/* <SafetyRoute path={`${match.url}/filter-popular`} render={() => <FilterPopular />} /> */}
        {/* <SafetyRoute path={`${match.url}/curve`} render={() => <Curve />} /> */}
        {/* <SafetyRoute path={`${match.url}/query-sorted`} render={() => <QuerySorted />} /> */}

        <SafetyRoute path={`${match.url}/query/:query`} render={(props) => <QueryDetail {...props} />} />


        <Route component={asyncComponent(() => import('app/routes/404'))} />
      </Switch>
    </div>
  );
};
export default Reports;
