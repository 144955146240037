/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { keaSignIn } from 'actions/Auth';
import { Link } from 'react-router-dom';

const SignInPage = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const submitLogin = () => {
    dispatch(keaSignIn({
      email: emailInputRef.current.value,
      password: passwordInputRef.current.value,
    }));
  };

  const onKeyDown = useCallback((event) => {
    if (event.keyCode === 13) submitLogin();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    emailInputRef.current.focus();

    return () => document.removeEventListener('keydown', onKeyDown, false);
  }, []);

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
    >
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <img src={require('assets/images/kealogo.svg')} alt="kea_logo" />
        </div>
        <div className="app-login-content">
          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  inputRef={emailInputRef}
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  inputRef={passwordInputRef}
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    style={{ width: '100%' }}
                    onClick={submitLogin}
                    variant="contained"
                    color="primary"
                  >
                    <IntlMessages id="appModule.signIn" />
                  </Button>
                </div>
                <Link to="/signin/forgot-password">
                  <IntlMessages id="appModule.forgotPassword" />
                </Link>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {
        loader
        && (
        <div className="loader-view">
          <CircularProgress />
        </div>
        )
      }
      {showMessage && NotificationManager.error(alertMessage)}
      <NotificationContainer />
    </div>
  );
};


export default SignInPage;
