import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { chartColors } from 'util/chartUtils';
import CardBox from 'components/kea/CardBox';
import DashboardStub from 'components/kea/DashboardStub';

function Audience({ audience }) {
  const options = {
    legend: {
      display: false,
      position: 'bottom',
    },
    textYIndex: 2,
  };

  const { main, gray, different } = chartColors;
  const data = {
    labels: [
      'Low quality traffic (non-interested or occasional visitors, bots, crawlers etc.)',
      'Didn\'t use recommendations',
      'Used recommendations',
    ],
    datasets: [{
      data: [
        audience.bad,
        audience.noRecs,
        audience.recs,
      ],
      backgroundColor: [
        gray.normal,
        different.normal,
        main.normal,
      ],
      hoverBackgroundColor: [
        gray.dark,
        different.dark,
        main.dark,
      ],
    }],
    text: `${audience.recsPercentage}%`,
  };

  return (
    <CardBox header="Audience">
      {
        (audience.error) ? (<DashboardStub />)
          : (
            <div>
              <div className="row">
                <div className="col-12">
                  <p>{`${audience.recsPercentage} % of interested visitors used recommendations`}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Doughnut
                    height={200}
                    data={data}
                    options={options}
                  />
                </div>
                <div className="col-sm-6">
                  <table className="chartTable">
                    <tbody>
                      <tr>
                        <td>
                          <p>Total number of unique visitors</p>
                        </td>
                        <td>
                          <p className="textRight">{audience.total}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Got interested in the store
                            <sup>*</sup>
                            <br />
                            <sup>*</sup>
                            viewed at least 2 products
                          </p>
                        </td>
                        <td>
                          <p className="textRight">{audience.interested}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Number of interested visitors who used recommendations</p>
                        </td>
                        <td>
                          <p className="textRight">{audience.recs}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
    }
    </CardBox>

  );
}

Audience.propTypes = {
  audience: PropTypes.shape({
    recsPercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    noRecs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    interested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.any,
  }).isRequired,
};

export default Audience;
