import React from 'react';
import {
  ResponsiveContainer, PieChart, Pie, Cell, Tooltip,
} from 'recharts';
import Widget from 'components/Widget/index';

const WidgetRainbow = ({ title, mainNumber, description, pieData }) => (
  <Widget styleName="jr-card-full noMargin">
    <div className="row">
      <div className="col-xl-5 col-md-12 col-sm-12">
        <div className="d-flex flex-row px-3 pt-4">
          <p className="text-uppercase">{title}</p>
        </div>
        <div className="jr-actchart pb-3 pl-3">
          <h2 className="jr-fs-2xl jr-font-weight-bold mb-1">{mainNumber}</h2>
          <p className="mb-0 text-grey">{description}</p>
        </div>
      </div>
      <div className="col-xl-7 col-md-12 col-sm-12 ">
        <ResponsiveContainer className="rounded-bottom-right" height={126} width="100%">
          <PieChart>
            <Pie
              data={pieData}
              cy={122}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              {
                pieData.map((e) => (<Cell key={e.id} fill={e.fill} />))
              }
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  </Widget>
);


export default WidgetRainbow;
