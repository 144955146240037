import { createSlice, createAction } from '@reduxjs/toolkit';
import {
  put, select, takeLatest,
} from 'redux-saga/effects';
import {
  changeSearchOnboardingSettings,
  changeRecsOnboardingSettings,
  saveRecsSettings,
  saveSearchSettings,
  updateSearchVisible, updateRecsVisible, updatePreview,
} from '../actions';

const initialState = {
  search: {
    completed: true,
  },
  recs: {
    industries: [],
    fields: [],
    audienceSize: null,
    completed: true,
    wizardCompleted: true,
  },
};

const sliceName = 'onboarding';

export const onboardingSearchPreview = createAction(`${sliceName}/onboardingSearchPreview`);
export const onboardingSearchCustomize = createAction(`${sliceName}/onboardingSearchCustomize`);
export const onboardingSearchActivate = createAction(`${sliceName}/onboardingSearchActivate`);
export const onboardingRecsWizardComplete = createAction(`${sliceName}/onboardingRecsWizardComplete`);
export const onboardingRecsPreview = createAction(`${sliceName}/onboardingRecsPreview`);
export const onboardingRecsCustomize = createAction(`${sliceName}/onboardingRecsCustomize`);
export const onboardingRecsActivate = createAction(`${sliceName}/onboardingRecsActivate`);

const orderSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    onboardingRecsChange(state, { payload }) {
      return {
        ...state,
        recs: {
          ...state.recs,
          ...payload,
        },

      };
    },
    onboardingSearchChange(state, { payload }) {
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    },
    onboardingChange(state, { payload }) {
      return {
        recs: {
          ...state.recs,
          ...payload.recs,
        },
        search: {
          ...state.search,
          ...payload.search,
        },
      };
    },
  },
});

export const { actions, reducer } = orderSlice;
export const {
  onboardingChange,
  onboardingRecsChange,
  onboardingSearchChange,
} = actions;
export default reducer;

function* searchPreview() {
  try {
    const { shopAddress } = yield select((state) => state.settings);
    const prefix = shopAddress.startsWith('http') ? '' : 'https://';
    window.open(`${prefix}${shopAddress}?kea-preview=true`, '_blank');
    const changes = { step1: true };
    yield put(onboardingSearchChange(changes));
    yield put(changeSearchOnboardingSettings(changes));
    yield put(updateSearchVisible(false));
  } catch (err) {
    console.log('Failed in onboardingPreview', err);
  }
}

function* searchCustomize() {
  try {
    const changes = { step2: true };
    yield put(onboardingSearchChange(changes));
    yield put(changeSearchOnboardingSettings(changes));
  } catch (err) {
    console.log('Failed in onboardingCustomize', err);
  }
}

function* searchActivate() {
  try {
    const { shopId } = yield select((state) => state.settings);
    const { searchVisible } = yield select((state) => state.settings);
    if (!searchVisible) yield put({ type: 'SEARCH_VISIBLE_TOGGLE' });
    const changes = { step3: true, completed: true };
    yield put(onboardingSearchChange(changes));
    yield put(changeSearchOnboardingSettings(changes));

    yield put(updateSearchVisible(true));
    yield put(updatePreview(false));
    yield put(saveSearchSettings(shopId));
  } catch (err) {
    console.log('Failed in onboardingActivate', err);
  }
}

function* recsWizardComplete() {
  try {
    const changes = { wizardCompleted: true };
    yield put(onboardingRecsChange(changes));

    const { shopId } = yield select((state) => state.settings);
    const payload = yield select((state) => state.onboarding.recs);
    yield put(changeRecsOnboardingSettings(payload));
    yield put(saveRecsSettings(shopId));
  } catch (err) {
    console.log('Failed in recsWizardComplete', err);
  }
}

// todo clean up and refactor. mb. some day it will happen. or not :)
function* recsPreview() {
  try {
    const { shopAddress } = yield select((state) => state.settings);
    const prefix = shopAddress.startsWith('http') ? '' : 'https://';
    window.open(`${prefix}${shopAddress}?kea-preview=true`, '_blank');
    const changes = { step1: true };
    yield put(onboardingRecsChange(changes));
    yield put(changeRecsOnboardingSettings(changes));
    yield put(updateSearchVisible(false));
  } catch (err) {
    console.log('Failed in onboardingPreview', err);
  }
}

function* recsCustomize() {
  try {
    const changes = { step2: true };
    yield put(onboardingRecsChange(changes));
    yield put(changeRecsOnboardingSettings(changes));
  } catch (err) {
    console.log('Failed in onboardingCustomize', err);
  }
}

function* recsActivate() {
  try {
    const { shopId } = yield select((state) => state.settings);
    const { searchVisible } = yield select((state) => state.settings);
    if (!searchVisible) yield put({ type: 'SEARCH_VISIBLE_TOGGLE' });
    const changes = { step3: true, completed: true };
    yield put(onboardingRecsChange(changes));
    yield put(changeRecsOnboardingSettings(changes));

    yield put(updateRecsVisible(true));
    yield put(updatePreview(false));
    yield put(saveRecsSettings(shopId));
  } catch (err) {
    console.log('Failed in onboardingActivate', err);
  }
}


export function* sagas() {
  yield takeLatest(onboardingSearchPreview().type, searchPreview);
  yield takeLatest(onboardingSearchCustomize().type, searchCustomize);
  yield takeLatest(onboardingSearchActivate().type, searchActivate);
  yield takeLatest(onboardingRecsWizardComplete().type, recsWizardComplete);
  yield takeLatest(onboardingRecsPreview().type, recsPreview);
  yield takeLatest(onboardingRecsCustomize().type, recsCustomize);
  yield takeLatest(onboardingRecsActivate().type, recsActivate);
}
