/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import BriefLostOpportunities from 'components/kea/widgets/BriefLostOpportunities';
import AffectedUsers from 'components/kea/widgets/noResultQueries/AffectedUsers';
import QueriesWithoutResults from 'components/kea/widgets/noResultQueries/QueriesWithoutResults';
import { getAnalyticLostOpportunities, getAnalyticQueryNoResult } from 'actions';
import MainSection from './MainSection';

const QueryNoResult = ({ match }) => {
  const dispatch = useDispatch();
  const {
    analytics: { search, timeframe },
    app: { currentShop },
  } = useSelector((store) => store);
  const { queryNoResult, lostOpportunities } = search;

  useEffect(() => {
    dispatch(getAnalyticLostOpportunities(currentShop, timeframe));
    dispatch(getAnalyticQueryNoResult(currentShop, timeframe));
  }, [timeframe]);

  return (
    <Grid>
      <Grid spacing={4} container>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <MainSection
            queryNoResult={queryNoResult}
            currentShop={currentShop}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Grid container spacing={4}>

            <Grid item xs={12} sm={6} md={6} lg={12}>
              <AffectedUsers queryNoResult={queryNoResult} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <QueriesWithoutResults queryNoResult={queryNoResult} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <BriefLostOpportunities
                lostOpportunities={lostOpportunities}
                match={match}
                currentShop={currentShop}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} />
      </Grid>
    </Grid>
  );
};

export default QueryNoResult;
