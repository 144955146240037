import { createAction } from '@reduxjs/toolkit';
import * as c from '../constants/actionTypes/settings';

export const getSettings = (token) => ({
  type: c.GET_ALL_SETTINGS,
  payload: token,
});

export const getSettingDescribes = (token, appId) => ({
  type: c.GET_DESCRIBES,
  payload: { token, appId },
});

export const getAlerts = (token, appId) => ({
  type: c.GET_ALERTS,
  payload: { token, appId },
});

export const saveSearchSettings = (token) => ({ type: c.SAVE_SEARCH_SETTINGS, payload: token });
export const saveRecsSettings = (token) => ({ type: c.SAVE_RECS_SETTINGS, payload: token });

export const resetSearchSettings = () => ({ type: c.RESET_SEARCH_SETTINGS_TO_DEFAULT });
export const resetRecsSettings = () => ({ type: c.RESET_RECS_SETTINGS_TO_DEFAULT });

export const forceUpdateSearchVisible = (token, isVisible) => ({
  type: c.FORCE_UPDATE_SEARCH_VISIBLE,
  payload: { token, isVisible },
});

export const forceUpdateRecsVisible = (token, isVisible) => ({
  type: c.FORCE_UPDATE_RECS_VISIBLE,
  payload: { token, isVisible },
});

export const updateSearchVisible = (isVisible) => ({
  type: c.UPDATE_SEARCH_VISIBLE,
  payload: isVisible,
});

export const updateRecsVisible = (isVisible) => ({
  type: c.UPDATE_RECS_VISIBLE,
  payload: isVisible,
});

export const updatePreview = createAction('settings/UPDATE_PREVIEW');

export const changeSearchGeneralSetting = (key, value) => ({
  type: c.CHANGE_SEARCH_GENERAL_SETTING,
  payload: { key, value },
});

export const changeSearchResultSetting = (key, value) => ({
  type: c.CHANGE_SEARCH_RESULT_SETTING,
  payload: { key, value },
});

export const changeSearchBoxSetting = (key, value) => ({
  type: c.CHANGE_SEARCH_BOX_SETTING,
  payload: { key, value },
});

export const changeSearchThemeSetting = (key, value) => ({
  type: c.CHANGE_SEARCH_THEME_SETTING,
  payload: { key, value },
});

export const changeSearchFacetsSetting = (key, value) => ({
  type: c.CHANGE_SEARCH_FACETS_SETTING,
  payload: { key, value },
});

export const changeRecsThemeSetting = (key, value) => ({
  type: c.CHANGE_RECS_THEME_SETTING,
  payload: { key, value },
});

export const rewriteAlertBanners = (updatedBanners) => ({
  type: c.REWRITE_ALERT_BANNERS,
  payload: updatedBanners,
});

export const updateAlertBanners = (banners) => ({
  type: c.UPDATE_ALERT_BANNERS,
  payload: banners,
});
