import withStyles from '@material-ui/core/styles/withStyles';
import { Slider } from '@material-ui/core';

const boxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export default withStyles({
  root: {
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover': {
      boxShadow,
      '@media (hover: none)': {
        boxShadow,
      },
    },
  },
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 5,
  },
  rail: {
    height: 4,
    opacity: 0.5,
  },
  mark: {
    height: 0,
    width: 0,
  },
  markLabel: {
    top: 7,
  },
  markLabelActive: {
    top: 7,
  },
  markActive: {
    height: 0,
    width: 0,
  },
})(Slider);
