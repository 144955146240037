import React from 'react';
import PropTypes from 'prop-types';
import WidgetWithChart from '../../common/WidgetWithChart';
import PreparedChart from './PreparedChart';

const TotalQueries = ({ queryPopular }) => {
  const { isFetched, isFetching, curve, queries, error } = queryPopular;
  const dataIsReady = isFetching || !isFetched;

  const chart = error ? null : <PreparedChart data={curve && curve.data} />;
  const mainNumber = error || !(queries && queries.total)
    ? '-'
    : queries.total;

  return (
    <WidgetWithChart
      isFetching={dataIsReady}
      title="Number of queries"
      mainNumber={mainNumber}
      description="Total number of search sessions"
      chart={chart}
    />
  );
};

TotalQueries.propTypes = {
  queryPopular: PropTypes.shape({
    isFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    queries: PropTypes.shape({
      total: PropTypes.any,
    }),
    curve: PropTypes.any,
  }),
};


export default TotalQueries;
