/* eslint-disable no-underscore-dangle */
import moment from 'moment';

export default {
  today: {
    to: moment()._d,
    from: moment()._d,
    text: 'Today',
    id: 'today',
  },
  // yesterday: {
  //   from: moment().subtract(1, 'day')._d,
  //   to: moment().subtract(1, 'day')._d,
  //   text: 'Yesterday',
  //   id: 'yesterday',
  // },
  thisWeek: {
    from: moment().startOf('isoWeek')._d,
    to: moment()._d,
    text: 'This week',
    id: 'thisWeek',
  },
  previousWeek: {
    from: moment().subtract(1, 'weeks').startOf('isoWeek')._d,
    to: moment().subtract(1, 'weeks').endOf('isoWeek')._d,
    text: 'Prev. week',
    id: 'previousWeek',
  },
  thisMonth: {
    from: moment().startOf('month')._d,
    to: moment()._d,
    text: 'This month',
    id: 'thisMonth',
  },
  previousMonth: {
    from: moment().subtract(1, 'months').startOf('month')._d,
    to: moment().subtract(1, 'months').endOf('month')._d,
    text: 'Prev. month',
    id: 'previousMonth',
  },
  last7days: {
    from: moment().subtract(6, 'days')._d,
    to: moment()._d,
    text: 'Last 7d.',
    id: 'last7days',
  },
  last30days: {
    from: moment().subtract(29, 'days')._d,
    to: moment()._d,
    text: 'Last 30d.',
    id: 'last30days',
  },
  last90days: {
    from: moment().subtract(89, 'days')._d,
    to: moment()._d,
    text: 'Last 90d.',
    id: 'last90days',
  },
  lastYear: {
    from: moment().subtract(1, 'year')._d,
    to: moment()._d,
    text: 'Last year.',
    id: 'lastYear',
  },
};
