import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import Card from '@material-ui/core/Card';
import * as actions from 'actions/Recs';
import { bindActionCreators } from 'redux';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import SaveActionBtnGrp from 'components/kea/settings/SaveActionBtnGrp';
import WidgetListDialog from './WidgetListDialog';
import WidgetList from './WidgetList';

class PageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedWidgets: [],
      dialogIsOpen: false,
      sectionIdForSheet: null,
    };

    this.closeWidgetList = () => {
      this.setState({
        dialogIsOpen: false,
        sectionIdForSheet: null,
        checkedWidgets: [],
      });
    };
    this.openWidgetList = (sectionIdForSheet, checked) => {
      this.setState({
        dialogIsOpen: true,
        checkedWidgets: checked,
        sectionIdForSheet,
      });
    };
  }

  onDragEnd(result, pageId) {
    const { destination, source, draggableId } = result;
    const { page: { dataMap } } = this.props.recs;
    const { sections } = dataMap[pageId];
    const sectionsMap = _.keyBy(sections, 'id');

    const srcSection = sectionsMap[source.droppableId];
    if (!destination) return;

    const dstSection = sectionsMap[destination.droppableId];

    if (dstSection.id === srcSection.id && destination.index === source.index) {
      return;
    } // On nothing changed

    if (dstSection.id === srcSection.id) {
      const widgetList = Array.from(srcSection.widgets);
      const [removed] = widgetList.splice(result.source.index, 1);
      widgetList.splice(result.destination.index, 0, removed);
      this.reOrderWidgets(pageId, srcSection.id, widgetList);
      return;
    } // On reorder widgets in the same sections

    if (dstSection.id !== srcSection.id) {
      const srcWidgets = Array.from(srcSection.widgets);
      srcWidgets.splice(source.index, 1);
      const dstWidgets = Array.from(dstSection.widgets);
      dstWidgets.splice(destination.index, 0, draggableId);

      const newSections = {
        ...sectionsMap,
        [dstSection.id]: { ...sectionsMap[dstSection.id], widgets: dstWidgets },
        [srcSection.id]: { ...sectionsMap[srcSection.id], widgets: srcWidgets },
      };

      this.props.actions.recsCustomizePageSections(pageId, _.flatMap(newSections));
    } // On move widget between sections
  }

  dropWidgetFromSection(widgetId, sectionId, pageId) {
    const { page: { dataMap } } = this.props.recs;
    const { sections } = dataMap[pageId];
    const sectionsMap = _.keyBy(sections, 'id');

    const { widgets } = sectionsMap[sectionId];
    const newWidgets = widgets.filter((wId) => wId !== widgetId);

    const newSections = {
      ...sectionsMap,
      [sectionId]: {
        ...sectionsMap[sectionId],
        widgets: newWidgets,
      },
    };

    this.props.actions.recsCustomizePageSections(pageId, _.flatMap(newSections));
  }

  reOrderWidgets(pageId, sectionId, widgets) {
    const { page: { dataMap } } = this.props.recs;
    const { sections } = dataMap[pageId];
    const sectionsMap = _.keyBy(sections, 'id');

    const newSections = {
      ...sectionsMap,
      [sectionId]: {
        ...sectionsMap[sectionId],
        widgets,
      },
    };

    this.props.actions.recsCustomizePageSections(pageId, _.flatMap(newSections));
  }

  resetChanges(pageId) {
    const { page: { dataMapDefault } } = this.props.recs;
    const newPage = dataMapDefault[pageId];
    this.props.actions.recsCustomizePageSections(pageId, newPage.sections);
  }

  render() {
    const {
      checkedWidgets, // todo calc it realtime
      dialogIsOpen,
      sectionIdForSheet,
    } = this.state;

    const { match, recs, settings } = this.props;
    const { url, params: { pageId } } = match;
    const {
      model, widget, page, algorithm,
      page: { dataMap: pagesMap, dataMapDefault },
    } = recs;

    const notFetched = !settings.isFetched
        || !model.isFetched
        || !widget.isFetched
        || !page.isFetched;

    if (notFetched) {
      return <PlaceholderSpinner />;
    }

    const currentPage = pagesMap[pageId];

    const buttons = [
      { text: 'Save', action: () => this.props.actions.recsUpdatePage(currentPage), color: 'primary' },
      { text: 'Discard', action: () => this.resetChanges(pageId), color: 'secondary' },
    ];

    return (
      <div className="app-wrapper slideInUpTiny">
        <ContainerHeader
          match={match}
          title={<IntlMessages id="recommendations.customization.pages.title" values={{ pageName: currentPage.title }} />}
          action={(
            <SaveActionBtnGrp
              isFetching={notFetched}
              buttons={buttons}
              disabled={_.isEqual(pagesMap[pageId], dataMapDefault[pageId])}
            />
          )}
        />
        <WidgetListDialog
          isOpen={dialogIsOpen}
          pageId={pageId}
          selected={checkedWidgets}
          widgetList={widget.data}
          widgetsMap={widget.dataMap}
          algorithm={algorithm}
          pagesMap={pagesMap}
          sectionId={sectionIdForSheet}
          onClose={this.closeWidgetList}
          recsCustomizePageSections={this.props.actions.recsCustomizePageSections}
        />
        <DragDropContext onDragEnd={(args) => this.onDragEnd(args, pageId)}>
          {
            currentPage
              .sections
              .map((section) => {
                const chosenWidgets = _.flatMap(currentPage.sections, (pg) => pg.widgets);
                return (
                  <Card className="jr-card" key={section.id}>
                    <div className="widget-edit-section">
                      <h3 className="widget-edit-section-title">
                        Section:
                        {section.title}
                      </h3>
                      <WidgetList
                        url={url}
                        algorithm={algorithm}
                        pageId={pageId}
                        sectionId={section.id}
                        widgets={section.widgets}
                        widgetsMap={widget.dataMap}
                        dropWidgetFromSection={(widgetId, sectionId) => this.dropWidgetFromSection(widgetId, sectionId, pageId)}
                      />
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <div
                        className="widget-item-add"
                        onClick={() => this.openWidgetList(section.id, chosenWidgets)}
                      >
                        + Add widget
                      </div>
                    </div>
                  </Card>
                );
              })
            }
        </DragDropContext>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    recs: state.recs,
    settings: state.settings,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(PageEditor);
