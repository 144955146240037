/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import WidgetWithChart from 'components/kea/widgets/common/WidgetWithChart';
import PreparedChart from './PreparedChart';

const QueriesWithoutResults = ({ queryNoResult }) => {
  const { isFetched, isFetching, curve, queries, error } = queryNoResult;
  const dataIsReady = isFetching || !isFetched;

  const chart = error ? null : <PreparedChart data={curve && curve.data} />;
  const mainNumber = error || !(queries && queries.filteredTotal)
    ? '-'
    : queries.filteredTotal;

  return (
    <WidgetWithChart
      isFetching={dataIsReady}
      title="Queries without results"
      mainNumber={mainNumber}
      description="Total number of queries without results"
      chart={chart}
    />
  );
};

export default QueriesWithoutResults;
