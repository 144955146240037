import PropTypes from 'prop-types';
import React from 'react';
import { CardContent, Card } from '@material-ui/core';
import classes from './SetupCard.module.scss';
import Subheader from '../Typography/Subheader';

export default function SetupCard({ active, title, icon, onClick }) {
  const image = `/images/industry_icons${active ? '_accent' : ''}/${icon}`;

  return (
    <Card className={`${classes.card} ${active && classes['card-active']}`} onClick={onClick}>
      {icon && <img className={classes.image} alt="industry" src={image} />}
      <CardContent className={classes.content}>
        <Subheader variant="2">{title}</Subheader>
      </CardContent>
    </Card>
  );
}

SetupCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

SetupCard.defaultProps = {
  icon: undefined,
  onClick: undefined,
  active: false,
};
