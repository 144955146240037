import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/kealogo-text-bg.svg';
import { genRandomKey, getInitUrlByShop, getShopIdFromLocation } from '../../util/appUtils';

const UserInfo = () => {
  const { auth: { authorization } } = useSelector((state) => state);

  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div className="user-profile kealabs-logo-container d-flex flex-row align-items-center">
      <Logo className="kealabs-logo" />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>
          { getShopIdFromLocation() || 'Select the shop'}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: { minWidth: 220, paddingTop: 0, paddingBottom: 0 },
        }}
      >
        {
          authorization.shopList.map((shop) => (
            <NavLink key={genRandomKey()} to={getInitUrlByShop(shop)}>
              <MenuItem onClick={() => setOpen(false)}>
                {shop.shopId}
              </MenuItem>
            </NavLink>
          ))
        }
      </Menu>
    </div>
  );
};

export default UserInfo;
