import * as c from 'constants/actionTypes/notifications';

const defaultState = {
  snackbars: [],
};


export default (state = defaultState, action) => {
  switch (action.type) {
    case c.ENQUEUE_SNACKBAR:
      return {
        ...state,
        snackbars: [
          ...state.snackbars,
          {
            key: action.key,
            ...action.payload,
          },
        ],
      };

    case c.CLOSE_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.map((snackbar) => (
          (action.dismissAll || snackbar.key === action.key)
            ? { ...snackbar, dismissed: true }
            : { ...snackbar }
        )),
      };

    case c.REMOVE_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.filter(
          (snackbar) => snackbar.key !== action.key,
        ),
      };

    default:
      return state;
  }
};
