import React from 'react';
import CircularProgress from 'components/kea/placeholders/CircularProgress';


const PlaceholderSpinner = ({ height }) => (
  <div
    className="loader-view"
    style={{ height: height || 'calc(100vh - 200px)', margin: 'auto' }}
  >
    <CircularProgress />
  </div>
);

export default PlaceholderSpinner;
