import React from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import pt from 'prop-types';

const SettingsSelectInput = ({
  title, description, onChange, value, items,
}) => {
  const changeHandler = (event) => onChange(event.target.value);

  return (
    <div className="setting-item-container">
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Grid item xs={false} sm={1} />
        <Grid item xs={7}>
          <div className="setting-item-title">{title}</div>
          <div className="setting-item-description">{description && description}</div>
        </Grid>

        <Grid container item xs={4} justify="flex-end" style={{ textAlign: 'center' }}>
          <Select
            value={value}
            style={{ width: 200 }}
            autoWidth
            onChange={changeHandler}
          >
            {
                  items.map((e) => (
                    <MenuItem
                      key={e.value}
                      value={e.value}
                    >
                      {e.text}
                    </MenuItem>
                  ))
              }
          </Select>
        </Grid>
      </Grid>
    </div>
  );
};

SettingsSelectInput.defaultProps = {
  description: null,
};

SettingsSelectInput.propTypes = {
  title: pt.oneOfType([pt.string, pt.number]).isRequired,
  value: pt.oneOfType([pt.string, pt.number, pt.bool]).isRequired,
  description: pt.oneOfType([pt.string, pt.number]),
  onChange: pt.func.isRequired,
  items: pt.arrayOf(pt.shape({
    text: pt.string,
    value: pt.any.isRequired,
  })).isRequired,
};

export default SettingsSelectInput;
