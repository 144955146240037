import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/kea/CardBox';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import IntlMessages from '../../../../../util/IntlMessages';

const Page = ({ match }) => {
  const { page } = useSelector((store) => store.recs);

  const prepareImgSrc = (id) => {
    const availableImages = ['cart', 'category', 'collections_all', 'home', 'page_404', 'product_detail'];
    const defaultImage = '_user';

    if (availableImages.includes(id)) return `/images/page_icons_svg/${id}.svg`;
    return `/images/page_icons_svg/${defaultImage}.svg`;
  };

  return (
    <div className="app-wrapper slideInUpTiny">
      <ContainerHeader
        match={match}
        title={<IntlMessages id="recommendations.customization.title" />}
      />
      <Grid container spacing={2}>
        {
            page.data.length === 0
              ? <PlaceholderSpinner />
              : page.data.map((e) => (
                <Grid key={e.id} item xs={12} sm={6} md={4}>
                  <NavLink to={`${match.url}/${e.id}`} className="fullWidth centered noTextDecoration">
                    <CardBox
                      header={e.title}
                      styleName="fullWidth"
                    >
                      <img
                        alt={`${e.id} icon`}
                        className="customization-page-icon"
                        src={prepareImgSrc(e.id)}
                      />
                    </CardBox>
                  </NavLink>
                </Grid>
              ))
          }
      </Grid>
    </div>
  );
};

export default Page;
