import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import React from 'react';
import CustomTooltip from '../../common/CustomToolTIp';

const PreparedChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={66}>
    <LineChart
      data={data}
      margin={{
        top: 5, right: 5, left: 3, bottom: 5,
      }}
    >
      <Line dataKey="total" stroke="#4caf50" strokeWidth={2} />
      <Tooltip content={<CustomTooltip />} />
    </LineChart>
  </ResponsiveContainer>
);

export default PreparedChart;
