/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainer from 'components/kea/CardContainer';
import { getPopularByPurchase } from 'actions';
import { linkToProductDetail } from '../../../../../../util/appUtils';


const PopularProductsByPurchase = () => {
  const dispatch = useDispatch();
  const { analytics: { products, timeframe }, app: { currentShop } } = useSelector((str) => str);
  const { popularByPurchase: { isFetching, data, error } } = products;

  useEffect(() => {
    dispatch(getPopularByPurchase(currentShop, timeframe));
  }, [timeframe]);

  const headCells = [
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'count', numeric: true, label: 'count' },
  ];

  const prepareRows = (rows) => rows.map((e) => ({
    ...e,
    name: linkToProductDetail(currentShop, e.name, e.id),
  }));


  return (
    <CardContainer
      headerId="search.analytics.popularItemsByPurchase.header"
      styleName="min-height-700px noMargin"
      isFetching={isFetching}
      error={error}
    >
      <AnalyticTable
        rows={prepareRows(data)}
        headCells={headCells}
      />
    </CardContainer>

  );
};

export default PopularProductsByPurchase;
