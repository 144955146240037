/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import WidgetWithChart from '../../common/WidgetWithChart';
import PreparedChart from './PreparedChart';

const TotalCTR = ({ queryPopular }) => {
  const { curve, queries, isFetched, isFetching, error } = queryPopular;
  const dataIsReady = (isFetching || !isFetched);

  const chart = error ? null : <PreparedChart data={curve && curve.data} />;
  const mainNumber = error || !(queries && queries.ctr)
    ? '-'
    : `${(queries.ctr * 100).toFixed(2)}%`;

  return (
    <WidgetWithChart
      isFetching={dataIsReady}
      description="Average Click-through Rate"
      title="Average CTR, %"
      mainNumber={mainNumber}
      chart={chart}
    />
  );
};

export default TotalCTR;
