import React from 'react';
import Grid from '@material-ui/core/Grid';
import ColorPicker from '../../ColorPicker';

const SettingColorPicker = ({
  title, description, value, onChange,
}) => (
  <div className="setting-item-container">
    <Grid
      container
      direction="row"
      alignItems="center"
    >
      <Grid item xs={false} sm={1} />
      <Grid item xs={5}>
        <div className="setting-item-title">{title}</div>
        <div className="setting-item-description">{description && description}</div>
      </Grid>

      <Grid item container xs={6} justify="flex-end" className="centered">
        <ColorPicker
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  </div>
);

export default SettingColorPicker;
