import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Slider from '../Slider/Slider';

const SearchFieldSetting = ({
  id,
  value,
  title,
  description,
  onChangeWeight,
  onChangeAutocomplete,
  autocomplete,
}) => {
  const priorities = ['IGNORE', 'NORMAL', 'MID', 'BOOSTED'];

  return (
    <div className="setting-item-container">
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Grid item xs={3}>
          <div className="setting-item-title">{title}</div>
          <div className="setting-item-description">{description && description}</div>
        </Grid>

        <Grid container item xs={6} justify="center">
          <Grid item xs={3} className="field-weight-title">
            {value}
          </Grid>
          <Grid item xs={9}>
            <Slider
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              value={priorities.indexOf(value) || 0}
              onChange={(e, index) => onChangeWeight(id, priorities[index])}
              min={0}
              max={priorities.length - 1}
              step={1}
              valueLabelFormat={(index) => priorities[index]}
            />
          </Grid>
        </Grid>

        <Grid container item xs={3} justify="flex-end">
          <Switch
            color="primary"
            checked={autocomplete}
            onChange={(event) => onChangeAutocomplete(id, event.target.checked)}
          />
        </Grid>

      </Grid>
    </div>
  );
};

export default SearchFieldSetting;
