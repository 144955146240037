import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Spinner from 'components/kea/placeholders/Spinner';

const SearchInput = ({ changeHandler, isFiltering, value }) => (
  <TextField
    onChange={changeHandler}
    value={value}
    label="Find in products...  "
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <IconButton>
            {
              isFiltering
                ? <Spinner r={15} />
                : <SearchIcon />
          }
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default SearchInput;
