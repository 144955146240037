import * as c from 'constants/actionTypes/notifications';
import { genRandomKey } from '../util/appUtils';

export const enqueueSnackbar = (snackbar) => {
  const key = snackbar.options && snackbar.options.key;

  return {
    type: c.ENQUEUE_SNACKBAR,
    payload: {
      ...snackbar,
      key: key || genRandomKey(),
    },
  };
};

export const enqueueErrorSnackbar = (message, duration = 2000) => ({
  type: c.ENQUEUE_SNACKBAR,
  payload: {
    message,
    key: message,
    options: {
      variant: 'error',
      autoHideDuration: duration,
    },
  },
});

export const enqueueWarningSnackbar = (message) => ({
  type: c.ENQUEUE_SNACKBAR,
  payload: {
    message,
    key: message,
    options: {
      variant: 'warning',
      autoHideDuration: 2000,
    },
  },
});

export const enqueueInfoSnackbar = (message) => ({
  type: c.ENQUEUE_SNACKBAR,
  payload: {
    message,
    key: message,
    options: {
      variant: 'info',
      autoHideDuration: 2000,
    },
  },
});

export const enqueueSuccessSnackbar = (message, duration = 2000) => ({
  type: c.ENQUEUE_SNACKBAR,
  payload: {
    message,
    key: message,
    options: {
      variant: 'success',
      autoHideDuration: duration,
    },
  },
});

export const closeSnackbar = (key) => ({
  type: c.CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
});

export const removeSnackbar = (key) => ({
  type: c.REMOVE_SNACKBAR,
  key,
});

/** Example how to use snackbar with close button

 const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));

 const handleClick = () => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: 'Failed fetching data.',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        action: (key) => (
          <Button onClick={() => closeSnackbar(key)}>Close</Button>
        ),
      },
    });
  };

 */
