export const GET_PLANS = 'billing/GET_PLANS';
export const GET_PLANS_COMPLETE = 'billing/GET_PLANS_COMPLETE';
export const GET_PLANS_FAILED = 'billing/GET_PLANS_FAILED';

export const ACTIVATE_PLAN = 'billing/ACTIVATE_PLAN';
export const ACTIVATE_PLAN_COMPLETE = 'billing/ACTIVATE_PLAN_COMPLETE';
export const ACTIVATE_PLAN_FAILED = 'billing/ACTIVATE_PLAN_FAILED';

export const CONFIRM_CHARGE = 'billing/CONFIRM_CHARGE';
export const CONFIRM_CHARGE_COMPLETE = 'billing/CONFIRM_CHARGE_COMPLETE';
export const CONFIRM_CHARGE_FAILED = 'billing/CONFIRM_CHARGE_FAILED';
