import React from 'react';
import { useSelector } from 'react-redux';
import SetupCard from './SetupCard';
import TextCenter from '../Typography/TextCenter';
import Header from '../Typography/Header';
import Subheader from '../Typography/Subheader';
import RealGrid from '../Layout/RealGrid';

const fields = [
  { id: 'name', title: 'Name' },
  { id: 'product_type', title: 'Product Type' },
  { id: 'category', title: 'Parent Category' },
  { id: 'tags', title: 'Tags' },
  { id: 'params', title: 'Parameters' },
  { id: 'color', title: 'Product Image (color and shape)' },
  { id: 'vendor', title: 'Vendor' },
  { id: 'model', title: 'Model' },
  { id: 'description', title: 'Description' },
  { id: 'price', title: 'Price' },
];

export default function SetupRecsFields({ onClick }) {
  const activeCards = useSelector((store) => store.onboarding.recs.fields);
  const cards = fields.map((field) => {
    const { id, title } = field;
    const active = activeCards.indexOf(id) > -1;
    return (
      <SetupCard active={active} key={id} title={title} onClick={() => onClick(id)} />
    );
  });

  return (
    <TextCenter>
      <Header variant="2">Product attributes</Header>
      <Subheader>What product attributes are the most important to your business? You can select multiple attributes.</Subheader>
      <RealGrid>{cards}</RealGrid>
    </TextCenter>
  );
}
