/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Widget from 'components/Widget/index';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import PropTypes from 'prop-types';
import WidgetRainbow from '../../common/WidgetRainbow';
import { chartColors } from 'util/chartUtils';

const AffectedUsers = ({ lostOpportunities }) => {
  const { isFetched, isFetching, users, error } = lostOpportunities;

  if (isFetching || !isFetched) {
    return (
      <Widget styleName="jr-card-full noMargin">
        <PlaceholderSpinner height={129} />
      </Widget>
    );
  }

  const mainNumber = error || !(users && users.filteredTotal)
    ? '-'
    : users.filteredTotal;
  const pieData = [
    { id: '1', name: 'Affected users', fill: chartColors.main.normal, value: users ? users.filteredTotal : '-' },
    { id: '2', name: 'Other users', fill: chartColors.different.normal, value: users ? users.otherTotal : '-' },
  ];

  return (
    <WidgetRainbow
      description="Number of users affected by the queries with low CTR"
      mainNumber={mainNumber}
      pieData={pieData}
      title="Affected users"
    />
  );
};


AffectedUsers.propTypes = {
  lostOpportunities: PropTypes.shape({
    isFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    users: PropTypes.shape({
      total: PropTypes.any.isRequired,
      filteredTotal: PropTypes.any.isRequired,
    }),
  }).isRequired,
};

export default AffectedUsers;
