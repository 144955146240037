import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Widget from '../../Widget';
import PlaceholderError from '../placeholders/PlaceholderError';

const CardContainerSmall = ({
  error = null,
  children,
  isFetching = false,
  headerId,
  showMoreUrl = null,
  height = 268,
}) => (
  <Widget styleName="jr-card-full noMargin">
    <div className="d-flex flex-row px-3 pt-4">
      <p className="text-uppercase">
        <IntlMessages id={headerId} />
      </p>
    </div>
    {
      isFetching || error
        ? <StubComponent error={error} height={height} isFetching={isFetching} />
        : <div>{children}</div>
    }

    {
      showMoreUrl
        ? (
          <div className="analytic-widget-show-more-wrapper">
            <Link to={showMoreUrl}>
              <Button size="small">Show more</Button>
            </Link>
          </div>
        )
        : null
    }

  </Widget>
);


const StubComponent = ({ error, isFetching, height }) => {
  if (isFetching) return <PlaceholderSpinner height={height} />;
  return <PlaceholderError error={error} height={height} />;
};


CardContainerSmall.defaultProps = {
  children: null,
  headerId: null,
  isFetching: false,
  height: 268,
  error: null,
  showMoreUrl: null,
};

CardContainerSmall.propTypes = {
  children: PropTypes.element,
  headerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isFetching: PropTypes.bool,
  height: PropTypes.number,
  error: PropTypes.any,
  showMoreUrl: PropTypes.any,
};


export default CardContainerSmall;
