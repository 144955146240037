import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import React from 'react';
import CustomTooltip from '../../common/CustomToolTIp';
import { chartColors } from 'util/chartUtils';

const PreparedChart = ({ data }) => (
  <ResponsiveContainer className="rounded-bottom-right overflow-hidden" width="100%" height={70}>
    <AreaChart
      data={data}
      margin={{
        top: 0, right: 0, left: 0, bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="color7" x1="0" y1="0" x2="1" y2="0">
          <stop offset="5%" stopColor={chartColors.main.normal} stopOpacity={1} />
          <stop offset="95%" stopColor={chartColors.different.light} stopOpacity={1} />
        </linearGradient>
      </defs>
      <Area
        dataKey="ctr"
        strokeWidth={0}
        stackId="2"
        stroke="#867AE5"
        fill="url(#color7)"
        fillOpacity={1}
      />
      <Tooltip
        content={<CustomTooltip dataKey="ctr" />}
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default PreparedChart;
