/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Parser from 'html-react-parser';
import qs from 'qs';
import { rewriteAlertBanners, updateAlertBanners } from '../../actions';
import AlertDismissible from '../../components/kea/AlertDismissible';

const getClassByType = (type) => {
  const match = {
    warning: 'shadow-lg alert alert-warning fade show',
    info: 'shadow-lg alert alert-info fade show',
    dark: 'shadow-lg alert alert-light fade show',
  };
  return match[type];
};
const AlertBanner = ({ location }) => {
  const dispatch = useDispatch();
  const { settings: {
    alerts: {
      data,
      isFetching,
      actions,
    } },
  } = useSelector((store) => store);

  useEffect(() => {
    const parseOptions = { ignoreQueryPrefix: true };
    const qsObject = qs.parse(location.search, parseOptions);
    const action = actions[qsObject.banner];
    if (action) {
      dispatch(updateAlertBanners([action]));
    }
  }, []);

  const alertsIsNotAvailable = isFetching || (data.length === 0);
  if (alertsIsNotAvailable) {
    return null;
  }

  const closeBanner = (key) => {
    let filtered = data.map((e, i) => ((i !== key) ? e : false));
    filtered = filtered.filter((e) => e);
    dispatch(rewriteAlertBanners(filtered));
  };

  return (
    <div className="alerts-banner-wrapper">
      {
        data.map((el, index) => (
          <AlertDismissible
            key={`${el.id}_${index}`}
            onClose={() => closeBanner(index)}
            text={Parser(el.message)}
            className={getClassByType(el.status)}
          />
        ))
      }

    </div>
  );
};

export default AlertBanner;
