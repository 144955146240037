/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import WidgetWithChart from '../../common/WidgetWithChart';
import PreparedChart from './PreparedChart';

const QueriesWithLowCTR = ({ lostOpportunities }) => {
  const { isFetched, isFetching, queries, curve, error } = lostOpportunities;
  const dataIsReady = isFetching || !isFetched;
  const mainNumber = error || !(queries && queries.filteredTotal)
    ? '-'
    : queries.filteredTotal;

  return (
    <WidgetWithChart
      isFetching={dataIsReady}
      title="Queries with low CTR"
      mainNumber={mainNumber}
      description="Total number of queries with low CTR"
      chart={<PreparedChart data={curve && curve.data} />}
    />
  );
};

QueriesWithLowCTR.propTypes = {
  lostOpportunities: PropTypes.shape({
    isFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    queries: PropTypes.shape({
      total: PropTypes.any.isRequired,
    }),
    curve: PropTypes.shape({
      data: PropTypes.any.isRequired,
    }),
  }).isRequired,
};


export default QueriesWithLowCTR;
