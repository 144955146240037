import React from 'react';
import { Link } from 'react-router-dom';
import { genRandomKey } from 'util/appUtils';

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
const getRelativePath = (path, relative) => `${path.split(relative).shift()}${relative}`;

const getDisplayString = (subPath) => {
  const arr = subPath.replace('-', ' ').split('_');
  if (arr.length > 1) {
    return arr.map(capitalize).join(' ');
  }
  return capitalize(arr[0]);
};

const Delimiter = () => (
  <div className="breadcrumbs-delimiter">/</div>
);

const Breadcrumbs = ({ url, show }) => {
  const urlAsArray = url.split('/').slice(5);
  if (urlAsArray.length < 2 || !show) {
    return null;
  }

  return (
    <div className="breadcrumbs">
      {
        urlAsArray.map((subPath, index) => {
          if (urlAsArray.length - 1 === index) {
            return (
              <div key={genRandomKey()} className="breadcrumbs-item-active">
                {getDisplayString(subPath)}
              </div>
            );
          }
          return (
            <React.Fragment key={genRandomKey()}>
              <Link to={getRelativePath(url, subPath)} className="breadcrumbs-item">
                {getDisplayString(subPath)}
              </Link>
              <Delimiter />
            </React.Fragment>
          );
        })
      }
    </div>
  );
};

const ContainerHeader = ({ title, match, action, showBreadcrumbs = true }) => (
  <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
    <div style={{ width: 'calc(100% - 250px)' }}>
      <h2 className="title mb-3 mb-sm-0">{title}</h2>
      <Breadcrumbs url={match.url} show={showBreadcrumbs} />
    </div>
    { action }
  </div>
);

export default ContainerHeader;
