import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../placeholders/Spinner';

const SaveActionBtnGrp = ({
  isFetching, buttons, disabled,
}) => (
  <div className="header-save-actions">
    {
      buttons && buttons.map((button) => (
        <Button
          disabled={button.disabled || disabled || isFetching}
          key={button.text}
          onClick={button.action}
          variant="contained"
          color={button.color || 'default'}
          className="jr-btn text-white setting-btn-grp-button"
        >
          <span>{button.text}</span>
        </Button>
      ))
    }
    {
      isFetching && (
      <div className="loader">
        <Spinner r={10} />
      </div>
      )
    }
  </div>
);

SaveActionBtnGrp.defaultProps = {
  isFetching: false,
};

SaveActionBtnGrp.propTypes = {
  isFetching: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.any,
    action: PropTypes.func,
    value: PropTypes.any,
    color: PropTypes.any,
  })).isRequired,
};

export default SaveActionBtnGrp;
