import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeTimeframe } from 'actions';
import DateRangePicker from 'components/kea/DateRangePicker';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import asyncComponent from 'util/asyncComponent';
import Conversion from './Conversion';
import PopularProducts from './PopularProducts';

const StoreAnalytics = ({ match }) => {
  const dispatch = useDispatch();
  const { analytics: { timeframe } } = useSelector((store) => store);

  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));

  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  return (
    <div className="app-wrapper-full-width animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={<IntlMessages id="sidebar.store.analytics" />}
      />

      <Switch>
        <Route path={`${match.url}/conversion`} component={Conversion} />
        <Route path={`${match.url}/popular-products`} component={PopularProducts} />

        <Route component={asyncComponent(() => import('app/routes/404'))} />
      </Switch>
    </div>
  );
};

export default StoreAnalytics;
