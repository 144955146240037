import React from 'react';
import { List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import IntlMessages from '../../util/IntlMessages';
import StrangeIcon from '../Onboarding/StrangeIcon';

const NavMenuItem = (props) => {
  const {
    name, icon, link, disabled, onClick,
  } = props;

  return (
    <List component="div" className="nav-menu-item">
      <NavLink className={`prepend-icon nav-menu-link ${disabled ? 'nav-disabled-item' : null}`} to={link} onClick={onClick}>
        {!!icon && (
          <StrangeIcon icon={icon} />
        )}
        <span className="nav-text"><IntlMessages id={name} /></span>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
