import { put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_AUTHORIZATION_INFO,
  GET_AUTHORIZATION_INFO_COMPLETE,
  GET_AUTHORIZATION_INFO_FAILED,
  KEA_SIGN_IN, KEA_SIGN_IN_FAILED,
  KEA_SIGN_IN_SUCCESS,
  KEA_SIGN_IN_WITH_SECRET,
  KEA_SIGN_IN_WITH_SECRET_SUCCESS,
  KEA_SIGN_IN_WITH_SECRET_FAILED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  SUBMIT_NEW_PASSWORD,
  SUBMIT_NEW_PASSWORD_SUCCESS,
  KEA_SIGN_OUT, ON_SHOW_LOADER, ON_HIDE_LOADER,
} from 'constants/ActionTypes';

import { rmToken, setToken, tokenKey } from 'util/authUtils';
import { m, wsClient } from 'util/appUtils';
import { rewriteAlertBanners } from '../actions';

const wsBaseUri = window.WS_BASE_URI;

async function postRequest(url, body) {
  const options = {
    method: m.POST,
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return fetch(url, options);
}

function keaSignOut() {
  rmToken();
  window.history.go('/login');
}

function* signIn({ body, url }) {
  const response = yield postRequest(url, body);
  if (response.status === 200) {
    const sessionToken = response.headers.get(tokenKey);
    setToken(sessionToken);
    const appAddress = '/';
    window.location.assign(appAddress);
  } else {
    rmToken();
    window.location.assign('/signin?banner=SING_IN_FAILED');
    throw new Error(`${response.status} — ${response.statusText}`);
  }
}

function* resetPasswordRequest({ payload }) {
  const bannersActions = yield select((store) => store.settings.alerts.actions);
  yield put({ type: ON_SHOW_LOADER });
  try {
    const url = `${wsBaseUri}system/password/reset?email=${payload.email}`;
    const response = yield fetch(url);
    if (response.status !== 200) {
      const bannerAction = bannersActions.PASSWORD_RESET_FAILED;
      yield put(rewriteAlertBanners([bannerAction]));
      yield put({ type: FORGOT_PASSWORD_FAILED, payload: response.error });
    } else {
      const bannerAction = bannersActions.PASSWORD_RESET;
      yield put(rewriteAlertBanners([bannerAction]));
      yield put({ type: FORGOT_PASSWORD_SUCCESS });
    }
  } catch (err) {
    const bannerAction = bannersActions.PASSWORD_RESET_FAILED;
    yield put(rewriteAlertBanners([bannerAction]));
    yield put({ type: FORGOT_PASSWORD_FAILED, payload: err });
  }
  yield put({ type: ON_HIDE_LOADER });
}

function* submitNewPassword({ payload }) {
  const bannersActions = yield select((store) => store.settings.alerts.actions);
  yield put({ type: ON_SHOW_LOADER });
  try {
    const url = `${wsBaseUri}system/password/confirm`;
    const response = yield postRequest(url, payload);
    if (response.status !== 200) {
      const bannerAction = bannersActions.PASSWORD_RESET_FAILED;
      yield put(rewriteAlertBanners([bannerAction]));
      yield put({ type: FORGOT_PASSWORD_FAILED, payload: response.error });
    } else {
      window.location.assign('/signin?banner=PASSWORD_UPDATED_SUCCESSFULLY');
      yield put({ type: SUBMIT_NEW_PASSWORD_SUCCESS });
    }
  } catch (err) {
    const bannerAction = bannersActions.PASSWORD_RESET_FAILED;
    yield put(rewriteAlertBanners([bannerAction]));
    yield put({ type: FORGOT_PASSWORD_FAILED, payload: err });
  }
  yield put({ type: ON_HIDE_LOADER });
}

function* keaSignIn({ payload }) {
  try {
    const { email, password } = payload;

    const body = { email, password };
    const url = `${wsBaseUri}auth/login`;
    yield signIn({ body, url });

    yield put({ type: KEA_SIGN_IN_SUCCESS });
  } catch (err) {
    yield put({ type: KEA_SIGN_IN_FAILED, payload: err });
  }
}

function* keaSignInWithSecret({ payload }) {
  try {
    const { domain: token, secret, appId } = payload;
    const body = { token, secret, appId };
    const url = `${wsBaseUri}auth/login/token`;
    yield signIn({ body, url });

    yield put({ type: KEA_SIGN_IN_WITH_SECRET_SUCCESS });
  } catch (err) {
    yield put({ type: KEA_SIGN_IN_WITH_SECRET_FAILED, payload: err });
  }
}

function* getAuthorization() {
  try {
    const { account, shops } = yield wsClient.prepareRequest(m.GET, 'account');
    const result = {
      account,
      shopList: [],
      shopMap: {},
    };

    if (shops) {
      result.shopList = Object.keys(shops).map((shop) => shops[shop]);
      result.shopMap = shops;
    }

    yield put({ type: GET_AUTHORIZATION_INFO_COMPLETE, payload: result });
  } catch (err) {
    if (/401/.test(err.message)) {
      rmToken();
      window.location.reload();
    }
    yield put({ type: GET_AUTHORIZATION_INFO_FAILED, payload: err });
  }
}


export default function* authSaga() {
  yield takeLatest(KEA_SIGN_OUT, keaSignOut);
  yield takeLatest(KEA_SIGN_IN, keaSignIn);
  yield takeLatest(KEA_SIGN_IN_WITH_SECRET, keaSignInWithSecret);
  yield takeLatest(GET_AUTHORIZATION_INFO, getAuthorization);
  yield takeLatest(FORGOT_PASSWORD, resetPasswordRequest);
  yield takeLatest(SUBMIT_NEW_PASSWORD, submitNewPassword);
}
