/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRecsFields, recsLoadAllModels, recsLoadAllPages, recsLoadAllWidgets } from 'actions';
import SafetyRoute from 'components/kea/SafetyRoute';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import Settings from './settings';
import Dashboard from './dashboard';
import Analytics from './analytics';
import Customization from './customization';
import OnboardingRecs from '../../../containers/OnboardingRecs';

const RecsRouter = ({ match }) => {
  const dispatch = useDispatch();
  const { app: { currentShop } } = useSelector((store) => store);
  useEffect(() => {
    dispatch(getRecsFields(currentShop));
    dispatch(recsLoadAllModels(currentShop));
    dispatch(recsLoadAllPages(currentShop));
    dispatch(recsLoadAllWidgets(currentShop));
  }, []);

  const { url } = match;
  const dashboardUrl = `${url}/dashboard`;
  return (
    <Switch>
      <SafetyRoute path={dashboardUrl} render={(props) => <Dashboard {...props} />} />
      <SafetyRoute path={`${url}/analytics`} render={(props) => <Analytics {...props} />} />
      <SafetyRoute path={`${url}/customization`} render={(props) => <Customization {...props} />} />
      <SafetyRoute path={`${url}/settings`} render={(props) => <Settings {...props} />} />
      <SafetyRoute path={`${url}/onboarding`} render={(props) => <OnboardingRecs {...props} />} />
      <SafetyRoute component={() => <Redirect to={dashboardUrl} />} />
    </Switch>
  );
};

export default RecsRouter;

RecsRouter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};
