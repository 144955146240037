/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, filterProducts } from 'actions/Catalog';
import CardContainer from 'components/kea/CardContainer';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import ContainerHeader from 'components/ContainerHeader';
import SearchInput from './SearchInput';

const getDataForTable = (data, filteredData, filterQuery) => {
  if (filterQuery.length) {
    return filteredData;
  }
  return data;
};

const ProductListOld = ({ match }) => {
  const { app: { currentShop }, catalog: { productList } } = useSelector((store) => store);
  const { data, isFetching, isFetched, isFiltering, filterQuery, filteredData } = productList;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts(currentShop));
  }, []);

  const headCells = [
    { id: 'product', numeric: false, label: 'Product' },
    { id: 'link', numeric: false, label: 'Link' },
  ];

  const filterHandler = ({ target }) => dispatch(filterProducts(target.value, data));
  const filterInput = (
    <SearchInput
      value={filterQuery}
      changeHandler={filterHandler}
      isFiltering={isFiltering}
    />
  );

  return (
    <div className="app-wrapper-full-width animated slideInUpTiny animation-duration-3">
      <ContainerHeader match={match} title="Product catalog" />
      <CardContainer
        styleName="min-height-700px noMargin "
        isFetching={isFetching}
        headerPostfix="Product list"
        actionComponent={filterInput}
      >
        {
            isFetched && data
              ? (
                <AnalyticTable
                  rows={getDataForTable(data, filteredData, filterQuery)}
                  headCells={headCells}
                />
              )
              : <PlaceholderSpinner height={500} />
        }
      </CardContainer>
    </div>
  );
};

export default ProductListOld;
