/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import QueriesWithLowCTR from 'components/kea/widgets/lostOpportunities/QueriesWithLowCTR';
import BriefQueryNoResult from 'components/kea/widgets/BriefQueryNoResult';
import AffectedUsers from 'components/kea/widgets/lostOpportunities/AffectedUsers';
import { getAnalyticLostOpportunities, getAnalyticQueryNoResult } from 'actions';
import MainSection from './MainSection';

const LostOpportunities = ({ match }) => {
  const dispatch = useDispatch();
  const {
    analytics: { search, timeframe },
    app: { currentShop },
  } = useSelector((store) => store);
  const { lostOpportunities, queryNoResult } = search;

  useEffect(() => {
    dispatch(getAnalyticLostOpportunities(currentShop, timeframe));
    dispatch(getAnalyticQueryNoResult(currentShop, timeframe));
  }, [timeframe]);

  return (
    <Grid>
      <Grid spacing={4} container>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <MainSection
            currentShop={currentShop}
            lostOpportunities={lostOpportunities}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Grid spacing={4} container>

            <Grid item xs={12} sm={6} md={6} lg={12}>
              <QueriesWithLowCTR lostOpportunities={lostOpportunities} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <AffectedUsers lostOpportunities={lostOpportunities} />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={12}>
              <BriefQueryNoResult
                match={match}
                queryNoResult={queryNoResult}
                currentShop={currentShop}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} />
      </Grid>
    </Grid>
  );
};

export default LostOpportunities;
