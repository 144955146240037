import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  onboardingSearchActivate,
  onboardingSearchCustomize,
  onboardingSearchPreview,
} from '../slices/OnboardingSlice';

import Onboarding from './Onboarding';

function buildCards(history, shopId) {
  return [
    {
      id: 'step1',
      header: 'Safely preview search',
      buttonColor: '#0187ac',
      buttonText: 'Preview',
      buttonIcon: 'search',
      buttonOnClick: () => onboardingSearchPreview(),
      meta: 'Sneak peek Kea Labs Search before making it enabled for all of your visitors.',
    }, {
      id: 'step2',
      header: 'Customize search and style settings',
      buttonColor: '#0187ac',
      buttonText: 'Customize',
      buttonIcon: 'edit',
      buttonOnClick: () => {
        history.push(`/app/${shopId}/search/settings`);
        return onboardingSearchCustomize();
      },
      meta: 'Edit visual style and behaviour of a Search to match your needs',
    }, {
      id: 'step3',
      header: 'Make search enabled for your customers!',
      buttonColor: 'green',
      buttonText: 'Activate',
      buttonIcon: 'boat',
      buttonOnClick: () => {
        history.push(`/app/${shopId}/search/dashboard`);
        return onboardingSearchActivate();
      },
      meta: 'Make Kea Labs Search active and show it for all of your visitors.',
    },
  ];
}

export default function OnboardingSearch() {
  const history = useHistory();
  const { shopId } = useSelector((store) => store.settings);
  const cards = buildCards(history, shopId);

  return (
    <Onboarding
      cards={cards}
      header="Setup Kea Labs Search"
      subheader1="For your convenience, Kea Labs Search is not enabled for all of your visitors yet, so you can safely preview it, adjust search behaviour, visual styles, and enable it once you'll be happy with results."
      subheader2="Configure and activate Kea Labs Search in 3 easy steps:"
    />
  );
}
