import React from 'react';
import PropTypes from 'prop-types';

const CardBox = ({
  children, header, subheader, subsubheader, actionComponent, styleName,
}) => (
  <div className={`jr-card full-height-card ${styleName}`}>
    <div className="jr-card-header d-flex align-items-start ">
      <div className="mr-auto">
        <h3 className="card-heading">{header}</h3>
        {subheader && <p className="sub-heading">{subheader}</p>}
        {subsubheader && <p className="sub-heading">{subsubheader}</p>}
      </div>
      {actionComponent || null}
    </div>
    <div>{children}</div>
  </div>
);

CardBox.defaultProps = {
  actionComponent: null,
  subheader: null,
  subsubheader: null,
  styleName: null,

};

CardBox.propTypes = {
  children: PropTypes.any.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  actionComponent: PropTypes.any,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  subsubheader: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  styleName: PropTypes.string,
};


export default CardBox;
