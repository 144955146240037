
export default {
  palette: {
    primary: {
      light: '#9BCE8E',
      main: '#82c272',
      dark: '#5B874F',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4B9FBB',
      main: '#1F88AB',
      dark: '#155F77',
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
  },
};
