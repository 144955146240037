import React from 'react';
import IconWithTextCard from 'components/kea/IconWithTextCard';
import PropTypes from 'prop-types';
import { ReactComponent as UniqueVisitorsIcon } from 'assets/images/dashboard/kea-uniq-visitors-1.svg';
import { ReactComponent as ViewedProductsIcon } from 'assets/images/dashboard/kea-viewed-products-1.svg';
import { ReactComponent as ViewedIcon } from 'assets/images/dashboard/kea-recs-viewed-1.svg';
import { ReactComponent as ClickedIcon } from 'assets/images/dashboard/kea-recs-clicked-1.svg';
import { commaSeparate } from 'util/appUtils';

const Summary = ({ summary }) => {
  const dataMetrics = [
    {
      title: commaSeparate(summary.totalCarts),
      subTitle: 'Unique visitors',
      imageIcon: <UniqueVisitorsIcon className="dashboard-summary-icon" />,
    }, {
      title: commaSeparate(summary.totalViews),
      subTitle: 'Viewed products',
      imageIcon: <ViewedProductsIcon className="dashboard-summary-icon" />,
    }, {
      title: commaSeparate(summary.totalRecsViewed),
      subTitle: 'Viewed',
      imageIcon: <ViewedIcon className="dashboard-summary-icon" />,
    }, {
      title: commaSeparate(summary.totalRecsClicked),
      subTitle: 'Clicked',
      imageIcon: <ClickedIcon className="dashboard-summary-icon" />,
    },
  ];

  return dataMetrics.map((e, i) => (
    <div key={e.subTitle} className="col-lg-3 col-sm-6 col-12">
      <IconWithTextCard data={e} />
    </div>
  ));
};

export default Summary;

Summary.propTypes = {
  summary: PropTypes.shape({
    totalCarts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalViews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalRecsViewed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalRecsClicked: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};
