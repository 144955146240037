import PropTypes from 'prop-types';
import React from 'react';

const CustomTooltip = ({ active, payload, dataKey }) => {
  if (active) {
    const { payload: data } = payload[0];
    return (
      <div style={{ color: '#000', fontWeight: 600 }}>
        {`${data.label} - ${data[dataKey]}`}
      </div>
    );
  }
  return null;
};

CustomTooltip.defaultProps = {
  dataKey: 'total',
  active: false,
  payload: [],
};

CustomTooltip.propTypes = {
  dataKey: PropTypes.string,
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.shape({
    payload: PropTypes.shape({
      label: PropTypes.string,
      total: PropTypes.number,
    }),
  })),
};

export default CustomTooltip;
