import { put, select, takeLatest } from 'redux-saga/effects';
import * as c from 'constants/actionTypes/search';
import { mapDifference, wsClient } from '../util/appUtils';


function* fetchFields({ payload }) {
  try {
    const { fields, attributes } = yield wsClient.fetchFields(payload.token, 'search');

    const data = {};
    fields.forEach((el) => {
      data[el.id] = {
        id: el.id,
        title: el.title,
        weight: el.weight,
        autocomplete: el.autocomplete,
      };
    });

    yield put({ type: c.GET_FIELDS_COMPLETE, payload: { fields: data, attributes } });
  } catch (err) {
    yield put({ type: c.GET_FIELDS_FAILED, payload: err });
  }
}


function* updateFields({ payload }) {
  try {
    const { fields: { data: fields, forDiff } } = yield select((store) => store.search);

    const fieldsDiff = mapDifference(fields, forDiff);
    const body = { fields: fieldsDiff, domain: payload };
    yield wsClient.updateFields(payload, 'search', body);

    yield put({ type: c.GET_FIELDS, payload: { token: payload } });
    yield put({ type: c.SAVE_FIELDS_CHANGES_COMPLETED });
  } catch (err) {
    yield put({ type: c.SAVE_FIELDS_CHANGES_FAILED, payload: err });
  }
}


export default function* search() {
  yield takeLatest(c.GET_FIELDS, fetchFields);
  yield takeLatest(c.SAVE_FIELDS_CHANGES, updateFields);
}
