import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsToggleSwitcher from 'components/kea/settings/SettingsToggleSwitcher';
import SettingRangeSlider from 'components/kea/settings/SettingRangeSlider';
import * as a from '../../../../../actions';

const General = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store);
  const { recsVisible, recs: { theme } } = settings;

  const handleClickEnableService = (value) => dispatch(a.updateRecsVisible(value));

  const changeHandlerRowSm = (value) => dispatch(a.changeRecsThemeSetting('widget-products-inrow-sm', value));
  const changeHandlerRowMd = (value) => dispatch(a.changeRecsThemeSetting('widget-products-inrow-md', value));
  const changeHandlerRowLg = (value) => dispatch(a.changeRecsThemeSetting('widget-products-inrow-lg', value));
  const changeHandlerRowXl = (value) => dispatch(a.changeRecsThemeSetting('widget-products-inrow-xl', value));

  return (
    <div className="settings-container">
      <div className="setting-group-header">Kea Labs Recommendations integration</div>
      <SettingsToggleSwitcher
        title="Enable Kea Labs Recommendations"
        description={'You can switch off Kea Labs Search if it\'s required. So nobody can see it.\n'
        + 'After that you can safely test all of your changes with a Preview tool.'}
        value={recsVisible.value}
        onChange={handleClickEnableService}
      />

      <div className="setting-group-header">Number of Recommendations</div>
      <div className="setting-group-note">Number of visible Recommendations on different screen sizes</div>

      <SettingRangeSlider
        value={theme['widget-products-inrow-sm']}
        onChange={changeHandlerRowSm}
        title="Mobile"
        min={1}
        max={5}
        step={1}
      />

      <SettingRangeSlider
        value={theme['widget-products-inrow-md']}
        onChange={changeHandlerRowMd}
        title="Tablet"
        min={1}
        max={5}
        step={1}
      />

      <SettingRangeSlider
        value={theme['widget-products-inrow-lg']}
        onChange={changeHandlerRowLg}
        title="Laptop"
        min={1}
        max={5}
        step={1}
      />

      <SettingRangeSlider
        value={theme['widget-products-inrow-xl']}
        onChange={changeHandlerRowXl}
        title="Desktop"
        min={1}
        max={5}
        step={1}
      />
    </div>
  );
};

export default General;
