import React from 'react';
import { Switch } from 'react-router-dom';
import SafetyRoute from 'components/kea/SafetyRoute';
import { Redirect } from 'react-router';
import Settings from './settings';
import Dashboard from './dashboard';
import Analytic from './analytic';
import Customization from './customization';
import OnboardingSearch from '../../../containers/OnboardingSearch';

const SearchRouter = ({ match }) => {
  const { url } = match;
  const dashboardUrl = `${url}/dashboard`;
  return (
    <Switch>
      <SafetyRoute path={dashboardUrl} render={(props) => <Dashboard {...props} />} />
      <SafetyRoute path={`${url}/analytic`} render={(props) => <Analytic {...props} />} />
      <SafetyRoute path={`${url}/customization`} render={(props) => <Customization {...props} />} />
      <SafetyRoute path={`${url}/settings`} render={(props) => <Settings {...props} />} />
      <SafetyRoute path={`${url}/onboarding`} render={(props) => <OnboardingSearch {...props} />} />
      <SafetyRoute component={() => <Redirect to={dashboardUrl} />} />
    </Switch>
  );
};

export default SearchRouter;
