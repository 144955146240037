export const GET_PRODUCTS = 'catalog/GET_PRODUCTS';
export const GET_PRODUCTS_COMPLETE = 'catalog/GET_PRODUCTS_COMPLETE';
export const GET_PRODUCTS_FAILED = 'catalog/GET_PRODUCTS_FAILED';

export const GET_PRODUCT_BY_ID = 'catalog/GET_PRODUCT_BY_ID';
export const GET_PRODUCT_BY_ID_COMPLETE = 'catalog/GET_PRODUCT_BY_ID_COMPLETE';
export const GET_PRODUCT_BY_ID_FAILED = 'catalog/GET_PRODUCT_BY_ID_FAILED';

export const FILTER_PRODUCTS_BY_QUERY = 'catalog/FILTER_PRODUCTS_BY_QUERY';
export const FILTER_PRODUCTS_BY_QUERY_COMPLETE = 'catalog/FILTER_PRODUCTS_BY_QUERY_COMPLETE';
export const FILTER_PRODUCTS_BY_QUERY_FAILED = 'catalog/FILTER_PRODUCTS_BY_QUERY_FAILED';
