import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Fade, Paper, Popper } from '@material-ui/core';

const ColorPicker = ({
  value,
  onChange,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => setDisplayColorPicker(false);

  return (
    <div>
      <div className="cp-swatch" onClick={handleClick}>
        <div
          className="cp-color"
          style={{ backgroundColor: value }}
        />
      </div>
      {
        displayColorPicker
          ? <div className="cp-cover" onClick={handleClose} />
          : null
      }
      <Popper
        open={displayColorPicker}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        modifiers={{ name: 'flip', options: { fallbackPlacements: ['top', 'bottom'] } }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <SketchPicker
                color={value}
                onChange={(e) => onChange(e.hex)}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default ColorPicker;
