import React from 'react';
import { Alert } from 'reactstrap';

const AlertDismissible = ({ onClose, text, className }) => (
  <Alert
    toggle={onClose}
    className={className}
  >
    {text}
  </Alert>
);

export default AlertDismissible;
