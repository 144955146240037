import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsToggleSwitcher from 'components/kea/settings/SettingsToggleSwitcher';
import * as a from 'actions';
import SettingRangeSlider from 'components/kea/settings/SettingRangeSlider';

const ResultPage = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store);
  const { search: { searchResultPage } } = settings;

  const changeHandlerShowSearchBox = (value) => dispatch(a.changeSearchResultSetting('showSearchBox', value));
  const changeHandlerItemPerPage = (value) => dispatch(a.changeSearchResultSetting('limitSelected', value));
  const changeHandlerButtonsCount = (value) => dispatch(a.changeSearchResultSetting('paginationButtonsCount', value));

  return (
    <div className="settings-container">
      <div className="setting-group-header">Behavior</div>
      <SettingsToggleSwitcher
        title="Place Search field with autocomplete on top of the search results page"
        description={'If it\'s false - header of the search results page will contain only label with the current search query.'}
        value={searchResultPage.showSearchBox}
        onChange={changeHandlerShowSearchBox}
      />

      <div className="setting-group-header">Pagination</div>
      <p className="setting-group-note">Note: you can configure number of products per row on the "Theme and colors" tab.</p>
      <SettingRangeSlider
        value={searchResultPage.limitSelected}
        onChange={changeHandlerItemPerPage}
        title="Number of products per page"
        min={24}
        max={96}
        step={1}
      />

      <SettingRangeSlider
        value={searchResultPage.paginationButtonsCount}
        onChange={changeHandlerButtonsCount}
        title="Number of pagination buttons"
        min={1}
        max={5}
        step={1}
      />

      {/* <div style={{ whiteSpace: 'pre' }}> */}
      {/*  { JSON.stringify(searchResultPage, null, 4) } */}
      {/* </div> */}
    </div>
  );
};

export default ResultPage;
