import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Spinner from 'components/kea/placeholders/Spinner';
import * as Icons from '@material-ui/icons';

const SearchInput = ({ changeHandler, isFiltering, value, onClear }) => (
  <TextField
    style={{ width: 600 }}
    onChange={changeHandler}
    value={value}
    variant="outlined"
    size="small"
    placeholder="Find in products...  "
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconButton>
            {
              isFiltering
                ? <Spinner r={15} />
                : <Icons.Search />
          }
          </IconButton>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={onClear}>
            <Icons.Clear />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default SearchInput;
