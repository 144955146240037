import { put, takeLatest } from 'redux-saga/effects';
import * as c from 'constants/actionTypes/billing';
import { wsClient } from '../util/appUtils';

function* getPlans({ payload }) {
  try {
    const { appId, token } = payload;
    const data = wsClient.getPlans(token, appId);
    yield put({ type: c.GET_PLANS_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.GET_PLANS_FAILED, payload: err });
  }
}


export default function* searchSaga() {
  yield takeLatest(c.GET_PLANS, getPlans);
}
