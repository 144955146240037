import React, { useRef, useState } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from 'components/Navigation';
import { SEARCH, RECS } from 'util/appUtils';
import { keaSignOut } from "../../actions";

const SideBarContent = ({ shopId }) => {
  const { settings, onboarding } = useSelector((store) => store);
  const isDisabled = (appId) => {
    if (!shopId) return true;
    if (!settings.isFetched) return true;

    const { searchEnabled, recsEnabled } = settings;
    if (appId === SEARCH) return !searchEnabled;
    if (appId === RECS) return !recsEnabled;

    return false;
  };

  const experimentIsDisabled = () => {
    if (settings && settings.experiment) {
      return !settings.experiment.enabled;
    }
    return true;
  };

  const logoutButton = new LogoutButton();
  const shopDisabled = isDisabled();
  const searchDisabled = isDisabled(SEARCH);
  const recsDisabled = isDisabled(RECS);

  // todo move links to component/store chunk
  const navigationMenus = [
    {
      name: 'sidebar.section.search',
      type: 'section',
      children: [
        !onboarding.search.completed && {
          name: 'sidebar.search.onboarding',
          type: 'item',
          icon: 'boat',
          disabled: searchDisabled,
          link: `/app/${shopId}/search/onboarding`,
        },
        {
          name: 'sidebar.search.dashboard',
          type: 'item',
          disabled: searchDisabled,
          link: `/app/${shopId}/search/dashboard`,
          icon: 'trending-up',
        },
        {
          name: 'sidebar.search.analytics',
          type: 'collapse',
          icon: 'chart',
          children: [
            {
              name: 'sidebar.search.analytics.queryPopular',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/analytic/query-popular`,
            }, {
              name: 'sidebar.search.analytics.lostOpportunities',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/analytic/lost-opportunities`,
            }, {
              name: 'sidebar.search.analytics.queryNoResult',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/analytic/query-no-result`,
            },
          ],
        },
        {
          name: 'sidebar.search.customization',
          type: 'item',
          icon: 'code-setting',
          disabled: searchDisabled,
          link: `/app/${shopId}/search/customization/fields`,
        },
        {
          name: 'sidebar.search.settings',
          type: 'collapse',
          icon: 'settings',
          children: [
            {
              name: 'sidebar.search.settings.general',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/settings/general`,
            }, {
              name: 'sidebar.search.settings.autocomplete',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/settings/autocomplete`,
            }, {
              name: 'sidebar.search.settings.result-page',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/settings/result-page`,
            }, {
              name: 'sidebar.search.settings.filters',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/settings/filters`,
            }, {
              name: 'sidebar.search.settings.theme',
              type: 'item',
              disabled: searchDisabled,
              link: `/app/${shopId}/search/settings/theme`,
            },
          ],
        },
      ],
    }, {
      name: 'sidebar.section.recs',
      type: 'section',
      children: [
        !onboarding.recs.completed && {
          name: 'sidebar.recs.onboarding',
          type: 'item',
          icon: 'boat',
          disabled: recsDisabled,
          link: `/app/${shopId}/recs/onboarding`,
        },
        {
          name: 'sidebar.recs.dashboard',
          type: 'item',
          disabled: recsDisabled,
          link: `/app/${shopId}/recs/dashboard`,
          icon: 'trending-up',
        }, {
          name: 'sidebar.recs.analytics',
          type: 'collapse',
          icon: 'chart',
          children: [{
            name: 'sidebar.recs.analytics.widgets',
            type: 'item',
            disabled: recsDisabled,
            link: `/app/${shopId}/recs/analytics/widgets-performance`,
          }],
        }, {
          name: 'sidebar.recs.customization',
          type: 'collapse',
          icon: 'code-setting',
          disabled: recsDisabled,
          children: [{
            name: 'sidebar.recs.customization.fields',
            type: 'item',
            disabled: recsDisabled,
            link: `/app/${shopId}/recs/customization/fields`,
          }, {
            name: 'sidebar.recs.customization.widgets',
            type: 'item',
            disabled: recsDisabled,
            link: `/app/${shopId}/recs/customization/widgets`,
          }],
        }, {
          name: 'sidebar.recs.settings',
          type: 'collapse',
          icon: 'settings',
          children: [{
            name: 'sidebar.recs.settings.general',
            type: 'item',
            disabled: recsDisabled,
            link: `/app/${shopId}/recs/settings/general`,
          }, {
            name: 'sidebar.recs.settings.theme',
            type: 'item',
            disabled: recsDisabled,
            link: `/app/${shopId}/recs/settings/theme`,
          }],
        },
      ],
    },
    {
      name: 'sidebar.section.store',
      type: 'section',
      children: [
        {
          name: 'sidebar.home.products',
          type: 'item',
          disabled: false,
          link: `/app/${shopId}/store/catalog/product-catalog`,
          icon: 'link',
        },
        {
          name: 'sidebar.store.analytics',
          type: 'collapse',
          icon: 'chart',
          children: [
            {
              name: 'search.analytics.popularProducts.header',
              type: 'item',
              disabled: shopDisabled,
              link: `/app/${shopId}/store/analytics/popular-products`,
            }, {
              name: 'sidebar.search.analytics.conversion',
              type: 'item',
              disabled: shopDisabled,
              link: `/app/${shopId}/store/analytics/conversion`,
            },
          ],
        },
        {
          name: 'sidebar.store.experiment',
          type: 'item',
          disabled: experimentIsDisabled(),
          link: `/app/${shopId}/store/experiment`,
          icon: 'link',
        },
      ],
    },
    // {
    //   name: 'sidebar.section.billing',
    //   type: 'section',
    //   children: [
    //     {
    //       name: 'sidebar.billing.plans',
    //       type: 'item',
    //       disabled: isDisabled(),
    //       link: `/app/${shopId}/organization/billing`,
    //       icon: 'money',
    //     },
    //     {
    //       name: 'sidebar.billing.support',
    //       type: 'item',
    //       link: `/app/${shopId}/help`,
    //       icon: 'money',
    //     },
    //   ],
    // },
    {
      name: 'sidebar.account',
      type: 'section',
      children: [
        {
          name: 'sidebar.account.logout',
          type: 'item',
          link: '/signin',
          onClick: logoutButton.handleLogout,
          icon: 'sign-in',
        },
      ],
    },
  ];

  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

function LogoutButton() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  this.handleLogout = (e) => {
    handleClose(e);
    dispatch(keaSignOut());
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };
}

export default SideBarContent;
