import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SafetyRoute from 'components/kea/SafetyRoute';
import asyncComponent from 'util/asyncComponent';
import SignInPage from './SignInPage';
import SignInEmbedded from './SignInEmbedded';
import ForgotPassword from './ForgotPassword';
import SubmitNewPassword from './SubmitNewPassword';

const SignIn = ({ match }) => (
  <Switch>
    <SafetyRoute path={`${match.url}/`} exact render={(props) => <SignInPage {...props} />} />
    <SafetyRoute path={`${match.url}/forgot-password`} exact render={(props) => <ForgotPassword {...props} />} />
    <SafetyRoute path={`${match.url}/change-password`} render={(props) => <SubmitNewPassword {...props} />} />
    <SafetyRoute path={`${match.url}/:domain/:secret/:app`} exact render={(props) => <SignInEmbedded {...props} />} />

    <Route component={asyncComponent(() => import('app/routes/404'))} />
  </Switch>
);

export default SignIn;
