import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import PlaceholderError from '../placeholders/PlaceholderError';

const CardContainer = ({
  error = null,
  children,
  isFetching = false,
  headerId,
  headerPostfix,
  subheaderId,
  actionComponent = null,
  styleName,
  height,
}) => (
  <div className={`jr-card ${styleName}`}>
    <div className="jr-card-header d-flex align-items-start ">
      <div className="mr-auto">
        <h3 className="card-heading">
          {headerId && <IntlMessages id={headerId} />}
          {headerPostfix}
        </h3>
        {subheaderId && <p className="sub-heading"><IntlMessages id={subheaderId} /></p>}
      </div>
      <div className="ml-auto">
        { actionComponent }
      </div>
    </div>
    {
      isFetching || error
        ? <StubComponent error={error} height={height} isFetching={isFetching} />
        : <div>{children}</div>
    }
  </div>
);

const StubComponent = ({ error, height, isFetching }) => {
  if (isFetching) return <PlaceholderSpinner height={height} />;
  return <PlaceholderError error={error} height={height} />;
};


CardContainer.defaultProps = {
  children: null,
  actionComponent: null,
  headerId: null,
  subheaderId: null,
  styleName: null,
  isFetching: false,
  height: 500,
  headerPostfix: null,
  error: null,
};

CardContainer.propTypes = {
  children: PropTypes.element,
  actionComponent: PropTypes.element,
  headerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  subheaderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  styleName: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isFetching: PropTypes.bool,
  height: PropTypes.number,
  headerPostfix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.any,
};


export default CardContainer;
