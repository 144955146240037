import { ButtonGroup } from 'reactstrap';
import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'components/kea/placeholders/CircularProgress';

const ButtonGrp = ({
  isFetching, buttons, onClick, value,
}) => (
  <ButtonGroup>
    {
        isFetching
          ? <CircularProgress className="setting-item-fetching-circular" />
          : buttons && buttons.map((e) => (
            <Button
              key={e.value}
              onClick={() => onClick(e.value)}
              className={`jr-btn setting-btn-grp-button ${e.value === value ? 'active' : null}`}
            >
              {e.text}
            </Button>
          ))
    }
  </ButtonGroup>
);

ButtonGrp.defaultProps = {
  isFetching: false,
};

ButtonGrp.propTypes = {
  isFetching: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.any.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
};

export default ButtonGrp;
