import React from 'react';
import WidgetPerformanceBlock from './WidgetPerformanceBlock';

const PagePerformanceBlock = ({ page }) => (
  <div className="page-block">
    <div className="row">
      {
          page.widgets.map((widget) => (
            <div className="col-12" key={widget.id}>
              <WidgetPerformanceBlock widget={widget} />
            </div>
          ))
      }
    </div>
    <div className="div_separator" />
  </div>
);
export default PagePerformanceBlock;
