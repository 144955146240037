export const GET_FIELDS = 'recs/GET_FIELDS';
export const GET_FIELDS_COMPLETE = 'recs/GET_FIELDS_COMPLETE';
export const GET_FIELDS_FAILED = 'recs/GET_FIELDS_FAILED';
export const DISCARD_FIELDS_CHANGES = 'recs/DISCARD_FIELDS_CHANGES';
export const FIELDS_CHANGE_HANDLER = 'recs/FIELDS_CHANGE_HANDLER';

export const SAVE_FIELDS_CHANGES = 'recs/SAVE_FIELDS_CHANGES';
export const SAVE_FIELDS_CHANGES_COMPLETED = 'recs/SAVE_FIELDS_CHANGES_COMPLETED';
export const SAVE_FIELDS_CHANGES_FAILED = 'recs/SAVE_FIELDS_CHANGES_FAILED';


export const RECS_LOAD_ALL_PAGES = 'recs/RECS_LOAD_ALL_PAGES';
export const RECS_LOAD_ALL_PAGES_COMPLETE = 'recs/RECS_LOAD_ALL_PAGES_COMPLETE';
export const RECS_LOAD_ALL_PAGES_FAILED = 'recs/RECS_LOAD_ALL_PAGES_FAILED';

export const RECS_LOAD_ONE_PAGE = 'recs/RECS_LOAD_ONE_PAGE';
export const RECS_LOAD_ONE_PAGE_COMPLETE = 'recs/RECS_LOAD_ONE_PAGE_COMPLETE';
export const RECS_LOAD_ONE_PAGE_FAILED = 'recs/RECS_LOAD_ONE_PAGE_FAILED';

export const RECS_CUSTOMIZE_SECTIONS_IN_PAGE = 'settings/RECS_CUSTOMIZE_SECTIONS_IN_PAGE';

export const RECS_UPDATE_PAGE = 'recs/RECS_UPDATE_PAGE';
export const RECS_UPDATE_PAGE_COMPLETE = 'recs/RECS_UPDATE_PAGE_COMPLETE';
export const RECS_UPDATE_PAGE_FAILED = 'recs/RECS_UPDATE_PAGE_FAILED';


export const RECS_LOAD_ALL_MODELS = 'recs/RECS_LOAD_ALL_MODELS';
export const RECS_LOAD_ALL_MODELS_COMPLETE = 'recs/RECS_LOAD_ALL_MODELS_COMPLETE';
export const RECS_LOAD_ALL_MODELS_FAILED = 'recs/RECS_LOAD_ALL_MODELS_FAILED';

export const RECS_LOAD_ONE_MODEL = 'recs/RECS_LOAD_ONE_MODEL';
export const RECS_LOAD_ONE_MODEL_COMPLETE = 'recs/RECS_LOAD_ONE_MODEL_COMPLETE';
export const RECS_LOAD_ONE_MODEL_FAILED = 'recs/RECS_LOAD_ONE_MODEL_FAILED';

export const RECS_LOAD_ALL_WIDGETS = 'recs/RECS_LOAD_ALL_WIDGETS';
export const RECS_LOAD_ALL_WIDGETS_COMPLETE = 'recs/RECS_LOAD_ALL_WIDGETS_COMPLETE';
export const RECS_LOAD_ALL_WIDGETS_FAILED = 'recs/RECS_LOAD_ALL_WIDGETS_FAILED';

export const RECS_LOAD_ONE_WIDGET = 'recs/RECS_LOAD_ONE_WIDGET';
export const RECS_LOAD_ONE_WIDGET_COMPLETE = 'recs/RECS_LOAD_ONE_WIDGET_COMPLETE';
export const RECS_LOAD_ONE_WIDGET_FAILED = 'recs/RECS_LOAD_ONE_WIDGET_FAILED';

export const RECS_UPDATE_ONE_WIDGET = 'recs/RECS_UPDATE_ONE_WIDGET';
export const RECS_UPDATE_ONE_WIDGET_COMPLETE = 'recs/RECS_UPDATE_ONE_WIDGET_COMPLETE';
export const RECS_UPDATE_ONE_WIDGET_FAILED = 'recs/RECS_UPDATE_ONE_WIDGET_FAILED';

export const RECS_CREATE_WIDGET = 'recs/RECS_CREATE_WIDGET';
export const RECS_CREATE_WIDGET_COMPLETE = 'recs/RECS_CREATE_WIDGET_COMPLETE';
export const RECS_CREATE_WIDGET_FAILED = 'recs/RECS_CREATE_WIDGET_FAILED';

export const RECS_DELETE_WIDGET = 'recs/RECS_DELETE_WIDGET';
export const RECS_DELETE_WIDGET_COMPLETE = 'recs/RECS_DELETE_WIDGET_COMPLETE';
export const RECS_DELETE_WIDGET_FAILED = 'recs/RECS_DELETE_WIDGET_FAILED';

export const RECS_UPDATE_MANY_WIDGETS = 'recs/RECS_UPDATE_MANY_WIDGETS';
export const RECS_UPDATE_MANY_WIDGETS_COMPLETE = 'recs/RECS_UPDATE_MANY_WIDGETS_COMPLETE';
export const RECS_UPDATE_MANY_WIDGETS_FAILED = 'recs/RECS_UPDATE_MANY_WIDGETS_FAILED';

export const RESET_SETTINGS_TO_DEFAULT = 'recs/RESET_SETTINGS_TO_DEFAULT';
export const SAVE_SETTINGS = 'recs/SAVE_SETTINGS';

export const UPDATE_RECS_VISIBLE = 'recs/UPDATE_RECS_VISIBLE';
export const FORCE_UPDATE_RECS_VISIBLE = 'recs/FORCE_UPDATE_RECS_VISIBLE';

export const UPDATE_ALERT_BANNERS = 'settings/UPDATE_ALERT_BANNERS';
