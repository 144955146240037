/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainer from 'components/kea/CardContainer';
import { getConversionWorst } from 'actions';
import { linkToProductDetail } from 'util/appUtils';


const WorstConversion = () => {
  const dispatch = useDispatch();
  const { analytics: { products, timeframe }, app: { currentShop } } = useSelector((str) => str);
  const { conversionWorst: { isFetching, data, error } } = products;

  useEffect(() => {
    dispatch(getConversionWorst(currentShop, timeframe));
  }, [timeframe]);

  const headCells = [
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'views', numeric: true, label: 'views' },
    { id: 'purchases', numeric: true, label: 'purchases' },
    { id: 'conversion', numeric: true, label: 'conversion' },
  ];

  const prepareRows = (rows) => rows.map((e) => ({
    ...e,
    name: linkToProductDetail(currentShop, e.name, e.id),
    conversion: e.conversion,
  }));


  return (
    <CardContainer
      headerId="search.analytics.conversion.worst.header"
      styleName="min-height-700px noMargin"
      isFetching={isFetching}
      error={error}
    >
      <AnalyticTable
        rows={prepareRows(data)}
        headCells={headCells}
      />
    </CardContainer>

  );
};

export default WorstConversion;
