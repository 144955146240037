/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import { getRelatedQueries } from 'actions';
import { linkToQueryDetail } from 'util/appUtils';
import CardContainerSmall from '../../../../../components/kea/CardContainerSmall';

const RelatedQueries = ({ match }) => {
  const { params: { query } } = match;
  const dispatch = useDispatch();
  const { analytics: { search, timeframe }, app: { currentShop } } = useSelector((store) => store);
  const { relatedQueries: { isFetching, data, error } } = search;

  useEffect(() => {
    dispatch(getRelatedQueries(currentShop, timeframe, query));
  }, [timeframe, query]);

  const headCells = [
    { id: 'query', numeric: false, label: 'Query' },
    { id: 'count', numeric: true, label: 'Count' },
  ];

  const paginationOptions = { rowsPerPageList: [6], initRowsPerPage: 6 };
  const prepareData = (e, index) => ({
    ...e,
    query: linkToQueryDetail(currentShop, e.query),
    id: `${e.query}-${index}`,
  });


  return (
    <CardContainerSmall
      headerId="search.analytics.queryDetail.related.header"
      isFetching={isFetching}
      error={error}
    >
      <AnalyticTable
        paginationOptions={paginationOptions}
        rows={data.map(prepareData)}
        headCells={headCells}
      />
    </CardContainerSmall>
  );
};

export default RelatedQueries;
