import { put, takeLatest } from 'redux-saga/effects';
import * as c from 'constants/actionTypes/catalog';
import { GET } from '@kealabs/kea-services/util/constants';
import moment from 'moment';
import {
  dateFormat,
  prepareDateRange,
  wsClient,
} from '../util/appUtils';


function* getProductById(action) {
  try {
    const { range, shopId, productId } = action.payload;
    const { from, to } = prepareDateRange(range);

    const qs = { shopId, productId, from, to };
    const path = 'system/catalog/product';
    const response = yield wsClient.prepareRequest(GET, path, { qs });
    let { data, trendingCurve, stats } = response;

    trendingCurve = trendingCurve.map((el) => {
      const { year, month, day } = el._id;
      const date = moment([year, month - 1, day]).format(dateFormat);
      return { label: date, count: el.count };
    });

    yield put({
      type: c.GET_PRODUCT_BY_ID_COMPLETE,
      payload: { data, trendingCurve, stats },
    });
  } catch (err) {
    yield put({ type: c.GET_PRODUCT_BY_ID_FAILED, payload: err });
  }
}

function* getProducts(action) {
  try {
    const path = 'system/catalog/products';
    const data = yield wsClient.prepareRequest(GET, path, { qs: action.payload });

    const products = data.map((product) => ({
      id: product.id,
      product: `${product.vendor ? `${product.vendor} - ` : ''} ${product.name}`,
      vendor: product.vendor,
      vendorCode: product.vendorCode,
      name: product.name,
      thumbnail: product.thumbnail,
    }));

    yield put({ type: c.GET_PRODUCTS_COMPLETE, payload: products });
  } catch (err) {
    yield put({ type: c.GET_PRODUCTS_FAILED, payload: err.message });
  }
}

function* filterProducts(action) {
  const { query, data } = action.payload;

  const filtered = [];
  for (let i = 0; i < data.length; i += 1) {
    const filterQuery = query.toLowerCase();
    const element = data[i];
    if (element.product.toLowerCase().indexOf(filterQuery) >= 0) {
      filtered.push(element);
    } else if (element.vendorCode === filterQuery) {
      filtered.push(element);
    }
  }

  yield put({ type: c.FILTER_PRODUCTS_BY_QUERY_COMPLETE, payload: { data: filtered } });
}


export default function* catalogSagas() {
  yield takeLatest(c.GET_PRODUCT_BY_ID, getProductById);
  yield takeLatest(c.GET_PRODUCTS, getProducts);
  yield takeLatest(c.FILTER_PRODUCTS_BY_QUERY, filterProducts);
}
