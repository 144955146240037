/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import WidgetTable from 'components/kea/tables/WidgetTable';
import PropTypes from 'prop-types';
import { linkToQueryDetail } from 'util/appUtils';
import CardContainerSmall from '../../CardContainerSmall';

const BriefQueryNoResult = ({ match, queryNoResult, currentShop }) => {
  const { isFetching, data, error } = queryNoResult;

  const headCells = [
    { id: '#', numeric: false, label: '#' },
    { id: 'query', numeric: false, label: 'Query' },
    { id: 'total', numeric: true, label: 'Total' },
  ];

  const sortDesc = (a, b) => b.total - a.total;
  const attachLinkToArray = (e) => ({ ...e, query: linkToQueryDetail(currentShop, e.query) });
  const prepareRows = (rows) => {
    if (rows) return rows.sort(sortDesc).slice(0, 7).map(attachLinkToArray);
    return [];
  };

  return (
    <CardContainerSmall
      isFetching={isFetching}
      error={error}
      headerId="search.analytics.lostOpportunities.widget.popular-without-results"
      showMoreUrl={`${match.url}/query-no-result`}
    >
      <WidgetTable
        hidePagination
        rows={prepareRows(data)}
        headCells={headCells}
      />
    </CardContainerSmall>
  );
};

BriefQueryNoResult.propTypes = {
  queryNoResult: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.any,
    data: PropTypes.arrayOf(PropTypes.shape({
      query: PropTypes.string,
    })),
  }).isRequired,
};


export default BriefQueryNoResult;
