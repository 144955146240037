/* eslint-disable no-use-before-define */
import { put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import * as c from 'constants/actionTypes/recs';
import { mapDifference, wsClient } from '../util/appUtils';

const appId = 'recs';

function* getFields({ payload }) {
  try {
    const { fields, attributes } = yield wsClient.fetchFields(payload.token, appId);

    const data = {};
    fields.forEach((el) => {
      data[el.id] = {
        id: el.id,
        title: el.title,
        weight: el.weight,
        autocomplete: el.autocomplete,
      };
    });

    yield put({ type: c.GET_FIELDS_COMPLETE, payload: { fields: data, attributes, flat: fields } });
  } catch (err) {
    yield put({ type: c.GET_FIELDS_FAILED, payload: err });
  }
}

function* updateFields() {
  try {
    const { currentShop: token } = yield select((store) => store.app);
    const { fields: { data: fields, forDiff } } = yield select((store) => store.recs);

    const fieldsDiff = mapDifference(fields, forDiff);
    const body = { fields: fieldsDiff, domain: token };
    yield wsClient.updateFields(token, appId, body);

    yield put({ type: c.SAVE_FIELDS_CHANGES_COMPLETED });
    yield put({ type: c.GET_FIELDS });
  } catch (err) {
    yield put({ type: c.SAVE_FIELDS_CHANGES_FAILED, payload: err });
  }
}

function* loadAllModels({ payload }) {
  try {
    const { algorithm } = yield select((state) => state.recs);
    const { token } = payload;
    const data = yield wsClient.recsLoadAllModels(token);
    const dataForSelect = data.map((el) => ({ text: (algorithm[el.id] || el.id), value: el.id }));
    yield put({ type: c.RECS_LOAD_ALL_MODELS_COMPLETE, payload: { data, dataForSelect } });
  } catch (err) {
    yield put({ type: c.RECS_LOAD_ALL_MODELS_FAILED, payload: err });
  }
}

function* loadAllWidgets({ payload }) {
  try {
    const { token } = payload;
    const data = yield wsClient.recsLoadAllWidgets(token);
    const dataMap = _.keyBy(data, 'alias');
    yield put({ type: c.RECS_LOAD_ALL_WIDGETS_COMPLETE, payload: { data, dataMap } });
  } catch (err) {
    yield put({ type: c.RECS_LOAD_ALL_WIDGETS_FAILED, payload: err });
  }
}

// Unused
function* deleteWidget({ payload }) {
  try {
    const { shopId } = yield select((state) => state.settings);
    const { alias } = payload;
    const data = yield wsClient.recsDeleteWidget(shopId, alias);
    yield put({ type: c.RECS_DELETE_WIDGET_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.RECS_DELETE_WIDGET_FAILED, payload: err });
  }
}

function* updateOneWidget({ payload }) {
  const { shopId } = yield select((state) => state.settings);
  try {
    const { widget } = payload;
    const data = yield wsClient.recsUpdateOneWidget(shopId, widget.alias, widget);
    yield put({ type: c.RECS_LOAD_ALL_WIDGETS, payload: { token: shopId } });
    if (data.errors && data.errors.length) throw new Error('Widget saved, but server respond with errors.');

    yield put({ type: c.RECS_UPDATE_ONE_WIDGET_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.RECS_UPDATE_ONE_WIDGET_FAILED, payload: err });
  }
}

// Unused
function* createWidget({ payload }) {
  try {
    const { shopId } = yield select((state) => state.settings);
    const { widget } = payload;
    const data = yield wsClient.recsCreateWidget(shopId, widget);
    yield put({ type: c.RECS_CREATE_WIDGET_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.RECS_CREATE_WIDGET_FAILED, payload: err });
  }
}

function* loadAllPages({ payload }) {
  try {
    const { token } = payload;
    const data = yield wsClient.recsLoadAllPages(token);
    const dataMap = _.keyBy(data, 'id');
    yield put({ type: c.RECS_LOAD_ALL_PAGES_COMPLETE, payload: { data, dataMap, dataMapDefault: dataMap } });
  } catch (err) {
    yield put({ type: c.RECS_LOAD_ALL_PAGES_FAILED, payload: err });
  }
}

function* updatePage({ payload }) {
  try {
    const { shopId } = yield select((state) => state.settings);
    const { page } = payload;
    const body = { ...page, background: true };
    const data = yield wsClient.recsUpdatePage(shopId, page.id, body);
    yield put({ type: c.RECS_LOAD_ALL_PAGES, payload: { token: shopId } });
    if (data.errors && data.errors.length) throw new Error('Widget saved, but server respond with errors.');

    yield put({ type: c.RECS_UPDATE_PAGE_COMPLETE, payload: data });
  } catch (err) {
    yield put({ type: c.RECS_UPDATE_PAGE_FAILED, payload: err });
  }
}


export default function* recs() {
  yield takeLatest(c.GET_FIELDS, getFields);

  yield takeLatest(c.RECS_LOAD_ALL_MODELS, loadAllModels);
  yield takeLatest(c.RECS_LOAD_ALL_WIDGETS, loadAllWidgets);
  yield takeLatest(c.RECS_CREATE_WIDGET, createWidget);
  yield takeLatest(c.RECS_UPDATE_ONE_WIDGET, updateOneWidget);
  yield takeLatest(c.RECS_DELETE_WIDGET, deleteWidget);
  yield takeLatest(c.RECS_LOAD_ALL_PAGES, loadAllPages);
  yield takeLatest(c.RECS_UPDATE_PAGE, updatePage);

  yield takeLatest(c.SAVE_FIELDS_CHANGES, updateFields);
}
