import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import pt from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 13,
  },
});

const SettingsSelectRadio = ({
  onChange, value, items, groupTitle,
}) => {
  const changeHandler = (event) => onChange(event.target.value);

  const classes = useStyles();

  return (
    <div className="setting-item-container">
      <Grid container>
        <Grid item xs={false} sm={1} />
        <Grid item xs={12} sm={11}>
          <div className="setting-group-sub-header">{groupTitle}</div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Grid item xs={false} sm={1} />
        <Grid item xs={11}>
          <RadioGroup
            value={value}
            onChange={changeHandler}
          >

            { items.map((item) => (
              <FormControlLabel
                classes={{
                  label: classes.checkboxLabel,
                }}
                // maybe we need more robust solution, then this
                key={item.value}
                value={item.value}
                control={<Radio color="primary" />}
                label={item.text}
              />
            )) }
          </RadioGroup>
        </Grid>
      </Grid>
    </div>
  );
};

SettingsSelectRadio.propTypes = {
  value: pt.oneOfType([pt.string, pt.number, pt.bool]).isRequired,
  onChange: pt.func.isRequired,
  groupTitle: pt.string.isRequired,
  items: pt.arrayOf(pt.shape({
    text: pt.string,
    value: pt.any.isRequired,
  })).isRequired,
};

export default SettingsSelectRadio;
