import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SafetyRoute from 'components/kea/SafetyRoute';
import asyncComponent from 'util/asyncComponent';
import Product from './Product';
import ProductList from './ProductList';
import ProductListOld from './ProductListOld';

const Catalog = ({ match }) => (
  <Switch>
    <SafetyRoute exact path={`${match.url}/product-catalog`} render={(props) => <ProductList {...props} />} />
    <SafetyRoute path={`${match.url}/product-list-old`} render={(props) => <ProductListOld {...props} />} />
    <SafetyRoute path={`${match.url}/product-catalog/:productId`} render={(props) => <Product {...props} />} />

    <Route component={asyncComponent(() => import('app/routes/404'))} />
  </Switch>
);

export default Catalog;
