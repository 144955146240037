/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MainSection from './MainSection';
import TotalCTR from '../../../../../components/kea/widgets/popularQueries/TotalCTR';
import TotalQueries from '../../../../../components/kea/widgets/popularQueries/TotalQueries';
import BriefPopularProductsByView from '../../../../../components/kea/widgets/BriefPopularProductsByView';
import { getAnalyticQueryPopular, getPopularByView } from '../../../../../actions';

const QueryPopular = ({ match }) => {
  const dispatch = useDispatch();
  const {
    analytics: {
      timeframe,
      search: { queryPopular },
      products: { popularByView },
    },
    app: { currentShop },
  } = useSelector((store) => store);

  useEffect(() => {
    dispatch(getAnalyticQueryPopular(currentShop, timeframe));
    dispatch(getPopularByView(currentShop, timeframe));
  }, [timeframe]);

  return (
    <Grid>
      <Grid spacing={4} container>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <MainSection
            match={match}
            queryPopular={queryPopular}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <TotalQueries
                queryPopular={queryPopular}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <TotalCTR
                queryPopular={queryPopular}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <BriefPopularProductsByView
                match={match}
                popularByView={popularByView}
                currentShop={currentShop}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} />
      </Grid>
    </Grid>
  );
};

export default QueryPopular;
