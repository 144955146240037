import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { NavLink } from 'react-router-dom';
import { ClearOutlined, DehazeOutlined, HeightOutlined } from '@material-ui/icons';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const getItemStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  userSelect: 'none',
  padding: 10,
  backgroundColor: '#d3d3d338',
  border: '1px solid lightgrey',
  borderBottom: isDragging ? '1px solid lightgrey' : 0,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'rgb(240, 240, 240)' : 'white',
  minHeight: 1,
});

export const styles = {
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  leftIcon: {
    flexGrow: 0.5,
    textAlign: 'center',
  },
  deleteIcon: {
    flexGrow: 0.2,
    color: 'red',
    cursor: 'pointer',
  },
  settingIcon: {
    flexGrow: 0.2,
    color: '#3f4eae',
  },
  listIcon: {
    marginRight: 20,
    fontSize: 21,
    color: '#3f4eae',
    verticalAlign: 'super',
  },
  itemTitle: {
    flexGrow: 20,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  itemHeader: { fontSize: '1.125rem' },
  itemSubHeader: { fontSize: '0.725rem' },
};

function WidgetList({
  widgets, sectionId, widgetsMap, dropWidgetFromSection, algorithm, url,
}) {
  return (
    <Droppable droppableId={sectionId}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {
            widgets
              .filter((id) => widgetsMap[id])
              .map((widgetId, index) => (
                <Draggable key={widgetId} draggableId={widgetId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <div style={styles.leftIcon}>
                        {(snapshot.isDragging) ? <HeightOutlined /> : <DehazeOutlined />}
                      </div>
                      <NavLink
                        to={`${url}/${widgetId}?sectionId=${sectionId}`}
                        style={{ ...styles.itemTitle, ...styles.link }}
                      >
                        <div style={styles.itemHeader}>
                          {(widgetsMap[widgetId]) ? widgetsMap[widgetId].title : `deleted_${widgetId}` }
                        </div>
                        <div className="card-text">
                          {`Algorithm: ${algorithm[widgetsMap[widgetId].model]}`}
                        </div>
                      </NavLink>

                      <ClearOutlined
                        fontSize="default"
                        style={styles.deleteIcon}
                        onClick={() => dropWidgetFromSection(widgetId, sectionId)}
                      />

                      <NavLink
                        to={`${url}/${widgetId}?sectionId=${sectionId}`}
                        style={styles.settingIcon}
                      >
                        <SettingsOutlinedIcon
                          fontSize="default"
                        />

                      </NavLink>

                    </div>
                  )}
                </Draggable>
              ))
                    }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default WidgetList;
