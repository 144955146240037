/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import WorstConversion from './WorstConversion';
import BestConversion from './BestConversion';

const Conversion = () => (
  <Grid>
    <Grid spacing={4} container>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <BestConversion />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <WorstConversion />
      </Grid>
    </Grid>
  </Grid>
);

export default Conversion;
