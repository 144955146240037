import React from 'react';
import { Grid } from '@material-ui/core';
import MainSection from './MainSection';

const RecsAnalyticsWidgetsPerformance = () => (
  <Grid>
    <Grid spacing={4} container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainSection />
      </Grid>
    </Grid>
  </Grid>
);

export default RecsAnalyticsWidgetsPerformance;
