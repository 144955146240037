import Vertical from './Vertical/index';
import InsideHeaderNav from './Horizontal/InsideHeaderNav';
import BelowHeaderNav from './Horizontal/BelowHeaderNav';
import AboveHeaderNav from './Horizontal/AboveHeaderNav';

const AppLayouts = {
  Vertical,
  InsideHeaderNav,
  BelowHeaderNav,
  AboveHeaderNav,
};
export default AppLayouts;
