import React from 'react';
import Widget from 'components/Widget/index';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';

const WidgetWithChart = ({ title, mainNumber, description, chart, isFetching }) => (
  <Widget styleName="jr-card-full noMargin">
    {
      isFetching
        ? <PlaceholderSpinner height={129} />
        : (
          <>
            <div className="d-flex flex-row px-3 pt-4">
              <p className="text-uppercase">{title}</p>
            </div>
            <div className="row">
              <div className="col-xl-5 col-md-6 col-sm-12">
                <div className="jr-actchart pb-3 pl-3">
                  <h2 className="jr-fs-2xl jr-font-weight-bold mb-1">{mainNumber}</h2>
                  <p className="mb-0 text-grey">{description}</p>
                </div>
              </div>
              <div className="col-xl-7 col-md-6 col-sm-12">
                {chart}
              </div>
            </div>
          </>
        )
      }

  </Widget>
);

export default WidgetWithChart;
