export const GET_ALL_SETTINGS = 'settings/GET_ALL_SETTINGS';
export const GET_ALL_SETTINGS_COMPLETE = 'settings/GET_ALL_SETTINGS_COMPLETE';
export const GET_ALL_SETTINGS_FAILED = 'settings/GET_ALL_SETTINGS_FAILED';

export const GET_DESCRIBES = 'settings/GET_DESCRIBES';
export const GET_DESCRIBES_COMPLETE = 'settings/GET_DESCRIBES_COMPLETE';
export const GET_DESCRIBES_FAILED = 'settings/GET_DESCRIBES_FAILED';

export const UPDATE_SEARCH_VISIBLE = 'settings/UPDATE_SEARCH_VISIBLE';
export const UPDATE_RECS_VISIBLE = 'settings/UPDATE_RECS_VISIBLE';

export const FORCE_UPDATE_SEARCH_VISIBLE = 'settings/FORCE_UPDATE_SEARCH_VISIBLE';
export const FORCE_UPDATE_SEARCH_VISIBLE_COMPLETE = 'settings/FORCE_UPDATE_SEARCH_VISIBLE_COMPLETE';
export const FORCE_UPDATE_SEARCH_VISIBLE_FAILED = 'settings/FORCE_UPDATE_SEARCH_VISIBLE_FAILED';

export const FORCE_UPDATE_RECS_VISIBLE = 'settings/FORCE_UPDATE_RECS_VISIBLE';
export const FORCE_UPDATE_RECS_VISIBLE_COMPLETE = 'settings/FORCE_UPDATE_RECS_VISIBLE_COMPLETE';
export const FORCE_UPDATE_RECS_VISIBLE_FAILED = 'settings/FORCE_UPDATE_RECS_VISIBLE_FAILED';

export const CHANGE_RECS_THEME_SETTING = 'settings/CHANGE_RECS_THEME_SETTING';

export const RESET_SETTINGS_TO_DEFAULT = 'settings/RESET_SETTINGS_TO_DEFAULT';
export const RESET_SEARCH_SETTINGS_TO_DEFAULT = 'settings/RESET_SEARCH_SETTINGS_TO_DEFAULT';
export const RESET_RECS_SETTINGS_TO_DEFAULT = 'settings/RESET_RECS_SETTINGS_TO_DEFAULT';

export const SAVE_RECS_SETTINGS = 'settings/recs/SAVE_SETTINGS';
export const SAVE_RECS_SETTINGS_COMPLETED = 'settings/recs/SAVE_SETTINGS_COMPLETED';
export const SAVE_RECS_SETTINGS_FAILED = 'settings/recs/SAVE_SETTINGS_FAILED';

export const SAVE_SEARCH_SETTINGS = 'settings/search/SAVE_SETTINGS';
export const SAVE_SEARCH_SETTINGS_COMPLETED = 'settings/search/SAVE_SETTINGS_COMPLETED';
export const SAVE_SEARCH_SETTINGS_FAILED = 'settings/search/SAVE_SETTINGS_FAILED';


export const GET_ALERTS = 'settings/GET_ALERTS';
export const GET_ALERTS_FAILED = 'settings/GET_ALERTS_FAILED';
export const UPDATE_ALERT_BANNERS = 'settings/UPDATE_ALERT_BANNERS';
export const REWRITE_ALERT_BANNERS = 'settings/REWRITE_ALERT_BANNERS';

export const CHANGE_SEARCH_ONBOARDING = 'settings/CHANGE_SEARCH_ONBOARDING';
export const CHANGE_RECS_ONBOARDING = 'settings/CHANGE_RECS_ONBOARDING';
export const CHANGE_SEARCH_GENERAL_SETTING = 'settings/CHANGE_SEARCH_GENERAL_SETTING';
export const CHANGE_SEARCH_RESULT_SETTING = 'settings/CHANGE_SEARCH_RESULT_SETTING';
export const CHANGE_SEARCH_BOX_SETTING = 'settings/CHANGE_SEARCH_BOX_SETTING';
export const CHANGE_SEARCH_THEME_SETTING = 'settings/CHANGE_SEARCH_THEME_SETTING';
export const CHANGE_SEARCH_FACETS_SETTING = 'settings/CHANGE_SEARCH_FACETS_SETTING';
