import Typography from '@material-ui/core/Typography';
import React from 'react';
import classes from './Header.module.scss';

export default function Header({ children, variant }) {
  if (variant === '1') {
    return <Typography className={classes.header1}>{children}</Typography>;
  }
  if (variant === '2') {
    return <Typography className={classes.header2}>{children}</Typography>;
  }
}

Header.defaultProps = {
  variant: '1',
};
