export const notifications = [

  {
    image: 'https://via.placeholder.com/150x150',
    title: 'Stella Johnson has recently posted an album',
    time: '4:10 PM',
    icon: 'zmdi-thumb-up text-blue',
  }, {
    image: 'https://via.placeholder.com/150x150',
    title: 'Alex Brown has shared Martin Guptil\'s post',
    time: '5:18 PM',
    icon: 'zmdi-comment-text text-grey',
  }, {
    image: 'https://via.placeholder.com/150x150',
    title: 'Domnic Brown has sent you a group invitation for Global Health',
    time: '5:36 PM',
    icon: 'zmdi-card-giftcard text-info',
  }, {
    image: 'https://via.placeholder.com/150x150',
    title: 'John Smith has birthday today',
    time: '5:54 PM',
    icon: 'zmdi-cake text-warning',
  }, {
    image: 'https://via.placeholder.com/150x150',
    title: 'Chris has updated his profile picture',
    time: '5:25 PM',
    icon: 'zmdi-account-box-o text-grey',
  },
];
