import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { commaSeparate } from '../../util/appUtils';
import TextCenter from '../Typography/TextCenter';
import Header from '../Typography/Header';
import Subheader from '../Typography/Subheader';
import BigSlider from '../kea/Slider/BigSlider';
import classes from './SetupRecsAudience.module.scss';

function makeAudiences(array) {
  return array.map((audienceValue) => ({
    value: audienceValue,
    text: `Less then ${commaSeparate(audienceValue)}/month`,
    label: `${commaSeparate(audienceValue)}`,
  }));
}

const audiences = makeAudiences([
  1000,
  5000,
  10000,
  20000,
  50000,
  100000,
  500000,
  1000000,
]);
audiences.push(
  {
    value: 1000001,
    text: `${commaSeparate(1000000)} and more`,
    label: `${commaSeparate(1000000)}+`,
  },
);

export default function SetupRecsAudience({ onClick }) {
  const audienceSize = useSelector((store) => store.onboarding.recs.audienceSize);
  const audience = audiences.filter((a) => a.value === audienceSize).pop() || audiences[0];
  const audienceIndex = audiences.map((a) => a.value).indexOf(audienceSize);

  const onSliderChange = (_, index) => {
    onClick(audiences[index].value);
  };
  const sliderMarks = audiences.map(({ label }, index) => ({ value: index, label }));

  return (
    <TextCenter>
      <Header variant="2">Store Audience</Header>
      <Subheader>What is an average monthly number of visitors in your store?</Subheader>
      <div className={classes.sliderWrapper}>
        <Typography className={classes.sliderText}>{audience.text}</Typography>
        <BigSlider color="secondary" min={0} max={audiences.length - 1} value={audienceIndex} onChange={onSliderChange} marks={sliderMarks} />
      </div>
    </TextCenter>
  );
}
