/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import CardContainer from 'components/kea/CardContainer';
import { changeTimeframe, getABCarts } from 'actions';
import DateRangePicker from 'components/kea/DateRangePicker';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import qs from 'qs';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import PlaceholderError from '../../../../../components/kea/placeholders/PlaceholderError';
import WidgetTable from '../../../../../components/kea/tables/WidgetTable';
import { genRandomKey } from '../../../../../util/appUtils';

const Carts = ({ match, location }) => {
  const { page, group } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const dispatch = useDispatch();
  const {
    analytics: { timeframe, experimentCarts },
    app: { currentShop },
  } = useSelector((store) => store);
  const { error, isFetching, carts, total } = experimentCarts;
  const limit = 20;

  useEffect(() => {
    const payload = { page, group, token: currentShop, timeframe, limit };
    dispatch(getABCarts(payload));
  }, [timeframe, page]);

  const onTimeframeSubmit = (newRange) => dispatch(changeTimeframe(newRange));
  const timeframePicker = (
    <DateRangePicker
      timeframe={timeframe}
      onSubmitRange={onTimeframeSubmit}
    />
  );

  const statuses = [
    <Chip label="Unknown" variant="outlined" />,
    <Chip label="Paid" color="primary" />,
    <Chip label="Abandoned" />,
  ];

  const getRowLabel = (fromRec, fromSearch) => {
    if (fromRec && fromSearch) return (<Chip size="small" color="primary" label="recs & search" />);
    if (fromRec && !fromSearch) return (<Chip size="small" color="primary" label="recs" />);
    if (fromSearch && !fromRec) return (<Chip size="small" color="primary" label="search" />);
    return null;
  };

  return (
    <div className="app-wrapper slideInUpTiny">
      <ContainerHeader
        match={match}
        action={timeframePicker}
        title={<IntlMessages id="sidebar.store.experiment.carts" />}
      />
      {
        isFetching
          ? <PlaceholderSpinner height={750} />
          : error
            ? <PlaceholderError error={error} height={750} />
            : (
              <>
                {
                  carts.map((cart) => (
                    <CardContainer key={genRandomKey()}>
                      <Grid>
                        <Grid container>
                          <Grid item xs={9}>
                            <Grid container>
                              <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>Group</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                  {cart.abGroup || 'unknown'}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4}><Typography variant="body1" gutterBottom>Order ID</Typography></Grid>
                              <Grid item xs={8}><Typography variant="body1" gutterBottom>{cart.externalId || 'unknown'}</Typography></Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4}><Typography variant="body1" gutterBottom>  Kealabs ID</Typography></Grid>
                              <Grid item xs={8}><Typography variant="body1" gutterBottom>{cart.internalId || 'unknown'}</Typography></Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4}><Typography variant="body1" gutterBottom>Created</Typography></Grid>
                              <Grid item xs={8}><Typography variant="body1" gutterBottom>{cart.creationDate || 'unknown'}</Typography></Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4}><Typography variant="body1" gutterBottom> Timestamp </Typography></Grid>
                              <Grid item xs={8}><Typography variant="body1" gutterBottom>{cart.timestamp || 'unknown'}</Typography></Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4}><Typography variant="body1" gutterBottom> IP-address </Typography></Grid>
                              <Grid item xs={8}><Typography variant="body1" gutterBottom>{cart.location || 'unknown'}</Typography></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid>
                              <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
                                <Grid item style={{ marginRight: 40 }}>
                                  {statuses[cart.status]}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={12} style={{ marginTop: 40 }}>
                            <Typography variant="h5" gutterBottom>
                              Products:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <WidgetTable
                              headCells={[
                                { id: 'productId', label: 'ProductId' },
                                { id: 'name', label: 'Name' },
                                { id: 'labels', label: '' },
                                { id: 'quantity', label: 'Quantity' },
                                { id: 'price', label: 'Price' },
                              ]}
                              rows={[
                                ...cart.products.map((el) => ({
                                  ...el,
                                  name: el.name.includes('LOCALIZE') ? <IntlMessages id={el.name} /> : el.name,
                                  labels: getRowLabel(el.fromRec, el.fromSearch),
                                })),
                                {
                                  id: '',
                                  productId: 'Total: ',
                                  name: '',
                                  labels: '',
                                  quantity: cart.total.quantity,
                                  price: cart.total.price,
                                },
                              ]}
                            />
                          </Grid>
                          {
                      cart.queries.length > 0
                        ? (
                          <>
                            <Grid item xs={12} style={{ marginTop: 40 }}>
                              <Typography variant="h5" gutterBottom>
                                Search queries:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: 20 }}>
                              {
                                [...new Set(cart.queries.map(({ query }) => query))]
                                  .map((query) => (
                                    <Chip
                                      key={genRandomKey()}
                                      size="small"
                                      style={{ margin: 3 }}
                                      label={query}
                                      variant="outlined"
                                    />
                                  ))
                              }
                            </Grid>
                          </>
                        )
                        : null
                    }
                        </Grid>
                      </Grid>
                    </CardContainer>
                  ))
                }
                <Grid>
                  <Grid
                    container
                    justifyContent="center"
                  >
                    <Grid item>
                      <Pagination
                        variant="outlined"
                        color="primary"
                        page={page ? parseInt(page, 10) : 1}
                        count={Math.ceil(total / limit)}
                        showFirstButton
                        siblingCount={4}
                        boundaryCount={4}
                        showLastButton
                        renderItem={(item) => (
                          <PaginationItem
                            component={Link}
                            to={`${location.pathname}?page=${item.page}&group=${group}`}
                            {...item}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )
      }
    </div>
  );
};
export default Carts;
