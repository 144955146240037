import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as a from 'actions';
import SettingColorPicker from 'components/kea/settings/SettingColorPicker';


const Theme = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store);
  const { search: { theme } } = settings;

  const changeHandlerTextHeader = (value) => dispatch(a.changeSearchThemeSetting('text_header', value));
  const changeHandlerTextMain = (value) => dispatch(a.changeSearchThemeSetting('text_main', value));
  const changeHandlerMainAccent = (value) => dispatch(a.changeSearchThemeSetting('main_accent', value));
  const changeHandlerSecondAccent = (value) => dispatch(a.changeSearchThemeSetting('second_accent', value));
  const changeHandlerBorder = (value) => dispatch(a.changeSearchThemeSetting('border_color', value));
  const changeHandlerBachground = (value) => dispatch(a.changeSearchThemeSetting('background_color', value));


  return (
    <div className="settings-container">
      <div className="setting-group-header">Colors</div>
      <SettingColorPicker
        value={theme.text_header}
        onChange={changeHandlerTextHeader}
        title="Headers Color"
      />

      <SettingColorPicker
        value={theme.text_main}
        onChange={changeHandlerTextMain}
        title="Main Color"
      />

      <SettingColorPicker
        value={theme.main_accent}
        onChange={changeHandlerMainAccent}
        title="Accent Color"
      />

      <SettingColorPicker
        value={theme.second_accent}
        onChange={changeHandlerSecondAccent}
        title="Button Color"
      />

      <SettingColorPicker
        value={theme.border_color}
        onChange={changeHandlerBorder}
        title="Border Color"
      />

      <SettingColorPicker
        value={theme.background_color}
        onChange={changeHandlerBachground}
        title="Background Color"
      />

      {/* <div style={{ whiteSpace: 'pre' }}> */}
      {/* {JSON.stringify(theme, null, 4)} */}
      {/* </div> */}
    </div>
  );
};

export default Theme;
