import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingColorPicker from 'components/kea/settings/SettingColorPicker';
import * as a from '../../../../../actions';


const Theme = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store);
  const { recs: { theme } } = settings;


  const changeHandlerTitleColor = (value) => dispatch(a.changeRecsThemeSetting('widget-title-color', value));
  const changeHandlerPrNameColor = (value) => dispatch(a.changeRecsThemeSetting('widget-product-name-color', value));
  const changeHandlerPrSecondColor = (value) => dispatch(a.changeRecsThemeSetting('widget-product-second-color', value));
  const changeHandlerPrBtnBg = (value) => dispatch(a.changeRecsThemeSetting('widget-product-btn', value));
  const changeHandlerPrBtnColor = (value) => dispatch(a.changeRecsThemeSetting('widget-product-btn-color', value));
  const changeHandlerPriceColor = (value) => dispatch(a.changeRecsThemeSetting('widget-product-price-color', value));
  const changeHandlerOldPriceColor = (value) => dispatch(a.changeRecsThemeSetting('widget-product-oldprice-color', value));
  const changeHandlerMobDotsColor = (value) => dispatch(a.changeRecsThemeSetting('widget-mobile-dots-color', value));

  return (
    <div className="settings-container">
      <div className="setting-group-header">Widget colors</div>
      <SettingColorPicker
        value={theme['widget-title-color']}
        onChange={changeHandlerTitleColor}
        title="Widget title"
      />
      <SettingColorPicker
        value={theme['widget-product-name-color']}
        onChange={changeHandlerPrNameColor}
        title="Product name"
      />
      <SettingColorPicker
        value={theme['widget-product-second-color']}
        onChange={changeHandlerPrSecondColor}
        title="Accent color"
      />
      <SettingColorPicker
        value={theme['widget-product-btn']}
        onChange={changeHandlerPrBtnBg}
        title="Button background"
      />
      <SettingColorPicker
        value={theme['widget-product-btn-color']}
        onChange={changeHandlerPrBtnColor}
        title="Button text"
      />
      <SettingColorPicker
        value={theme['widget-product-price-color']}
        onChange={changeHandlerPriceColor}
        title="Price color"
      />
      <SettingColorPicker
        value={theme['widget-product-oldprice-color']}
        onChange={changeHandlerOldPriceColor}
        title="Old Price/Sale color"
      />
      <SettingColorPicker
        value={theme['widget-mobile-dots-color']}
        onChange={changeHandlerMobDotsColor}
        title="Carousel pagination"
      />
    </div>
  );
};

export default Theme;
