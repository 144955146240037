/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import WidgetTable from 'components/kea/tables/WidgetTable';
import { linkToProductDetail } from 'util/appUtils';
import CardContainerSmall from '../../CardContainerSmall';

const BriefPopularProductsByView = ({ match, popularByView, currentShop }) => {
  const { isFetching, data, error } = popularByView;

  const headCells = [
    { id: '#', numeric: false, label: '#' },
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'count', numeric: true, label: 'count' },
  ];

  const prepareRows = (rows) => {
    if (Array.isArray(rows)) {
      return rows
        .slice(0, 7)
        .map((e) => ({
          ...e,
          name: linkToProductDetail(currentShop, e.name, e.id),
        }));
    }
    return [];
  };

  return (
    <CardContainerSmall
      error={error}
      isFetching={isFetching}
      headerId="search.analytics.popularItemsByView.header"
      showMoreUrl={`/app/${currentShop}/store/analytics/popular-products`}
    >
      <WidgetTable
        hidePagination
        rows={prepareRows(data)}
        headCells={headCells}
      />
    </CardContainerSmall>
  );
};

export default BriefPopularProductsByView;
