import _ from 'lodash';
import moment from 'moment';
import KeaWebServiceClient from '@kealabs/kea-services/lib/kea-webservice-client';
import qs from 'qs';
import { Link } from 'react-router-dom';
import React from 'react';
import store from 'store2';

import { tokenKey, getToken, rmToken } from './authUtils';

export const RECS = 'recs';
export const SEARCH = 'search';

export const wsDateFormat = 'DD.MM.YYYY';
export const dateFormat = 'YYYY-MM-DD';
export const dateMonthFormat = 'YYYY-MM';

const wsBaseUri = window.WS_BASE_URI;

export const m = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
};

export const getInitUrlByShop = (shop) => {
  const { shopId } = shop;

  if (shop.searchEnabled) {
    if (!shop.search.onboarding.completed) {
      return `/app/${shopId}/search/onboarding`;
    }
    return `/app/${shopId}/search/dashboard`;
  }

  if (shop.recsEnabled) {
    if (!shop.recs.onboarding.completed) {
      return `/app/${shopId}/recs/onboarding`;
    }
    return `/app/${shopId}/recs/dashboard`;
  }
  return `/app/${shopId}/billing`;
};

export const setStartMode = (mode) => store.set('KEA-APP-START-MODE', mode);
export const getStartMode = () => store.get('KEA-APP-START-MODE');
export const appInIframe = () => {
  const startMode = getStartMode();
  return startMode === 'iframe';
};

export const isSameDay = (momentFrom, momentTo) => momentFrom.isSame(momentTo, 'day');

export const matchDatesDashboardCase = (dataDate, picker) => {
  const fromIsMatched = isSameDay(moment(dataDate.from, wsDateFormat), picker.from);
  const toIsMatched = isSameDay(moment(dataDate.to, wsDateFormat), picker.to);
  return fromIsMatched && toIsMatched;
};

export const getShopIdFromLocation = () => window.location.pathname.split('/')[2];

export const genRandomKey = () => new Date().getTime() + Math.random();

export function genRandomInteger(min, max) {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

export const linkToQueryDetail = (shopId, query) => {
  const path = `/app/${shopId}/search/analytic/query/${query}`;
  return <Link to={path}>{query}</Link>;
};

export const linkToProductDetail = (shopId, product, productId) => {
  const path = `/app/${shopId}/store/catalog/product-catalog/${productId}`;
  return <Link to={path}>{product}</Link>;
};

export function fetchData(path, method = m.GET, payload = {}, extraHeaders = {}) {
  const { query, body } = payload;
  const sessionToken = getToken();

  let uri = path;
  const options = {
    method,
    credentials: 'include',
    headers: { ...extraHeaders },
  };

  if (sessionToken) options.headers[tokenKey] = sessionToken;
  if (query) uri = `${uri}?${qs.stringify(query)}`;
  if (body) options.body = body;

  return fetch(uri, options).then((response) => {
    if (response.status === 200) return response.json();
    if (response.status === 401) {
      rmToken();
      window.history.push('/signin');
    }
    throw new Error(`${response.status} — ${response.statusText}`);
  });
}

export const qsParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
export const domain = qsParams.domain ? qsParams.domain : null;

export const wsClient = new KeaWebServiceClient({
  baseUrl: wsBaseUri,
  secret: null,
  extraHeaders: { [tokenKey]: getToken() },
});

export function prepareDateRange(range) {
  return {
    from: moment(range.from).format(dateFormat),
    to: moment(range.to).format(dateFormat),
  };
}

export function calcWidgetCurve(data) {
  const a = {};

  if (data.length < 62) {
    data.forEach((el) => {
      const { year, month, day } = el._id;
      const alias = moment([year, month - 1, day]).format(dateFormat);
      const label = moment([year, month - 1, day]).format('DD MMMM YYYY');
      a[alias] = { ...el, label };
    });
    return a;
  }

  if (data.length >= 62) {
    data.forEach((el) => {
      const { year, month } = el._id;
      const alias = moment([year, month - 1]).format(dateMonthFormat);
      if (a.hasOwnProperty(alias)) {
        a[alias].total += el.total;
        a[alias].click += el.click;
        a[alias].noaction += el.noaction;
        a[alias].refine += el.refine;
        a[alias].ctr += el.ctr;
        a[alias].count += 1;
      } else {
        const label = moment([year, month - 1]).format('MMMM YYYY');
        a[alias] = {
          ...el,
          label,
          count: 1,
        };
      }
    });
    for (const month in a) {
      const currMonth = a[month];
      if (currMonth.ctr) {
        currMonth.ctr /= currMonth.count;
      }
    }
    return a;
  }
  return a;
}


export function objectDifference(object, base) {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = (_.isObject(value) && _.isObject(base[key])) ? objectDifference(value, base[key]) : value;
    }
  });
}

export function mapDifference(object, examplar) {
  const keys = Object.keys(object);
  const result = [];
  keys.forEach((key) => {
    if (!_.isEqual(object[key], examplar[key])) {
      result.push(object[key]);
    }
  });
  return result;
}

export const addHexPrefix = (src) => src;

export const rmHexPrefix = (src) => src;

export const addHexPrefixArray = (arr) => arr.map((el) => addHexPrefix(el));

export const rmHexPrefixArray = (arr) => arr.map((el) => rmHexPrefix(el));

export const commaSeparate = (number) => {
  if (number) {
    return number.toFixed(0).toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
  }
  return '--';
};

export function groupCurveByDays(arr, bucketSize, dateName, fields) {
  const grouped = [];
  let bucket = {};
  let added = false;
  const endOfTheBucket = bucketSize - 1;
  for (let i = 0; i < arr.length; i++) {
    const entry = arr[i];
    added = false;
    join(bucket, entry);
    if (!bucket[dateName]) bucket[dateName] = entry[dateName];
    if (i % bucketSize === endOfTheBucket) {
      grouped.push(bucket);
      bucket = {};
      added = true;
    }
  }
  // add the last bucket if needed
  if (!added) {
    grouped.push(bucket);
  }

  function join(bucket, entry) {
    fields.forEach((e) => {
      if (!bucket[e]) bucket[e] = 0;
      if (entry[e]) bucket[e] += entry[e];
    });
  }
  return grouped;
}

export function theCurveIsMostlyFull(arr, fieldName, minFillRatio) {
  if (arr.length === 0) return false;
  const filledBuckets = arr.map((e) => e[fieldName]).filter((e) => e > 0);
  return (filledBuckets.length / arr.length) > minFillRatio;
}

export const shopifyColors = {
  primaryButtonBackground: 'linear-gradient(180deg,#6371c7,#5563c1)',
  primaryButtonBorder: '#3f4eae',
  sky: {
    lighter: '#F9FAFB',
    light: '#F4F6F8',
    normal: '#DFE3E8',
    dark: '#C4CDD5',
    darker: '#C4CDD5',
  },
  titleBar: {
    light: '#B3B5CB',
    normal: '#0187ac',
    dark: '#1C2260',
    darker: '#00044C',
  },
  red: {
    lighter: '#FBEAE5',
    light: '#FEAD9A',
    normal: '#DE3618',
    dark: '#BF0711',
    darker: '#330101',
  },
  orange: {
    lighter: '#FCEBDB',
    light: '#FFC58B',
    normal: '#F49342',
    dark: '#C05717',
    darker: '#573B00',
  },
  yellow: {
    lighter: '#FCF1CD',
    light: '#FFEA8A',
    normal: '#EEC200',
    dark: '#8A6116',
    darker: '#573B00',
  },
  green: {
    lighter: '#E3F1DF',
    light: '#BBE5B3',
    normal: '#50B83C',
    dark: '#108043',
    darker: '#173630',
  },
  teal: {
    lighter: '#E0F5F5',
    light: '#B7ECEC',
    normal: '#47C1BF',
    dark: '#00848E',
    darker: '#003135',
  },
  blue: {
    lighter: '#EBF5FA',
    light: '#B4E1FA',
    normal: '#007ACE',
    dark: '#084E8A',
    darker: '#001429',
  },
  indigo: {
    lighter: '#F4F5FA',
    light: '#B3BCF5',
    normal: '#5C6AC4',
    dark: '#202E78',
    darker: '#000639',
  },
  purple: {
    lighter: '#F6F0FD',
    light: '#E3D0FF',
    normal: '#9C6ADE',
    dark: '#50248F',
    darker: '#230051',
  },
  bundle: [
    '#DE3618',
    '#F49342',
    '#EEC200',
    '#50B83C',
    '#108043',
    '#47C1BF',
    '#007ACE',
    '#0187ac',
    '#454F5B',
    '#637381',
    '#919EAB',
  ],
};

export function toggleValueInArray(value, array) {
  if (array.indexOf(value) > -1) {
    array.splice(array.indexOf(value), 1);
  } else {
    array.push(value);
  }
  return array;
}
