import React from 'react';
import Widget from 'components/Widget/index';
import PlaceholderSpinner from 'components/kea/placeholders/PlaceholderSpinner';
import WidgetRainbow from 'components/kea/widgets/common/WidgetRainbow';
import { chartColors } from 'util/chartUtils';

const DiscoveryWidget = ({ stats }) => {
  if (!stats) {
    return (
      <Widget styleName="jr-card-full noMargin">
        <PlaceholderSpinner height={129} />
      </Widget>
    );
  }

  const totalViews = stats && stats.totalViews ? stats.totalViews : '-';
  const fromRecsViews = stats && stats.fromRecsViews;
  const fromSearchViews = stats && stats.fromSearchViews;
  const otherViews = totalViews - fromRecsViews - fromSearchViews;

  const pieData = [
    { id: '1', name: 'Recommendations', fill: chartColors.main.normal, value: fromRecsViews },
    { id: '2', name: 'Search', fill: chartColors.different.normal, value: fromSearchViews },
    { id: '3', name: 'Other ways', fill: chartColors.gray.normal, value: otherViews },
  ];

  const title = 'Products  discovery';
  const description = 'Total views';

  return (
    <WidgetRainbow
      pieData={pieData}
      title={title}
      mainNumber={totalViews}
      description={description}
    />
  );
};

export default DiscoveryWidget;
