/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticTable from 'components/kea/tables/AnalyticTable';
import CardContainer from 'components/kea/CardContainer';
import { getRelatedProductsByView } from 'actions';
import { linkToProductDetail } from 'util/appUtils';

const RelatedProductsByView = ({ productId }) => {
  const dispatch = useDispatch();
  const { analytics: { products, timeframe }, app: { currentShop } } = useSelector((str) => str);
  const { relatedByView: { isFetching, data, error } } = products;

  useEffect(() => {
    dispatch(getRelatedProductsByView(currentShop, timeframe, productId));
  }, [timeframe, productId]);

  const headCells = [
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'count', numeric: true, label: 'count' },
  ];

  return (
    <CardContainer
      headerId="search.analytics.relatedProductsByView.header"
      styleName="min-height-700px noMargin"
      isFetching={isFetching}
      error={error}
    >
      <AnalyticTable
        rows={data.map((e) => ({ ...e, name: linkToProductDetail(currentShop, e.name, e.id) }))}
        headCells={headCells}
      />
    </CardContainer>

  );
};

export default RelatedProductsByView;
