import { Card } from 'reactstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';
import Error404 from '../../../app/routes/404';


const CardWithTabs = ({
  tabs, tabId, actions, onTabClick,
}) => {
  const activeTab = tabs[tabId];
  const tabIdList = Object.keys(tabs);

  return (
    <Card className="jr-card-full jr-card-tabs-right jr-c ard-profile jr-card jr-card-widget ">
      <div className="jr-tabs-classic">
        <div style={{ display: 'grid', gridTemplateColumns: '900px 100px 100px' }}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={tabId}
            onChange={onTabClick}
          >
            {
              tabIdList.map((id) => (
                <Tab className="tab" key={id} value={id} label={tabs[id].title} />
              ))
            }
          </Tabs>

          {
            actions.map((e, i) => (
              <Button
                key={e.title}
                variant="contained"
                disabled={e.disabled}
                onClick={e.action}
                color={e.color}
              >
                {e.title}
              </Button>
            ))
          }

        </div>
        <div className="jr-tabs-content jr-task-list">
          <div className="row">
            {
              activeTab
                ? activeTab.component
                : <Error404 />
            }
          </div>
        </div>
      </div>
    </Card>
  );
};

CardWithTabs.defaultProps = {
  actions: [],
};

CardWithTabs.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  tabId: PropTypes.string.isRequired,
  tabs: PropTypes.objectOf(PropTypes.shape({
    component: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    color: PropTypes.string,
  })),
};


export default CardWithTabs;
